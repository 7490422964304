import React from "react";
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
// import { Button, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';
import './loading.css';
import logotype from './img/logotype_white.svg'
import { CellsLogo, CellsLogoType } from './Utils'


const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    background: "white"
  },
  colorPrimary: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  colorSecondary: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  bar: {
    background: "linear-gradient(90deg, #00f09e 0%, #12ccc5 100% )",
  }
}))(LinearProgress);


const LoadingScreen = (props) => {
//  const { setHasCells } = props
  const { loading } = props
  const endOfYearPassed = Date.now() > 1640995200000
  return (
    <Grid container className="loadingcontainer" direction="column" justifyContent="center">
      <Grid item>
        <Grid item container alignItems="center" justifyContent="center" style={{}}>
          <CellsLogo fontSize="large" style={{fontSize: "8em", margin: 20}}/>
          <CellsLogoType fontSize="large" style={{fontSize: "8em", fill: "white", margin: 20}}/>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <div style={{maxWidth: 500, display: "flex", justifyContent:"center", alignItems: "center"}}>
            <BorderLinearProgress variant="determinate" color="primary" value={loading || 0} thickness={4} />
          </div>  
        </Grid>  
      </Grid>
      {!endOfYearPassed && <img src="https://cells.land/img/santa.png" alt="christmas!" style={{position: "absolute", bottom: 0, left: 20, maxWidth: "50%"}} />}
    </Grid>
  )
}

export default LoadingScreen;
