import React, { useEffect, useState, useMemo, useRef, useCallback, useReducer } from "react";
//import { Contract } from "@ethersproject/contracts";
//import { getDefaultProvider } from "@ethersproject/providers";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import loadable from '@loadable/component'
import { Header, Footer } from "./components";
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import { ethers } from "ethers";
import GlobalContext from "./Context";
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { withSnackbar } from "./Snackbar";
import { randomBackground, generateId, getRandomNFTURL, tileLayers, smartTrim, stripHtml, sortByKey, cellBackgrounds, copyClipboard, getApiKey, checkMedia, zones, CellsLogoGrad } from './Utils';
import { createTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Checkbox, Fab, Grow, Tooltip } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import VideoIcon from '@material-ui/icons/OndemandVideo';
import ImageIcon from '@material-ui/icons/Image';
import TextIcon from '@material-ui/icons/FontDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RightArrowIcon from '@material-ui/icons/ArrowRight';
import { areEqual, FixedSizeList } from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer";
import 'animate.css'
import WelcomeDialog from './WelcomeDialog'
import BottomDrawer from './BottomDrawer'
import axios from 'axios'
import FormData from 'form-data';

import LayerControl, { GroupedLayer } from "./LayerControl";
import CellsLogo from "./CellsLogo";
import BattlesLogo from "./BattlesLogo";
import BattlesWelcome from "./BattlesWelcome";
import CeldaReward from "./CeldaReward";
import CellReroll from "./CellReroll";
import CellEvolve from "./CellEvolve";


const CellSpeedDial = loadable(() => import('./CellSpeedDial'));
const CellSettings = loadable(() => import('./CellSettings'));
const Cells = loadable(() => import('./Cells'));
const CellEditContent = loadable(() => import('./CellEditContent'));
const CellAddNft = loadable(() => import('./CellAddNft'));
const MainMenu = loadable(() => import('./MainMenu'));
const UploadDialog = loadable(() => import('./Upload'));
const BrowseDialog = loadable(() => import('./BrowseDialog'));

require("./js/leaflet-image-transform.js");
require("./js/singleclick.js")
require("./js/L.Control.Zoomslider.css")
require("./js/L.Control.Zoomslider")
var CryptoJS = require("crypto-js");

const urlQuery = new URLSearchParams(window.location.search)
const fragment = new URLSearchParams(window.location.hash.slice(1));
const isPic = urlQuery.get("pic")
const isTokenId = urlQuery.get("tokenId")
const isCell = urlQuery.get("cell")
const isCountry = urlQuery.get("country")
const isOutside = urlQuery.get("out")
const isZoom = urlQuery.get("zoom")
const isLat = urlQuery.get("lat")
const isLng = urlQuery.get("lng")
const isZone = urlQuery.get("zone")
const isMap = urlQuery.get("map")
const isHdr = urlQuery.get("h")
const isFilter = urlQuery.get("filter")
const isEvent = urlQuery.get("event")
const isDiscord = urlQuery.get("from") === "discord" && fragment.get("access_token")

const EVENTACTIVE = !!isEvent || false
const EVENTRESTRICTED = true
const EVENTCELLS = [
  "",
  "47ba4", //lvl 1
  "47bac", //lvl 2
  "47a54", //lvl 3
  "47a5c", //lvl 4
  "47a64", //lvl 5
  "47bbc", //lvl 6
  "47bb4", //lvl 7
  "47a4c", //lvl 8
  "47a44", //lvl 9
  "47a6c", //lvl 10
  "47bc4", //lvl 11
  "47bcc", //lvl 12
  "47a34", //lvl 13
  "47a3c", //lvl 14
  "47a14", //lvl 15
  "47bdc", //lvl 16
  "47bd4", //lvl 17
  "47a2c", //lvl 18
  "47a24", //lvl 19
  "47a1c", //lvl 20
  "47964", //lvl 21
  "4797c", //lvl 22
  "47984", //lvl 23
  "4798c", //lvl 24
  "479f4", //lvl 25
]
const EVENTLEVELS = require('./json/levels.json')

//require("../node_modules/leaflet-draw/dist/leaflet.draw.css")

var maxBounds = L.latLngBounds(L.latLng(-70, -185), L.latLng(90, 185));
var mapZoom = localStorage.mapZoom || 3;
var mapPosLat = isLat || localStorage.mapPosLat || 36.24396;
var mapPosLng = isLng || localStorage.mapPosLng || 9.31109;

const theme = createTheme({
  typography: {
    fontFamily: 'Bai Jamjuree',
    caption: {
      letterSpacing: 1,
      fontWeight: "bold"
    },
    subtitle1: {
      letterSpacing: 1,
      fontWeight: "bold"
    },
    subtitle2: {
      letterSpacing: 1,
      fontWeight: "bold"
    },    
    body1: {
      letterSpacing: 1
    },
    body2: {
      letterSpacing: 1
    },    
    h3: {
      letterSpacing: 1,
      fontWeight: "bolder",
    },
    h4: {
      letterSpacing: 1,
      fontWeight: "bolder",
    },    
    h5: {
      letterSpacing: 1,
      fontWeight: "bolder",
    },
    h6: {
      letterSpacing: 1,
      fontWeight: "bolder",
    }
  },
  palette: {
    primary: {
      main: "#636363"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
        borderRadius: 10,
        padding: 10,
        border: "1px solid #12ccc5"
      }
    },
    MuiPopover: {
      paper: {
        background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
      }
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        padding: "5px 10px",
        fontWeight: "bolder"
      }
    }
  }
});

// const darkTheme = createTheme({
//   palette: {
//     type: "dark"
//   },
// });


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      bottom: theme.spacing(1),
      right: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
  },
  wrapper: {
    position: 'absolute',
    marginTop: theme.spacing(0),
    height: 380,
  }, 
  topRight: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(2),
      marginTop: theme.spacing(1),
  },
  bottomRight: {
    position: "absolute",
    bottom: 5,
    right: theme.spacing(1),
  },
  questFab: {
    color: 'white',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    transition: "all 700ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
    transformOrigin: "50% 50%",
    borderRadius: "5px",
    width: 48,
    height: 48,
    border: "1px solid #12ccc5",
    '&:hover': {
      background: "radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11))",
    },
  },  
  bottomLeft: {
    '& > *': {
      bottom: theme.spacing(1),
      left: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
  },
  popover: {
    display: "none"
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "90%"
  },
  menuButton: {
    color: 'white',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    transition: "all 700ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
    transformOrigin: "50% 50%",
    borderRadius: "10px",
    width: 64,
    height: 64,
    border: "1px solid #12ccc5",
    '&:hover': {
      background: "radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11))",
    },
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: 5000,
  },
  btnlogo: {
    width: "50%",
  }
}));

const NewLinearProgress = withStyles({
  root: {
    height: 5,
    borderRadius: 5,
    margin: 0,
    padding: 0,
  },
  colorPrimary: {
    background: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 0,
    background: 'linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% )',
  },
})((props) => <LinearProgress color="default" {...props} />);

function init(initialContent) {
  return {content: initialContent};
}

const contentReducer = (state, action) => {
  //if (process.env.REACT_APP_DEBUG) console.log("state,action", state, action)
  if (action.type === "delete") { 
    return {content: state.content.filter((c) => c.id !== action.id)}
  }
  if (action.type === "bounds") { 
    let out = state.content.map((c) => {
      if (c.id === action.id) {
        return {...c, bounds: action.payload}
      }
      return c
    })
    return {content: [...out]}
  }
  if (action.type === "lock") { 
    let out = state.content.map((c) => {
      if (c.id === action.id) {
        return {...c, locked: action.payload}
      }
      return c
    })
    return {content: [...out]}
  } 
  if (action.type === "visible") { 
    let out = state.content.map((c) => {
      if (c.id === action.id) {
        return {...c, visible: action.payload}
      }
      return c
    })
    return {content: [...out]}
  }      
  if (action.type === "editmultiple") { 
    let out = state.content.map((c) => {
      if (action.ids.indexOf(c.id) > -1) {
        return {...c, [action.action]: action.payload }
      }
      return c
    })
    return {content: [...out]}
  }
  if (action.type === "deletemultiple") { 
    return {content: state.content.filter((c) => action.ids.indexOf(c.id) === -1)}
  }         
  if (action.type === "editcontent") { 
    let out = state.content.map((c) => {
      if (c.id === action.id) {
        if (process.env.REACT_APP_DEBUG) console.log("editcontent", {...c, ...action.payload})
        return {...c, ...action.payload}
      }
      return c
    })
    return {content: [...out]}
  }    
  if (action.type === "add") {
    return {content: [...state.content, action.payload]}
  }
  if (action.type === "reset") {
    return init(action.payload)
  }      
  return {content: null}
}

function initCells(initialContent) {
  return {data: initialContent, filled: initialContent && initialContent.map(c => c.celdaCod)};
}

const cellReducer = (state, action) => {
  //if (process.env.REACT_APP_DEBUG) console.log("state,action", state, action)
  if (action.type === "add") {
    const cells = [...action.payload]
    if (!state.data || state.data.length === 0) {
      return {data: cells, filled: [...cells].map(c => c.celdaCod)}
    }
    const newCells = [...cells.filter(c => state.filled.indexOf(c.celdaCod) === -1)]
    return {data: [...state.data, ...newCells], filled: [...state.filled, ...newCells.map(c => c.celdaCod)]}
  }
  if (action.type === "replace") {
    const cells = [...state.data].map(c => {
      if (c.celdaCod === action.payload.celdaCod) {
        return {...c, ...action.payload.content}
      }
      return c  
    })
    return {data: [...cells], filled: [...cells.map(c => c.celdaCod)]}
  }  
  if (action.type === "reset") {
    return initCells(action.payload)
  }      
  return {data: null}
}

function Mapa(props) {
  const auth = localStorage.getItem("cells:auth");
  //const { loading, error, data } = useQuery(GET_TRANSFERS);
  // const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  //var maxBounds = new L.LatLngBounds(L.latLng(-56.158629, -165.041461), L.latLng(75.958444, 178.790486));
  const [cells, dispatchCells] = useReducer(cellReducer, undefined, initCells)
  const [map, setMap] = useState(null)
  const [loading, setLoading] = useState()
  const [loadingProgress, setLoadingProgress] = useState()
  const mapStyle = useRef(isMap || localStorage.mapStyle)
  const [userAddress, setUserAddress] = useState()
  const [welcomeOpen, setWelcomeOpen] = useState(false)
  const [userAccount, setUserAccount] = useState()
  const [connected, setConnected] = useState(false)
  const [newUser, setNewUser] = useState(false)
  const [accessToken, setAccessToken] = useState(auth)
  const [selectedCell, setSelectedCell] = useState()
  const [cellBackground, setCellBackground] = useState(randomBackground())
  const [backgroundChanged, setBackgroundChanged] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [currentContent, setCurrentContent] = useState()
  const [currentChain, setCurrentChain] = useState()
  const [editContent, setEditContent] = useState(false)
  const [addNft, setAddNft] = useState(false)
  const [upload, setUpload] = useState(false)
  const [uploadedFile, setUploadedFile] = useState()
  const [browseFiles, setBrowseFiles] = useState(false)
  const [cellSettings, setCellSettings] = useState()
  const [editCellSettings, setEditCellSettings] = useState(false)
  const [dragging, setDragging] = useState()
  const [highlighted, setHighlighted] = useState()
  const [celdaReward, setCeldaReward] = useState()
  const [state, dispatch] = useReducer(contentReducer, undefined, init)
  const [contentChanged, setContentChanged] = useState(false)
  const [selectedContent, setSelectedContent] = useState()
  const [muteAll, setMuteAll] = useState(false)
  const [mainMenuOpen, setMainMenuOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentStats, setCurrentStats] = useState(false)
  const [celdaAmounts, setCeldaAmounts] = useState()
  const [contextMenuOpen, setContextMenuOpen] = useState({})
  const [checkedContent, setCheckedContent] = useState([])
  const [checkedStatus, setCheckedStatus] = useState({locked: false, visible: true})
  //const [groupByStatus, setGroupByStatus] = useState({locked: 0, hidden: 0})
  const [sortEl, setSortEl] = useState(undefined)
  const [sortStatus, setSortStatus] = useState({field: "name", direction: 1})
  const [sortedList, setSortedList] = useState([])
  const [showBorders, setShowBorders] = useState(true)
  const [joinedEvent, setJoinedEvent] = useState(localStorage.getItem("joinedevent1") === "1")
  const [filters, setFilters] = useState({})
  const classes = useStyles()
  const [provider, setProvider] = useState((window.ethereum) ? new ethers.providers.Web3Provider(window.ethereum) : undefined)
  const customBg = useRef()
  const currPos = useRef()
  const { snackbarShowMessage, payload, origins } = props
  const hdr = useRef(isHdr)
  const [cellReroll, setCellReroll] = useState()
  const [cellEvolve, setCellEvolve] = useState()
  const [battlesWelcome, setBattlesWelcome] = useState(true)
  

  // const favoriteCells = JSON.parse(localStorage.getItem("cells:favorite"))
  // const ownCells = JSON.parse(localStorage.getItem("cells:own"))

  // HANDLERS

  const handleLoggedIn = useCallback((result) => {
    if (!result || !result.userData || !result.at || result.error) {
      setLoading()
      return {error: result?.error || "user data not found"}
    } else {
      const userData = result.userData
      localStorage.setItem("cells:auth", String(result.at));
      localStorage.setItem("cells:exp", String(result.exp));
      setAccessToken(String(result.at));
      if (userData && userData.address) {
        localStorage.setItem("cells:address", String(userData.address));
        fetchUserData(userData.address, String(result.at))
      }
      setLoading()
      snackbarShowMessage((userData.username) ? "Welcome to Cells!" : <span>Welcome to Cells, <b>{userData.username}</b>!</span>)
      return {result: "logged in"};  
    }
  }, [snackbarShowMessage, newUser])

  const handleLoggedOut = async (msg = "See you around!") => {
    const res = await clearCookie()
    if (process.env.REACT_APP_DEBUG) console.log("clearcookie", res)
    localStorage.removeItem("cells:auth")
    localStorage.removeItem("cells:exp")
    localStorage.removeItem("cells:address")
    setUserAccount(undefined)
    setAccessToken(undefined)
    setLoading()
    snackbarShowMessage(msg, "info")
  }

  const handleEditContent = (content) => {
    if (process.env.REACT_APP_DEBUG) console.log("editContent", content);
    setEditContent(content);
  }    
  const handleAddNft = () => {
    setAddNft(!addNft);
  }    
  
  const handleUnload = (e) => {
    if (editMode || editCellSettings) {
      e.preventDefault();
      e.returnValue = '';  
    }
  }
  
  const handleVisitCell = useCallback((data) => {
    const visitedCells = userAccount && userAccount.visitedCells
    if (!accessToken || !userAccount || visitedCells === null || visitedCells.find((c) => c.cell === data.celdaCod)) return
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("handleVisitCell", "at not present")
        return null
      }      
      let at = result.at
      try {
        let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at },
          body: JSON.stringify({id: data._id}),
        }
  
        let url = process.env.REACT_APP_API_URL + '/visit';
    
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (result) => { 
            if (result && !result.error) {
              const acc = {...userAccount}
              acc.visitedCells = [...visitedCells, {...data}]
              acc.visitedCount++
              if (result.reward) setCeldaReward(result.reward)
              setUserAccount(acc)
              return
            }
            if (process.env.REACT_APP_DEBUG) console.log("error", result && result.error)
          },
          (error) => {
              if (process.env.REACT_APP_DEBUG) console.log("error", error)
          }
        ); 
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log("error", err)
      }
    }, (err) => {if (process.env.REACT_APP_DEBUG) console.log("error", err)})
   
  }, [accessToken, userAddress, userAccount])

  const handlePageChange = useCallback((e,celdaCod = undefined) => {
    const h = window.history
    if (!h.state) {
      if (e && editMode) {
        e.preventDefault();
        e.returnValue = '';  
        if (!window.confirm("Any changes you made will be lost. Leave?")) { 
          celdaCod = selectedCell.celdaCod
        } else {
          dispatch({type: "reset", payload: [...currentContent] })
        }
      }
      if (celdaCod && celdaCod !== "/") {
        //h.pushState({page: 0, cell: undefined}, "Cells" + celdaCod, "/")
        h.pushState({page: 1, cell: celdaCod}, "Cells - " + celdaCod, "/?cell=" + celdaCod)
      } else {
        handleSelectedCell(false)
      }
    } else {
      let cell = h.state.cell
      if (cell) {
        if (!selectedCell) {
          let data = cells && cells.data && cells.data.find((c) => c.celdaCod === cell)
          if (data) handleSelectedCell(data)
        } else {
          handleSelectedCell(false)
        }
      } else {
        handleSelectedCell(false)
      }
    }
  }, [editMode, cells, selectedCell, currentContent])


  const enterPlayground = async (data) => {
    let c = document.getElementsByClassName('leaflet-bottomleft-menu')
    const res = await getCellContents(data, userAccount)
    if (res === "denied" || res === "no contents") return
    c[0].style.display = "none"
    currPos.current = [data.center[0], data.center[1], 8]
    handleVisitCell(data)
    let bgurl = randomBackground();
    if (!!data.cellSettings) {
      const cellBg = data.cellSettings.cellBackground
      if (cellBg && cellBg !== "RANDOM" && cellBg !== "CUSTOM" && cellBg !== "RANDOMNFT") {
        bgurl = data.cellBackground
      }
      if (cellBg && cellBg === "CUSTOM") {
        bgurl = data.cellSettings.cellCustomBackground
      }
      if (cellBg && cellBg === "RANDOMNFT") {
        bgurl = getRandomNFTURL(data.cellSettings.cellBackgroundNFTCollection)
      }
      if (!data.cellSettings.cellBackgroundStyle) data.cellSettings.cellBackgroundStyle = "tiles"
    }
    setCellSettings({...data.cellSettings})
    setCellBackground(bgurl)
    setSelectedCell(data)
    handlePageChange("",data.celdaCod)
    map.setMaxBounds(L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)))
    if (data.cellSettings && data.cellSettings.cellStartPosition && data.cellSettings.cellStartZoom) {
      map.setView(L.latLng(isLat || data.cellSettings.cellStartPosition[0], isLng || data.cellSettings.cellStartPosition[1]), (isZoom || data.cellSettings.cellStartZoom) + (!!isPic ? 1 : 2), {animate: false});
    } else {
      map.fitBounds([data.v1, data.v2, data.v3, data.v4], { padding: [0, 0], animate: false });
    }
  }


  const enterEventCell = async (data) => {
    let c = document.getElementsByClassName('leaflet-bottomleft-menu')
    const res = await getQuestContents(data, userAccount)
    if (res === "denied" || res === "no contents" || !res) return
    c[0].style.display = "none"
    currPos.current = [data.center[0], data.center[1], 8]
    //handleVisitCell(data)
    let bgurl = randomBackground();
    if (!!res.cellSettings) {
      const cellBg = res.cellSettings.cellBackground
      if (cellBg && cellBg !== "RANDOM" && cellBg !== "CUSTOM" && cellBg !== "RANDOMNFT") {
        bgurl = res.cellBackground
      }
      if (cellBg && cellBg === "CUSTOM") {
        bgurl = res.cellSettings.cellCustomBackground
      }
      if (cellBg && cellBg === "RANDOMNFT") {
        bgurl = getRandomNFTURL(res.cellSettings.cellBackgroundNFTCollection)
      }
      if (!res.cellSettings.cellBackgroundStyle) res.cellSettings.cellBackgroundStyle = "tiles"
    }
    setCellBackground(bgurl)
    setSelectedCell(data)
    handlePageChange("",data.celdaCod)
    map.setMaxBounds(L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)))
    if (res.cellSettings && res.cellSettings.cellStartPosition && res.cellSettings.cellStartZoom) {
      map.setView(L.latLng(isLat || res.cellSettings.cellStartPosition[0], isLng || res.cellSettings.cellStartPosition[1]), isZoom || res.cellSettings.cellStartZoom + (!!isPic ? 1 : 2), {animate: false});
    } else {
      map.fitBounds([data.v1, data.v2, data.v3, data.v4], { padding: [0, 0], animate: false });
    }
  }


  const handleSelectedCell = useCallback(async (data, updateMap = true) => {
    let c = document.getElementsByClassName('leaflet-bottomleft-menu')
    if (!!data) {
      //setLoading("GATHERING CELL DATA...")
      if (accessToken && EVENTACTIVE && (!EVENTRESTRICTED || EVENTCELLS.indexOf(data.celdaCod) !== -1) && joinedEvent) {
        enterEventCell(data)
      } else {
        enterPlayground(data)
      }      
    } else {
      c[0].style.display = "block"
      setCellSettings(undefined)
      setCellBackground(undefined)
      if (updateMap) mapStyle.current = isMap || localStorage.mapStyle || "Esri WorldImagery"
      setSelectedCell(data)   
      if (currPos.current) {
        localStorage.mapPosLat = currPos.current[0]
        localStorage.mapPosLng = currPos.current[1]
        localStorage.mapZoom = currPos.current[2]  
      }
      //map.setZoom(localStorage.mapZoom || 10)      
      map.setMaxBounds(maxBounds)
      //if (process.env.REACT_APP_DEBUG) console.log("pos",localStorage.mapPosLat, localStorage.mapPosLng, localStorage.mapZoom,cellBackground,mapStyle)
      map.setView(L.latLng(localStorage.mapPosLat, localStorage.mapPosLng), isZoom || localStorage.mapZoom, { duration: 0.7 });
    }
    setLoading()
    setBackgroundChanged(false)
    setEditMode(false)
  },[currPos, map, userAccount, joinedEvent, accessToken])
  
  
  const getCellContents = async (data, userAccount) => {
    const cellContents = data.cellContent ? data.cellContent : await fetchCellContents(data)
    const userData = {...userAccount}
    if ((!cellContents || cellContents.length === 0 || (data.cellSettings && data.cellSettings.cellPrivate)) && (!userData || !userData.address || !data.owner || data.owner.address !== userData.address) && (!isPic || isOutside)) {
      map.fitBounds([data.v1, data.v2, data.v3, data.v4], { padding: [20, 20], animate: false });
      setLoading()
      return "denied"
    }
    if (!cellContents) {
      setLoading()
      return "no contents"
    }
    let contentWithId = cellContents && cellContents.map((cid) => {
      if (cid) {
        if (!cid.id) cid.id = generateId()
        cid.visible = (cid.visible === undefined) ? true : cid.visible;
        cid.locked = (cid.locked === undefined) ? false : cid.locked;
        cid.keepRatio = (cid.keepRatio === undefined) ? (cid.type === "div") ? false : true : cid.keepRatio;
        cid.priority = cid.priority || 25
        cid.visibleZoomRange = cid.visibleZoomRange || [1, 16]
        return cid  
      }
      return null
    })
    data.cellContent = [...contentWithId]
    setCurrentContent([...contentWithId])
    dispatch({type: "reset", payload: [...contentWithId]})
    return null
  }


  const getQuestContents = (data, userAccount) => {
    return refreshToken().then(async (result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("fetchUserData", "at not present")
        return null
      }
      const cellData = await fetchQuestContents(data, result.at)
      const cellContents = cellData.cellContent
      const cellSettings = cellData.cellSettings
      if (!cellContents) {
        return "no contents"
      }
      let contentWithId = cellContents && cellContents.map((cid) => {
        if (cid) {
          if (!cid.id) cid.id = generateId()
          cid.visible = (cid.visible === undefined) ? true : cid.visible;
          cid.locked = (cid.locked === undefined) ? false : cid.locked;
          cid.keepRatio = (cid.keepRatio === undefined) ? (cid.type === "div") ? false : true : cid.keepRatio;
          cid.priority = cid.priority || 25
          cid.visibleZoomRange = cid.visibleZoomRange || [1, 16]
          return cid  
        }
        return null
      })
      let elvls = EVENTLEVELS
      elvls[0].divHtml = elvls[0].divHtml.replace("%level%",EVENTCELLS.indexOf(data.celdaCod))
      
      setCellSettings({...cellSettings})
      setCurrentContent([...contentWithId, ...elvls])
      dispatch({type: "reset", payload: [...contentWithId, ...elvls]})
      return cellData
    })

  }

  const handleDrawerClick = (id) => {
    if (!editMode) return;
    handleDrawerCheckbox(id)
    //setDrawerOpen(false)    
    //handleEditContent(id)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setHighlighted(undefined)
    setCheckedContent([])
  } 

  const handleDrawerContextMenu = (id, locked, visible, e) => {
    e.preventDefault()
    if (!editMode || !selectedCell || selectedCell.lockedStatus === "locked") return
    setContextMenuOpen({id: id, top: e.clientY, left: e.clientX, locked: locked, visible: visible})
  } 
  
  const handleDrawerMultiple = (action) => {
    let len = checkedContent.length
    if (action === "delete") {
      if (window.confirm(`This will delete ${len} item${len > 1 ? "s" : ""}. Are you sure?`)) {
        dispatch({type: "deletemultiple", ids: [...checkedContent]})
        snackbarShowMessage(`Deleted ${len} item${len > 1 ? "s" : ""}`)
      } else { return }  
    } 
    if (action === "locked" || action === "visible") {
      let msg = 'locked'
      if (action === "locked" && checkedStatus.locked) msg = "unlocked"
      if (action === "visible") msg = checkedStatus.visible ? "hidden" : "shown"
      dispatch({type: "editmultiple", action: action, ids: [...checkedContent], payload: !checkedStatus[action]})
      setCheckedStatus({locked: checkedStatus.locked, visible: checkedStatus.visible})    
      snackbarShowMessage(`${len} item${len > 1 ? "s" : ""} ${msg}`)
    }
    setCheckedContent([])    
  }   
  
  const handleDrawerCheckbox = (id, e) => {
    let newContent = (checkedContent.indexOf(id) !== -1) ? checkedContent.filter((c) => c !== id) : [...checkedContent, id]
    if (newContent.length === 1) {
      let content = {...state.content.find((c) => c.id === newContent[0])}
      setCheckedStatus({locked: (content.locked === undefined) ? false : content.locked, visible: (content.visible === undefined) ? true : content.visible})    
    }
    setCheckedContent(newContent)
  }

  const handleDrawerCheckAll = () => {
    setCheckedContent((checkedContent.length < 2) ? state.content.map(c => c.id) : [])
  }    

  const highlightContent = (id, bounds, action) => {
    if (action === "on") map.fitBounds(L.latLngBounds(L.latLng(bounds[0][0], bounds[0][1]), L.latLng(bounds[1][0], bounds[1][1])).pad(.5))
    setHighlighted((action === "on") ? id : undefined)
  }    

  const handleContextEdit = (id) => {
    handleContextMenuClose()
    handleEditContent(id)
  }  

  const handleContextHide = (id, visible) => {
    handleContextMenuClose()
    if (visible === undefined) visible = true
    dispatch({type: "visible", id: id, payload: !visible})
  }  

  const handleContextDelete = (id) => {
    handleContextMenuClose()
    if (window.confirm("Are you sure?")) {
      dispatch({type: "delete", id: id})
      snackbarShowMessage("Deleted", "info")
    } 
  }  

  const handleContextLock = (id, locked) => {
    handleContextMenuClose()
    if (locked === undefined) locked = false
    dispatch({type: "lock", id: id, payload: !locked})
  }    

  const handleContextMenuClose = () => {
    setContextMenuOpen({id: undefined})
  }  

  const handleSort = (f) => {
    handleSortClose()    
    let dir = (sortStatus.field === f) ? sortStatus.direction * -1 : (f === "locked") ? -1 : 1;
    setSortStatus({field: f, direction: dir})
  }  

  const handleSortOpen = (e) => {
    setSortEl(e.target)
  }  
    
  const handleSortClose = () => {
    setSortEl(undefined)
  }  
  
  const handleMenu = (action) => {
    if (action === "add") {
      if (!editMode) setEditMode(true)
      handleEditContent("new")
    }    
    if (action === "addnft") {
      if (!editMode) setEditMode(true)
      handleAddNft()
    }
    handleContextMenuClose()
  }

  
  const joinEvent = () => {
    if (!EVENTACTIVE || !userAccount) return
    let eventstate = (localStorage.getItem("joinedevent1") || -1) * -1
    if (eventstate === 1) {
      if (1===2 && userAccount && userAccount.tokenBalance > 0) {
        localStorage.setItem("joinedevent1",1)
        setJoinedEvent(true)  
      } else {
        refreshToken().then(result => {
          try {
            let requestOptions = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + result.at},
            }
      
            let url = process.env.REACT_APP_API_URL + '/joinQuest';
        
            fetch(url, requestOptions)
            .then(res => res.json())
            .then(
              (result) => { 
                if (result.result.startsWith("joined"))  {
                  snackbarShowMessage("Welcome to the first 🌍 Cells + Spookies 👻 Quest! Good luck!")
                  localStorage.setItem("joinedevent1",1)
                  setJoinedEvent(true)  
                } else {
                  snackbarShowMessage("You need to hold a Cell or a Spookie to join the Quest","error")
                }
              },
              (error) => {
                console.log("error", error)
              }
            ); 
      
          } catch (err) {
            
          }      
        })

      }
      
    } else {
      snackbarShowMessage("Leaving quest mode. Click the spooky zone to join again!","info")
      localStorage.setItem("joinedevent1",-1)
      setJoinedEvent(false)
    }
    
  }

  //  EFFECTS

  useEffect( () => {
    const { ethereum } = window;
    const at = localStorage.getItem("cells:auth")
      
    const handleAccountsChanged = async (accounts) => {
      if (process.env.REACT_APP_DEBUG) console.log("handleAccountsChanged", accounts)
      const address = localStorage.getItem("cells:address")
      const cid = await ethereum.request({ method: 'eth_chainId' });
      setCurrentChain(cid)
      if (accounts.length > 0 && at && !!address && accounts[0].toLowerCase() !== address.toLowerCase()) {
        handleLoggedOut("You have been logged out because you changed accounts. See you around!")
      } else {
        if ((accounts.length > 0 || address) && at) fetchUserData(accounts[0] || address)
      }
      setUserAddress(accounts.length > 0 ? accounts[0] : undefined)
      setConnected(accounts.length > 0)
      setLoading()
    }

    const handleChainChanged = (chainId) => {
      if (process.env.REACT_APP_DEBUG) console.log("chainChanged", chainId);
      //if (!!at) handleLoggedOut("You have been logged out because you changed network. See you around!")
      snackbarShowMessage("You changed networks. Reloading...", "info")
      setTimeout(() => window.location.reload(),1000)
      setCurrentChain(chainId)
    }   
    
    const handleDisconnect = (code, reason) => {
      setUserAddress(undefined)
      setConnected(false)
      if (!!at) handleLoggedOut("You have been logged out because disconnected your wallet. See you around!")
      else snackbarShowMessage("Wallet disconnected!", "info")
    }   

    if (ethereum) {
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('chainChanged', handleChainChanged);
      if (!connected && !!ethereum.selectedAddress) { 
        handleAccountsChanged([ethereum.selectedAddress])  
      } else {
        if (!connected) ethereum.request({method:'eth_accounts'}).then(handleAccountsChanged)
      }
    } else {
      if (connected && provider && provider.provider) {
        provider.provider.on('accountsChanged', handleAccountsChanged)
        provider.provider.on('chainChanged', handleChainChanged);
        provider.provider.on('disconnect', handleDisconnect);
      }
    }
    return () => {
      if (ethereum) {
        ethereum.removeListener("chainChanged", handleChainChanged);
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
      } else {
        if (provider && provider.provider) {
          provider.provider.removeListener("chainChanged", handleChainChanged);
          provider.provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.provider.removeListener('disconnect', handleDisconnect);
        }
      }
    }
  },[]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("popstate", handlePageChange);

    return () => {
      window.removeEventListener("beforeunload", handleUnload)
      window.removeEventListener("popstate", handlePageChange);
    }
  });

  useEffect(() => {
    setWelcomeOpen(true)
    try {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
      }

      let url = process.env.REACT_APP_API_URL + '/getStats';
  
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          setCurrentStats(result)
          //if (process.env.REACT_APP_DEBUG) console.log(result)
        },
        (error) => {
          
        }
      );
     
      url = process.env.REACT_APP_API_URL + '/celda/getAmounts';
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          setCeldaAmounts(result)
          //if (process.env.REACT_APP_DEBUG) console.log(result)
        },
        (error) => {
          
        }
      );    

    } catch (err) {
      
    }

  },[])

  useEffect(() => {
    if (isDiscord && userAccount && connected && accessToken && !userAccount.discord) {
      try {
        let requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken},
        }
  
        let url = process.env.REACT_APP_API_URL + '/discord/' + isDiscord;
    
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (result) => { 
            if (result && !result.error) {
              let u = {...userAccount}
              u.discord = result
              setUserAccount(u)
              setCeldaReward({amount: 5, desc: `Linked Discord account ${result.username}#${result.discriminator}!`})
              window.history.replaceState({}, undefined, "/")
            } else {
              snackbarShowMessage(`Could not link Discord account${!!result.error && ": " + result.error}`,"error")
              console.log("no result")
            }
            //if (process.env.REACT_APP_DEBUG) console.log(result)
          },
          (error) => {
            console.log(error)
          }
        ); 
  
      } catch (err) {
        console.log(err)
      }
    }
    return () => {}

  },[userAccount, connected, accessToken])
  
  function fetchUserData(address, at = accessToken) {
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("fetchUserData", "at not present")
        return null
      }
      let at = result.at
      try {
        let requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (at || accessToken)},
        }
  
        let url = process.env.REACT_APP_API_URL + '/getUserData'
        // setLoading("FETCHING USER DATA...")
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (r) => { 
            if (r) {
              if (r.reward) {
                setCeldaReward(r.reward)
              }
              delete r.reward
              setUserAccount(r)   
              setLoading()
            }
          },
          (error) => {
            if (process.env.REACT_APP_DEBUG) console.log("no user data")
            setLoading()
          }
        ); 
  
      } catch (err) {
        setLoading()
      }
    }, (err) => {
      if (process.env.REACT_APP_DEBUG) console.log("error", err)
    })
  }

  const refreshToken = () => {
    const at = localStorage.getItem("cells:auth")
    const exp = localStorage.getItem("cells:exp")
    return new Promise((resolve, reject) => {
      if (!at || !exp) { return reject({error: "no access token"}); }
      if (exp > Date.now()) return resolve({at: at})

      fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at },
        credentials: 'include'
      })
      .then(response => response.json())
      .then((result) => {
        if (!!result.at && !!result.exp) {
          localStorage.setItem("cells:auth", String(result.at));
          localStorage.setItem("cells:exp", String(result.exp));
          setAccessToken(String(result.at));
          return resolve({at: String(result.at)});    
        } else {
          handleLoggedOut("Login info not found. You'll need to log back in.")
          return reject({error: result})
        }
      })
      .catch((err) => {
        handleLoggedOut("Login info not found. You'll need to log back in.")
        return reject({error: err})
      })
    })

  }


  const uploadFileToIPFS = (file, background = false) => {
    return new Promise((resolve, reject) => {
      if (file.size > 105000000) {
        snackbarShowMessage("Oops! Can't upload that. Max file size is 100MB.", "error")
        return reject({error: "Oops! Can't upload that. Max file size is 100MB."})
      }
      if (!(file.type.match(/image\//) || file.type.match(/video\//) || file.type.match(/model\//))) {
        snackbarShowMessage("File type not allowed: " + file.type, "error")
        return reject({error: "File type not allowed: " + file.type})
      }
      refreshToken().then(ok => {
        let at = ok.at
        if (!at) {
          if (process.env.REACT_APP_DEBUG) console.log("cookie not found")
          return reject({error: "cookie not found"})
        }
        setLoadingProgress()
        setLoading("UPLOADING FILE...")
        getApiKey(at).then(key => {
          const url = "https://api.pinata.cloud/pinning/pinFileToIPFS"
          let data = new FormData();
          data.append('file',file)
          const metadata = JSON.stringify({
            name: file.name,
            keyvalues: {
                uploadedBy: userAccount.address
            },
          });
  
          data.append('pinataMetadata', metadata);
  
          axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              pinata_api_key: key.pinata_api_key,
              pinata_secret_api_key: key.pinata_api_secret
            },
            maxBodyLength: 'Infinity', 
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              setLoadingProgress(percentCompleted)
            }
          })
          .then(async response => {
            if (response.data) {
              var reader = new FileReader();
  
              //Read the contents of Image File.
              reader.readAsDataURL(file);
              reader.onload = async (e) => {
                let fileData = {
                  name: file.name,
                  size: file.size,
                  type: file.type,  
                  hash: response.data.IpfsHash,
                  date: Date.now(),
                }
                if (file.type.match(/image\//) || file.type.match(/video\//)) {
                  const sizes = await checkMedia(file.type.match(/image\//) ? "image" : "video", e.target.result)
                  if (sizes.height && sizes.width) fileData = {...fileData, width: sizes.width, height: sizes.height}
                }
                await updateFile(at, fileData)
                //if (!browseFiles && !background) setBrowseFiles(true)
                if (upload) setUpload(false)      
                return resolve(fileData)
              };
            } else {
              setLoading()
              setLoadingProgress()
              snackbarShowMessage("Something went wrong", "error")
              return reject({error: "Something went wrong"})
            }              
          })
          .catch(error => {
            setLoading()
            setLoadingProgress()
            console.error(error)
            return reject({error: error})
          })
        })
      })
    })
  }

  const updateFile = async (at, fileData) => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at },
      body: JSON.stringify(fileData),
    }
    //if (process.env.REACT_APP_DEBUG) console.log(process.env.NODE_ENV);
    let url = process.env.REACT_APP_API_URL + '/uploadedFile';

    fetch(url, requestOptions)
    .then(res => res.json())
    .then(
      (result) => { 
        //if (process.env.REACT_APP_DEBUG) console.log(result)
        if (result.ok === 1) {
          let user = {...userAccount}
          let exists = !user.ipfsFiles || !!user.ipfsFiles.find(f => f.hash === fileData.hash)
          if (!exists) {
            user.ipfsFiles = !!user.ipfsFiles ? [...user.ipfsFiles, fileData] : [user.ipfsFiles]
            setUserAccount(user)
          }
        } else {
          snackbarShowMessage("Something went wrong...", "error");                
        }
        setLoading()
        setLoadingProgress()
      },
      (error) => {                                
        setLoading()
        setLoadingProgress()
        snackbarShowMessage(error, "error");
      }
    ); 
  }    

  const downloadFile = (url) => {
    let mimeTypes = {
      "image/jpeg": "jpg",
      "image/gif": "gif",
      "image/png": "png",
      "image/webp": "webp",
      "image/svg+xml": "svg",
      "video/webm": "webm",
      "video/mp4": "mp4",
      "video/ogg": "ogg",
    }
    return new Promise((resolve, reject) => {
      setLoadingProgress()
      setLoading("DOWNLOADING FILE...")
      axios.get(url,
        {
            responseType: 'arraybuffer', 
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              setLoadingProgress(percentCompleted)
            },
            mode: 'no-cors',
            crossdomain: true
        })
        .then((response) => {
          if (response.data) {
            let filename = url.split('/').pop().split('?')[0].split('#')[0]
            let type = response.headers['content-type'];

            if (!filename || !filename.includes(".")) {
              let ext = mimeTypes[type]
              if (!ext) return reject({error: "file type not recognized"})
              filename = (!filename ? ext + "_" + Date.now() : filename.substring(0,20)) + "." + ext
            }
            
            const file = new File([response.data], filename, {type: type})
            setLoading()
            setLoadingProgress()
            return resolve({file: file})

          } else {
            setLoading()
            setLoadingProgress()
            return reject({error: "file not found"})
          }
   
        })
        .catch((error) => { 
          setLoading()
          setLoadingProgress()
          if (process.env.REACT_APP_DEBUG) console.log(error)
          return reject({error: error})
        });
    })
  }

  function clearCookie() {
    return new Promise((resolve, reject) => {

      fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include'
      })
      .then(response => response.json())
      .then((result) => {
          return resolve(result);
      })
      .catch((err) => { return reject({error: err}) })
    })

  }    

  const fetchCellContents = async (data) => {
    try {
      let requestOptions = {
        method: 'GET',
      }

      let url
      if (data.cellContentHash) url = "https://ipfs.thecellszone.com/ipfs/" + data.cellContentHash
      else url = process.env.REACT_APP_API_URL + '/getCellContents/' + data.celdaCod;

      return await fetch(url, requestOptions)
      .then(res => res.json())
      .then((r) => {
        return data.cellContentHash ? r.content : r
        //return r
      },
        (error) => {
          if (process.env.REACT_APP_DEBUG) console.log("error",error)
          return null
        }
      ); 

    } catch (err) {
      if (process.env.REACT_APP_DEBUG) console.log("error",err)
      return null
    }
  }

  const fetchQuestContents = async (data, at) => {
    try {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at },
      }

      let url = process.env.REACT_APP_API_URL + '/getQuestContents/' + data.celdaCod;

      return await fetch(url, requestOptions)
      .then(async (r) => {
        if (r) {
          let enc = await r.text()
          let word = process.env.REACT_APP_QUEST_WORD
          var bytes  = CryptoJS.AES.decrypt(enc, word);
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return decryptedData  
        } else {
          return {}
        }
        //return r
      },
        (error) => {
          if (process.env.REACT_APP_DEBUG) console.log("error",error)
          return null
        }
      ); 

    } catch (err) {
      if (process.env.REACT_APP_DEBUG) console.log("error",err)
      return null
    }
  }    
  
  useEffect(() => {

    let result = payload && [...payload]
    if (result && map) {
      if (isPic) {
        let d = document.getElementsByClassName('leaflet-control-container')
        d[0].style.display = "none"  
        d = document.getElementsByClassName('leaflet-bottomleft-menu')
        d[0].style.display = "none"
      }
      
      if (isCountry) setFilters({country: isCountry})
      //if (isZone) setFilters({zone: isZone})
  
      if (!!isCell) {
        let r = result.find(c => c.celdaCod === isCell)
        if (r) { 
          if (isFilter) setFilters({celdaCod: isCell.toLowerCase()})
          if (!isOutside) {
            handleSelectedCell(r)
          }
          else {
            if (!!r.custom_map) {
              if (process.env.REACT_APP_DEBUG) console.log("custom_map",r.custom_map)
              mapStyle.current = (tileLayers.find(t => t.name === r.custom_map) && r.custom_map) || isMap || "Esri WorldImagery"
            }
            map.fitBounds(L.latLngBounds([r.v1, r.v2, r.v3, r.v4]).pad(0.2), {animate: false})
          }
        } else {
          if (!!isCell) snackbarShowMessage(`Cell Code ${isCell} not found`, "error")
        }
      } else {
        if (!!isTokenId) {
          let r = result.find(c => Number(c.tokenId) === Number(isTokenId))
          if (r) {
            if (isFilter) setFilters({tokenId: isTokenId})
            if (process.env.REACT_APP_DEBUG) console.log("isTokenId", isTokenId)
            if (!!r.custom_map) {
              if (process.env.REACT_APP_DEBUG) console.log("custom_map",r.custom_map)
              mapStyle.current = (tileLayers.find(t => t.name === r.custom_map)  && r.custom_map) || isMap || "Esri WorldImagery"
            }
            map.fitBounds(L.latLngBounds(r.extendedCoords?.length ? r.extendedCoords : [r.v1, r.v2, r.v3, r.v4]).pad(0.2), {animate: false})
          } else {
            if (!!isTokenId) snackbarShowMessage(`Token ID ${isTokenId} not found`, "error")
          }
        } else {
          if (isZone) {
            let zone = zones.find(z => z.shortName.toLowerCase() === isZone.toLowerCase())
            if (zone) {
              if (isFilter) setFilters({zone: isZone.toLowerCase()})
              map.fitBounds(zone.bounds, {animate: false});
            }
          } 
          else map.setView(L.latLng(mapPosLat, mapPosLng), mapZoom || 10, {animate: false});
        }
      } 
    }

          
  },[map, payload]) 


  useEffect(() => {
    if (map && payload) {
      dispatchCells({type: "add", payload: [...payload]})
    }
  },[payload, map])

  useEffect(() => {
    let a = document.getElementsByClassName('leaflet-control-container')
    let b = document.getElementsByClassName('leaflet-bottomleft-menu')
    let c = document.getElementsByTagName('footer')

    if ((filters && (!!filters.country || !!filters.contractAddress || filters.showmycells)) || isPic) {
      if (a[0]) a[0].style.display = "none"  
      if (b[0]) b[0].style.display = "none"  
      if (c[0]) c[0].style.display = "none"
      if (c[1]) c[1].style.display = "none"
    } else {
      if (a[0]) a[0].style.display = "block"  
      if (b[0]) b[0].style.display = "block"
      if (c[0]) c[0].style.display = "block"
      if (c[1]) c[1].style.display = "block"
    }
    return () => {

    }
  },[filters])


  const isRandomNFT = cellSettings && cellSettings.cellBackground === "RANDOMNFT"
  const isRandom = cellSettings && cellSettings.cellBackground === "RANDOM"
  const isCustom = cellSettings && cellSettings.cellBackground === "CUSTOM"
  const isPlain = cellSettings && cellSettings.cellBackground === "PLAIN"
  const isTiles = cellSettings && (!cellSettings.cellBackgroundStyle || cellSettings.cellBackgroundStyle === "tiles")

  useEffect(() => {
    const bgRef = customBg.current
    const bgcontainer = document.getElementsByClassName('leaflet-container')[0]
    if (selectedCell && isTiles && !isPlain) {
      //if (process.env.REACT_APP_DEBUG) console.log("setBG", bgRef, bgRef._url, cellSettings.cellCustomBackground)
      let tileSize = cellSettings.cellTileSize
      if (tileSize < 100) tileSize = 100
      if (isRandomNFT) {
        setCellBackground(getRandomNFTURL(cellSettings.cellBackgroundNFTCollection))
      }
      if (bgRef && isTiles) {
        //if (isRandomNFT) tileSize = 400
        bgRef._tileSize = L.point(tileSize || 500, tileSize || 500)
        bgRef.options.tileSize = tileSize || 500
        bgRef.setUrl(backgroundChanged ? backgroundChanged : isRandom ? randomBackground() : isRandomNFT ? getRandomNFTURL(cellSettings.cellBackgroundNFTCollection) : isPlain ? `${process.env.REACT_APP_API_URL}/getColor/${cellSettings.cellBackgroundColor || "Black"}` : isCustom ? cellSettings.cellCustomBackground : cellSettings.cellBackground || randomBackground())
        bgRef.remove()
        bgRef.addTo(map)  
      }
    } else {
      if (backgroundChanged) {
        setCellBackground(randomBackground())
      } else {
        if (isPlain) { 
          setCellBackground(cellSettings.cellBackgroundColor)
          if (bgcontainer) bgcontainer.style.backgroundColor = cellSettings.cellBackgroundColor 
        } else if (bgcontainer) bgcontainer.style.backgroundColor = '#000'  
        if (cellSettings) {
          if (isRandom) {
            setCellBackground(randomBackground())
          } else if (isPlain) { 
            setCellBackground(cellSettings.cellBackgroundColor)
          } else if (isRandomNFT) { 
            setCellBackground(getRandomNFTURL(cellSettings.cellBackgroundNFTCollection).replace("{y}{x}{z}", generateId()))
          } else if (cellSettings.cellBackground) {
            setCellBackground(cellSettings.cellBackground)
          }
        }   
      } 
    }
    return () => {

    }
    
  },[map, cellSettings, customBg, selectedCell, backgroundChanged, isPlain, isRandomNFT, isRandom, isCustom, isTiles])

  useEffect(() => {
    if (map) {
      if (cellSettings && selectedCell && cellSettings.cellMinZoom && cellSettings.cellMaxZoom) {
        map.setMinZoom(cellSettings.cellMinZoom+2)
        map.setMaxZoom(cellSettings.cellMaxZoom+2)
      } else {
        map.setMinZoom(3)
        map.setMaxZoom(18)
      }  
    }
    return () => {
      if (map) {
        map.setMinZoom(3)
        map.setMaxZoom(18)  
      }
    }
  },[map, cellSettings, selectedCell])

  const len = checkedContent.length

  const QuestAnswer = () => {
    return  <Tooltip title="Advance to the next level">
              <Fab color="primary" size="small" className={classes.bottomRight + " " + classes.questFab} onClick={solveQuest} style={{zIndex: 1000}}>
                <RightArrowIcon />
              </Fab>              
            </Tooltip>
  }

  const solveQuest = () => {
    let answer = window.prompt("What is the answer?")
    if (!answer) return;
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("solveQuest", "at not present")
        return null
      }      
      let lvl = EVENTCELLS[userAccount.questLevel]
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + result.at},
      }
      fetch(process.env.REACT_APP_API_URL + "/quest/" + lvl + "/" + answer, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          if (result && !result.error) {
            if (result.result === "solved!") {
              if (userAccount.questLevel === 25) {                
                snackbarShowMessage("CORRECT! YOU WON! 🏆")
              } else {
                snackbarShowMessage("CORRECT! You solved level " + userAccount.questLevel + " 🎉. Redirecting to the next one.");
                let nxtlvl = EVENTCELLS[userAccount.questLevel + 1]
                setTimeout(() => {window.location.href="/?cell=" + nxtlvl}, 3000)
              }
            } else {
              if (result.result === "wrong") {
                snackbarShowMessage("WRONG ANSWER","error")
              }
            }
            return
          } else {
            console.log("error", result.error)
          }
          if (process.env.REACT_APP_DEBUG) console.log("error", result && result.error)
        },
        (error) => {
            console.log("error", error)
        }
      ); 
      return 
    })
  }

  const itemList = React.memo(({ data, index, style }) => {
    if (!data) return null
    const c = data[index]
    if (!c) return null
    let url = c.url;
    if (url) url = url.replace('http://','').replace('https://','')
    if (url) url = url.split('/').pop().split('?')[0]
    

    return (
        <div style={{...style, borderTop: "1px solid rgba(0,0,0,0.2)", display: "flex", alignItems: "center"}}>
          <ListItem style={{listStyleType:"none"}}>
            <Tooltip title="Where am i?">
              <ListItemIcon 
                onClick={() => highlightContent(c.id, c.bounds, "on")}
                onMouseLeave={() => highlightContent(c.id, c.bounds, "off")}
                onContextMenu={(e) => handleDrawerContextMenu(c.id, c.locked, c.visible, e)}
                style={{minWidth: "35px"}}
              >
                <Badge badgeContent={<VisibilityOffIcon fontSize="inherit" color="primary" />} overlap="circle" invisible={(c.visible === undefined ? true : c.visible) || !editMode} style={{backgroundColor: "transparent !important"}} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                  <Badge badgeContent={<LockIcon fontSize="inherit" color="secondary" />} overlap="circle" invisible={!c.locked || !editMode} style={{backgroundColor: "transparent !important"}} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                    {(c.type === "video") ? <VideoIcon htmlColor="#333" /> : (c.type === "image") ? <ImageIcon htmlColor="#333" /> : <TextIcon htmlColor="#333" />}
                  </Badge>                              
                </Badge>
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={smartTrim(c.name || url || stripHtml(c.divHtml),editMode ? 20 : 25) || <i>~ NO TEXT / HTML ~</i>}
              //secondary={(!!c.name) ? Utils.smartTrim(url || Utils.stripHtml(c.divHtml),editMode ? 20 : 25) : undefined}
              title={c.url || stripHtml(c.divHtml)}
              onClick={() => handleDrawerClick(c.id)}
              onContextMenu={(e) => handleDrawerContextMenu(c.id, c.locked, c.visible, e)}
              primaryTypographyProps={{style:{fontWeight: "bold", fontSize: "0.8rem"}}}
              //secondaryTypographyProps={{style:{fontSize: "0.8rem"}}}
              style={{minWidth: "170px"}}
            />
            {editMode && selectedCell && selectedCell.lockedStatus !== "locked" && (
              <ListItemSecondaryAction>
                <Checkbox edge="end" aria-label="check" onClick={() => handleDrawerCheckbox(c.id)} checked={checkedContent.indexOf(c.id) !== -1} style={{color: "white"}}/>
              </ListItemSecondaryAction>                          
            )}            
          </ListItem>
        </div>
    )
  }, areEqual)

  const itemKey = useCallback((index, data) => {
    const item = data[index]
    return item.id
  },[])

  const contentList = useMemo(() => (
    <AutoSizer>
      {({ height, width }) => (
      <FixedSizeList 
        style={{backgroundColor: "#111", cursor: "pointer"}}
        height={height}
        width={width}
        itemCount={sortedList.length}
        itemData={sortedList}
        itemSize={45}
        itemKey={itemKey}
        overscanCount={10}
      >
        {itemList}
      </FixedSizeList>)}
    </AutoSizer>
  ),[sortedList, itemList, itemKey])
  

  useEffect(() => {
    if (!state.content || state.content.length === 0) return
    let sl = sortByKey([...state.content], sortStatus.field, sortStatus.direction)
    setSortedList(!editMode ? sl.filter((c) => c && c.visible !== false) : sl)
    return () => {}
  },[sortStatus, editMode, state.content])

  // useEffect(() => {
  //   setTimeout(() => setGameOpen(true),3000)
    
  // },[])

  const contextProps = { 
    cells: {cells, dispatchCells}, 
    state: {state, dispatch},
    editMode: {editMode, setEditMode},
    muteAll: {muteAll, setMuteAll},
    dragging: {dragging, setDragging},
    cellSettings: {cellSettings, setCellSettings},
    editCellSettings: {editCellSettings, setEditCellSettings},
    highlighted: {highlighted, setHighlighted},
    drawerOpen: {drawerOpen, setDrawerOpen},
    contextMenuOpen: {contextMenuOpen, setContextMenuOpen},
    currentContent: {currentContent, setCurrentContent},
    currentStats: {currentStats, setCurrentStats},
    currentChain: {currentChain, setCurrentChain},
    selectedContent: {selectedContent, setSelectedContent},
    backgroundChanged: {backgroundChanged, setBackgroundChanged},
    celdaReward: {celdaReward, setCeldaReward},
    celdaAmounts: {celdaAmounts, setCeldaAmounts},
    cellReroll: {cellReroll, setCellReroll},
    cellEvolve: {cellEvolve, setCellEvolve},
    newUser: {newUser, setNewUser},
    userAccount: {userAccount, setUserAccount},
    upload: {upload, setUpload},
    uploadedFile: {uploadedFile, setUploadedFile},
    browseFiles: {browseFiles, setBrowseFiles},
    provider: {provider, setProvider}, 
    mapStyle: mapStyle,
    userAddress: [userAddress, setUserAddress],
    connected: [connected, setConnected],
    loading: {loading, setLoading},
    loadingProgress: {loadingProgress, setLoadingProgress},
    filters: {filters, setFilters},
    joinedEvent: {joinedEvent, setJoinedEvent}, // EVENT
    accessToken: [accessToken, setAccessToken],
    snackbarShowMessage: snackbarShowMessage,
    editContent: editContent,
    selectedCell: selectedCell,
    contentChanged: contentChanged,
    handleLoggedIn: handleLoggedIn,
    handleLoggedOut: handleLoggedOut,
    handleSelectedCell: handleSelectedCell,
    handleEditContent: handleEditContent,
    handleAddNft: handleAddNft,
    setContentChanged: setContentChanged,
    refreshToken: refreshToken,
    welcomeOpen: welcomeOpen,
    uploadFileToIPFS: uploadFileToIPFS,
    downloadFile: downloadFile,
    hdr: hdr,
  }
  
  return (
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={contextProps}>
        <Backdrop open={!!loading} className={classes.backdrop}>
          <div className={classes.progress}>
            {!!loadingProgress ?
            <>
              <NewLinearProgress color="primary" variant="determinate" value={loadingProgress}  />
              <div style={{margin: 0, padding:20, backgroundColor: "rgba(0,0,0,0.5)", height: 50, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography variant="h3" align="center" style={{color: "white"}}><b>{loading} {loadingProgress}%</b></Typography>
              </div>
              <NewLinearProgress color="primary" variant="determinate" value={loadingProgress}  />
            </>
            :
            <>
              <NewLinearProgress color="primary" />
              <div style={{margin: 0, padding:20, backgroundColor: "rgba(0,0,0,0.5)", height: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h3" align="center" style={{color: "white"}}><b>{loading}</b></Typography>
              </div>
              <NewLinearProgress color="primary" variant="query" style={{margin: 0, padding:0}}/>
            </>
            } 
          </div>
        </Backdrop>
        { !!selectedCell && !drawerOpen && !isPic &&
          <Header>                   
            <Grow in={!!selectedCell} timeout={500}>
              <Tooltip title={"EXIT PLAYGROUND"} >
                <Fab color="secondary" size="small" className={classes.topRight} onClick={() => window.history.back()}>
                  <CloseIcon />
                </Fab>
              </Tooltip>
            </Grow>                           
          </Header>
        }   
        <div id="map-container">   
          {/* <MapContainer className="map" center={[mapPosLat, mapPosLng]} zoom={mapZoom} maxBounds={maxBounds} maxBoundsViscosity={1.0} minZoom={4} doubleClickZoom={false} zoomSnap={0.5}> */}
            <MapContainer 
              whenCreated={setMap}
              className="map"
              center={[isLat || localStorage.mapPosLat || mapPosLat, isLng || localStorage.mapPosLng || mapPosLng]}
              zoom={isZoom || localStorage.mapZoom || mapZoom}
              boxZoom={false}
              doubleClickZoom={false}
              markerZoomAnimation={false}
              //zoomAnimationThreshold={1}
              maxBounds={maxBounds}
              maxBoundsViscosity={.5}
              minZoom={3}
              maxZoom={18}
              zoomsliderControl={true}
              zoomControl={false}
            >              
                <LayerControl position="bottomleft" filters={filters} setFilters={setFilters} showBorders={showBorders} setShowBorders={setShowBorders} userAccount={userAccount} currentStats={currentStats}>            
                  { 
                    tileLayers.map((tile) => (
                      <GroupedLayer key={tile.name} name={tile.name} checked={(!!selectedCell) ? false : (mapStyle.current) ? mapStyle.current.toLowerCase() === tile.name.toLowerCase() : isMap ? isMap.toLowerCase() === tile.name.toLowerCase() : tile.checked} group="MAP STYLES">
                        <TileLayer 
                          attribution={tile.attribution === "Mapbox" ? '© <a href="https://apps.mapbox.com/feedback/" target="_blank">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>' : tile.attribution}
                          url={tile.url}
                          zoomOffset={tile.attribution !== "Mapbox" ? 0 : -1}
                          tileSize={tile.attribution !== "Mapbox" ? 256 : 512}
                          opacity={(!!selectedCell) ? 0 : 1}
                          name={tile.name}
                          base={1}
                           />
                      </GroupedLayer>  
                    ))             
                  }
                  <Cells mapStyle={mapStyle} editMode={editMode} userAddress={userAddress} showBorders={showBorders} setShowBorders={setShowBorders} origins={origins} joinEvent={joinEvent} />
                </LayerControl>
                <LayersControl position="topright">                 
                  <LayersControl.BaseLayer key="customBg" name="customBg" checked={!!selectedCell && isTiles}>
                    <TileLayer 
                      ref={customBg}
                      url={(!!cellSettings) ? ((isCustom && cellSettings.cellCustomBackground === cellBackground) ? cellSettings.cellCustomBackground : isRandomNFT ? getRandomNFTURL(cellSettings.cellBackgroundNFTCollection) : (isPlain && cellBackground === cellSettings.cellBackgroundColor) ? `${process.env.REACT_APP_API_URL}/getColor/${cellSettings.cellBackgroundColor || "Black"}` : cellBackground) : cellBackground}
                      tileSize={(!!cellSettings) ? isRandomNFT ? 400 : cellSettings.cellTileSize : 500}
                      opacity={(!selectedCell) ? 0 : isTiles && !isPlain ? 1 : 0} 
                      // keepBuffer={100}
                    />
                  </LayersControl.BaseLayer>    
                </LayersControl>
                {/* {!selectedCell && <CellsLogo sw={[21.16648, -49.87793]} ne={[35.24562, -31.50879]} customClass="sea_logo" />} */}
                {!selectedCell && (
                  <BattlesLogo sw={[18.72950, -49.87793]} ne={[35.24562, -31.50879]} customClass="sea_logo" />
                )}
                {!!selectedCell && !!cellSettings && (!isTiles || isPlain) && <div style={{position: "absolute", backgroundImage: `url(${isRandomNFT ? getRandomNFTURL(cellSettings.cellBackgroundNFTCollection).replace("{y}{x}{z}",generateId()) : cellSettings.cellBackground === "CUSTOM" ? cellSettings.cellCustomBackground : cellBackground})`, backgroundSize: cellSettings.cellBackgroundStyle !== "repeat" ? cellSettings.cellBackgroundStyle  : 'inherit', backgroundRepeat: cellSettings.cellBackgroundStyle !== "repeat" ? "no-repeat" : "repeat", backgroundPosition: "50% 50%", left: 0, top: 0, right: 0, bottom: 0}}></div>}
                { !isPic && selectedCell && !drawerOpen && (!EVENTACTIVE || EVENTCELLS.indexOf(selectedCell.celdaCod) === -1 || !joinedEvent || !userAccount || !(userAccount && EVENTCELLS[userAccount.questLevel] === selectedCell.celdaCod)) && (
                  <Footer>
                    <CellSpeedDial provider={provider} map={map} setCellBackground={setCellBackground} />
                  </Footer> 
                )}
                {
                  selectedCell && EVENTACTIVE && EVENTCELLS.indexOf(selectedCell.celdaCod) !== -1 && joinedEvent && userAccount && EVENTCELLS[userAccount.questLevel] === selectedCell.celdaCod && <QuestAnswer />
                }
            </MapContainer>
            
            { !selectedCell && !isCell && !isPic && !isTokenId && <WelcomeDialog welcomeOpen={welcomeOpen} setWelcomeOpen={setWelcomeOpen} fetchUserData={fetchUserData} currentStats={currentStats} setCurrentStats={setCurrentStats} map={map} /> }
            { !selectedCell && !isCell && !isPic && !isTokenId && <BattlesWelcome battlesWelcome={battlesWelcome} setBattlesWelcome={setBattlesWelcome} /> }
            { upload && !!userAccount && <UploadDialog browseFiles={browseFiles} setBrowseFiles={setBrowseFiles} upload={upload} setUpload={setUpload} /> }
            { browseFiles && !!userAccount && <BrowseDialog browseFiles={browseFiles} setBrowseFiles={setBrowseFiles} /> } 
            { !!editContent && editMode && <CellEditContent editContent={editContent} map={map} />  }
            { addNft && editMode && <CellAddNft addNft={addNft} map={map} /> }
            { !!editCellSettings && <CellSettings setCellBackground={setCellBackground} map={map} />  }
            { !selectedCell && ( <MainMenu currentStats={currentStats} setCurrentStats={setCurrentStats} open={mainMenuOpen} setOpen={setMainMenuOpen} map={map} fetchUserData={fetchUserData} /> )}
            { filters && Object.keys(filters).length !== 0 && <BottomDrawer filters={filters} setFilters={setFilters} cells={cells} map={map} /> }
            { !!celdaReward && <CeldaReward celdaReward={celdaReward} setCeldaReward={setCeldaReward} /> }
            { !!cellReroll && <CellReroll cellReroll={cellReroll} setCellReroll={setCellReroll} map={map} /> }            
            { !!cellEvolve && <CellEvolve cellEvolve={cellEvolve} setCellEvolve={setCellEvolve} map={map} /> }

            { !!selectedCell && (
              <React.Fragment>                         
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={handleDrawerClose}
                  ModalProps={{BackdropProps:{invisible: true}}}
                  PaperProps={{style:{backgroundColor:"#111",minWidth: "270px", overflowX: "hidden", color: "white"}}}
                >
                  {(editMode && selectedCell && selectedCell.lockedStatus !== "locked") && (
                    <div style={{margin: 0, padding: 0, zIndex: 200, borderBottom: "2px solid #333"}}>
                      <Box justifyContent="center" style={{backgroundColor: "#111", display: "flex", flexDirection: "column", alignContent: "center", textAlign: "center", height: "60px", color: "white"}} >  
                        <div style={{backgroundColor: "#111", display: "flex", flexDirection: "row", alignContent: "center", textAlign: "center", justifyContent: "space-around"}}>
                          {/* <Typography variant="subtitle2" style={{textAlign: "center"}}>{len} items selected</Typography>  */}
                          <Tooltip title={"Sort items"}>
                            <IconButton aria-label="sort" onClick={handleSortOpen} style={{marginLeft: "4px"}}>
                                <SortIcon htmlColor="#FFF"/>
                            </IconButton>
                          </Tooltip>                                
                          <Tooltip title={(len > 0) ? `${(checkedStatus.visible) ? "Hide" : "Show"} ${len} item${len > 1 ? "s" : ""}` : ""} >
                            <IconButton aria-label="visible" onClick={() => handleDrawerMultiple("visible")} disabled={len === 0}>
                              {(checkedStatus.visible) ? <VisibilityOffIcon htmlColor="#FFF" /> : <VisibilityIcon htmlColor="#FFF"/> }
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={(len > 0) ? `${(checkedStatus.locked) ? "Unlock" : "Lock"} ${len} item${len > 1 ? "s" : ""}` : ""}>
                            <IconButton aria-label="locked" onClick={() => handleDrawerMultiple("locked")} disabled={len === 0}>
                              {(checkedStatus.locked) ? <UnlockIcon htmlColor="#FFF" /> : <LockIcon htmlColor="#FFF" /> }
                            </IconButton>                                
                          </Tooltip>
                          <Tooltip title={(len > 0) ? `Remove ${len} item${len > 1 ? "s" : ""}` : ""}>
                            <IconButton aria-label="delete" onClick={() => handleDrawerMultiple("delete")} disabled={len === 0} >
                                <DeleteIcon htmlColor="#FFF" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={(len > 1) ? "Uncheck all" : "Check all"}>
                            <Checkbox checked={len > 1} edge="end" style={{marginRight: "6px",color:"#FFF"}} onClick={handleDrawerCheckAll}  />
                          </Tooltip>                              
                        </div>
                      </Box>
                      <Menu
                        id="sort-menu"
                        anchorEl={sortEl}
                        open={Boolean(sortEl)}
                        onClose={handleSortClose}
                        PaperProps={{style:{backgroundColor: "#111", padding: 0, color: "white"}}}
                      >
                        <MenuItem onClick={() => handleSort("name")}>
                          <ListItemIcon style={{minWidth: "35px"}}>
                            {sortStatus.field === "name" ? ((sortStatus.direction === -1) ? <ArrowDownwardIcon fontSize="small" color={"secondary"} /> : <ArrowUpwardIcon fontSize="small" color={"secondary"} />) : <ImportExportIcon fontSize="small" color={"disabled"} />}
                          </ListItemIcon>
                          Name
                        </MenuItem>
                        <MenuItem onClick={() => handleSort("type")}>
                          <ListItemIcon style={{minWidth: "35px"}}>
                          {sortStatus.field === "type" ? ((sortStatus.direction === -1) ? <ArrowDownwardIcon fontSize="small" color={"secondary"} /> : <ArrowUpwardIcon fontSize="small" color={"secondary"} />) : <ImportExportIcon fontSize="small" color={"disabled"} />}
                          </ListItemIcon>
                          Content Type
                        </MenuItem>
                        <MenuItem onClick={() => handleSort("priority")}>
                          <ListItemIcon style={{minWidth: "35px"}}>
                          {sortStatus.field === "priority" ? ((sortStatus.direction === -1) ? <ArrowDownwardIcon fontSize="small" color={"secondary"} /> : <ArrowUpwardIcon fontSize="small" color={"secondary"} />) : <ImportExportIcon fontSize="small" color={"disabled"} />}
                          </ListItemIcon>
                          Priority
                        </MenuItem>
                        <MenuItem onClick={() => handleSort("locked")}>
                          <ListItemIcon style={{minWidth: "35px"}}>
                          {sortStatus.field === "locked" ? ((sortStatus.direction === -1) ? <ArrowDownwardIcon fontSize="small" color={"secondary"} /> : <ArrowUpwardIcon fontSize="small" color={"secondary"} />) : <ImportExportIcon fontSize="small" color={"disabled"} />}
                          </ListItemIcon>
                          Locked
                        </MenuItem>
                        {/* <MenuItem onClick={() => handleSort("visible")}>
                          <ListItemIcon style={{minWidth: "35px"}}>
                          {sortStatus.field === "visible" ? ((sortStatus.direction === 1) ? <ArrowDownwardIcon fontSize="small" color={"secondary"} /> : <ArrowUpwardIcon fontSize="small" color={"secondary"} />) : <ImportExportIcon fontSize="small" color={"disabled"} />}
                          </ListItemIcon>
                          Sort by visibility
                        </MenuItem> */}
                      </Menu> 
                    </div>  
                  )}
                  <div style={{width: "100%", height: "100%"}}>
                    {contentList}
                  </div>
                </Drawer>

                {!!contextMenuOpen.id && (
                  <Menu
                    open={!!contextMenuOpen.id}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={!!contextMenuOpen.top ? { top: contextMenuOpen.top, left: contextMenuOpen.left } : undefined}
                    PaperProps={{style:{backgroundColor: "#111", padding: 0, color: "white"}}}
                  >
                    <MenuItem onClick={() => handleContextLock(contextMenuOpen.id, contextMenuOpen.locked)}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        {contextMenuOpen.locked ? <UnlockIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                      </ListItemIcon>
                      {contextMenuOpen.locked ? "Unlock" : "Lock"}
                    </MenuItem>
                    <MenuItem onClick={() => handleContextEdit(contextMenuOpen.id)}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                    <Tooltip title="You can find hidden items in the item list">
                      <MenuItem onClick={() => handleContextHide(contextMenuOpen.id, contextMenuOpen.visible)}>
                        <ListItemIcon style={{minWidth: "35px"}}>
                          <VisibilityOffIcon fontSize="small" />
                        </ListItemIcon>
                        {contextMenuOpen.visible ? "Hide" : "Show"}
                      </MenuItem>                        
                    </Tooltip>   
                    <MenuItem onClick={() => {copyClipboard(contextMenuOpen.id, snackbarShowMessage, <>Copied <b>{contextMenuOpen.id}</b> to clipboard</>); setContextMenuOpen({})}}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        <FileCopyIcon fontSize="small" />
                      </ListItemIcon>
                      Copy ID
                    </MenuItem>                        
                    <MenuItem onClick={() => handleContextDelete(contextMenuOpen.id)}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Remove
                    </MenuItem>
                    <hr />
                    <MenuItem onClick={() => handleMenu("add")}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        <AddBoxIcon fontSize="small" />
                      </ListItemIcon>
                      Add content
                    </MenuItem>          
                    <MenuItem onClick={() => handleMenu("addnft")}>
                      <ListItemIcon style={{minWidth: "35px"}}>
                        <img src="https://cells.land/img/icons/ethereum.svg" width="19" style={{position: "relative"}} alt="add nft" />
                      </ListItemIcon>
                      Add NFT
                    </MenuItem>                    
                  </Menu>
                )}     
              </React.Fragment>
            )
          }      
          { !isPic && !selectedCell && !mainMenuOpen && !(filters && Object.keys(filters).length !== 0) &&
              <Footer>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center"}} className={classes.bottomRight}>
                  <Tooltip title={<div style={{textAlign: "center", fontSize: "1.2em"}}><div style={{fontSize: "1.2em", marginBottom: 10, color: "#12ccc5", fontWeight: "bolder"}}>NEW GAME</div><span style={{fontSize: "1.2em"}}><div style={{fontSize: "1.2em", marginBottom: 10, fontStyle: "italic", fontWeight: "bolder"}}>Play CELDA NFT Battles!</div><span style={{color: "#FFFFFF80"}}>Use your NFT Portfolio to Battle and earn CELDA!</span></span></div>} placement="left">
                    <img src="https://cells.land/img/logos/battles.png?v2" style={{position: "relative", cursor: "pointer", marginBottom: 10, width: 80, height: 80}} alt="CELDA NFT Battles" onClick={() => window.open("https://nftbattles.xyz","_blank")} className="battlesLogo" />
                  </Tooltip>
                  <Tooltip title={"Open menu"} placement="left">
                      <Fab size="large" className={`${classes.menuButton} animate__animated animate__slideInRight animate__faster`} onClick={() => setMainMenuOpen(true)}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                          {!userAccount ?
                            <CellsLogoGrad />
                            :
                            <Badge 
                              badgeContent={<span>D</span>}
                              color="secondary"
                              invisible={(Date.parse(userAccount && (userAccount.wallet?.lastDailyDate || 0)) + 75600000) > Date.now()}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            >
                              <CellsLogoGrad />
                            </Badge>                            
                          }
                          <span style={{color: "#12ccc5"}}><b>MENU</b></span>
                        </div>
                      </Fab>
                  </Tooltip>
                </div>
              </Footer>
          }
        </div>
        </GlobalContext.Provider>
      </ThemeProvider>
    
  )  
}



export default withSnackbar(Mapa);