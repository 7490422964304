import React, {useState, useContext} from 'react';
import { ethers } from "ethers";
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ButtonBase, CircularProgress, Grow, Avatar } from '@material-ui/core';
import { green, lightBlue } from '@material-ui/core/colors';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {handleLogin, CellsLogoGrad, CellsLogoType} from "./Utils"
import GlobalContext from './Context';
import UserSettings from './UserSettings';
import Stats from './Stats';
import CellsDialog from './CellsDialog'
import MintCells from './MintCells'

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';


//const etherscan_url = process.env.REACT_APP_ETHERSCAN_URL
const isMobile = L.Browser.mobile

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  paper: {
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(44, 44, 44, 1), rgb(11, 11, 11))",
    color: "#fff",
    paddingBottom: 0,
    overflowX: 'hidden',
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: "100%"
  },
  connect: {
    margin: 10,
    color: "white",
    backgroundColor: green['600'],
    '&:hover': {
      backgroundColor: green['900'],
    },
  },
  opensea: {
    margin: 10,
    color: "white",
    backgroundColor: lightBlue['600'],
    '&:hover': {
      backgroundColor: lightBlue['900'],
    },
  },
  optionpaper: {
    minHeight: 150,
    padding: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    background: "radial-gradient(at 50% 100%, rgba(33, 33, 33, 1), rgb(11, 11, 11))",
    color:"white",
    borderRadius: 10,
    transition: "all 300ms ease-in-out",
    '&:hover': {
      boxShadow: "0 0 13px 1px #12ccc5",
      color: "#12ccc5"
    },
  },
  img: {
    margin: 'auto',
    display: 'flex',
    width: 100,
    height: 100,
    maxWidth: '100%',
    maxHeight: '100%',
    fontSize: 72,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#12ccc5"
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }
}));

const WelcomeDialog = (props) => {
  const context = useContext(GlobalContext)
  const { welcomeOpen, setWelcomeOpen, fetchUserData, currentStats, setCurrentStats, map } = props;
  const accessToken = localStorage.getItem("cells:auth")
  const classes = useStyles();
  const [dismiss, setDismiss] = useState(false)
  const {setLoading} = context.loading
  const [connected, setConnected] = context.connected
  const {userAccount, } = context.userAccount
  const {provider, setProvider} = context.provider
  const [userAddress, setUserAddress] = context.userAddress
  const {cells, } = context.cells
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);
  const [cellsDialog, setCellsDialog] = useState()
  const [mint, setMint] = useState()

  let welcome = localStorage.getItem("cells:welcome")
  if (welcome === "1") return null

  const handleClose = (action) => {
    if (dismiss) localStorage.setItem("cells:welcome",1)
    setWelcomeOpen(false);
  };

  const handleDismiss = () => {
    setDismiss(!dismiss)
  }

  const handleClick = async (action) => {
    if ((!provider || !window.ethereum) && (action === "connect" || action === "login")) {
      const wcProvider = new WalletConnectProvider({
        infuraId: process.env.REACT_APP_INFURA_KEY,
      });
      await wcProvider.enable().then(
        (address) => {
          const prov = new ethers.providers.Web3Provider(wcProvider)
          setProvider(prov) 
          const at = localStorage.getItem("cells:auth")
          const data = localStorage.getItem("cells:address")
          if (at && !!data && address[0].toLowerCase() !== data.toLowerCase()) {
            context.handleLoggedOut("You have been logged out because you changed accounts. See you around!", "info")
          } else {
            fetchUserData(address[0])
          }
          setConnected(true)
          setUserAddress(address[0])
          setLoading()
          return
        },
        (error) => {
          console.error(error)
          context.snackbarShowMessage("MetaMask or WalletConnect compatible wallet not detected.", "error");
          setLoading()
          return;  
      })
    }

    if (action === "connect") {
      if (!connected && window.ethereum) {
        try {
          // if (!loading) setLoading("CONNECTING...")
          // else return
          window.ethereum.request({ method: 'eth_requestAccounts' })
          .catch((err) => {
            if (err.code === 4001) {
              context.snackbarShowMessage("Please connect MetaMask.", "error");
            } else {
              console.error(err);
            }
            setLoading()
          })
        } catch (error) {
          console.error(error);
          setLoading()
        }
        return null
      }
    } 

    if (action === "login") {        
      if (connected && !accessToken && userAddress) { 
        handleLogin(context).then((result) => {
          setLoading()
          if (process.env.REACT_APP_DEBUG) console.log("login result", result)
        }).catch(err => {
          setLoading()
          if (process.env.REACT_APP_DEBUG) console.log("login error", err)
        })
        return null
      }
    }    
    if (action === "profile") {
      setSettingsOpen(true)
      setWelcomeOpen(false)
    }
    if (action === "stats") {
      if (!!currentStats) {
        setStatsOpen(true)
        setWelcomeOpen(false)  
      }
    }    
    if (action === "viewcells") {
      if (cells && cells.data && userAccount) {
        let c = cells.data.filter(c => !!userAccount.cells.find(x => x.celdaCod === c.celdaCod))
        setCellsDialog({title: "YOUR CELLS", cells: c})
        setWelcomeOpen(false)
      }
    }
  };

  // const goToCell = (cellData) => {
  //   //setwelcomeOpen(undefined)
  //   map.flyToBounds([cellData.v1,cellData.v2,cellData.v3,cellData.v4],{padding: [100, 100]})
  // }

  
  // const Cell = (props) => {
  //   const cellData = props.data
  //   const classes = useStyles();
  //   return (
  //     <Grid item>
  //       <Paper elevation={3} className={classes.cellpaper} style={{backgroundImage: `url(https://cells.land/img/cell/${cellData.celdaCod}.jpg)`}} onClick={() => goToCell(cellData)}>
  //         <div className={classes.backdrop}></div>
  //         <Typography variant="caption" align="center" style={{position: "absolute", right: 5, top: 5}}>
  //             #{cellData.tokenId}
  //         </Typography>            
  //         <Grid container direction="column" alignItems="center" justifyContent="space-between" style={{flexGrow: 1}} >
  //           <Grid item>
  //             <Typography variant="h4" align="center" style={{color: "#12ccc5"}}>
  //               <b>{cellData.celdaCod}</b>
  //             </Typography>
  //             <Typography variant="caption" align="center" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
  //               <LocationIcon style={{paddingRight: 3, paddingBottom: 2, fontSize: "1em"}}/>{cellData.center[0].toFixed(4)}, {cellData.center[1].toFixed(4)} <SquareFootIcon style={{paddingLeft: 6, paddingRight: 0, fontSize: "1.2em"}}/> {cellData.size_area.toFixed(0)}km²
  //             </Typography>                
  //           </Grid>
  //           <Grid container item direction="row" alignItems="center" justifyContent="center" >
  //             {cellData.country.map((c, idx) => {
  //               return (
  //                 <Grid item key={cellData.celdaCod + "-" + c} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
  //                     <img alt={c} src={`https://cells.land/img/flags-iso/shiny/24/${cellData.countryCode[idx]}.png`} width={24} height={24} style={{paddingRight: 5}}/>
  //                     <span>{c}</span>
  //                 </Grid>
  //               )
  //             })}
  //           </Grid>
  //         </Grid>
  //       </Paper>
  //     </Grid>
  //   )
  // }

  let w = isMobile ? 100 : 200;
  let h = w
  return (
    <div>
      <Dialog
        open={!!welcomeOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, height: "100%"}}}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        TransitionComponent={Grow}  
        fullScreen
      >
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{maxWidth: 1280}}>
                <Grid item style={{display: "flex", justifyContent:"center", alignItems: "center", flexDirection: "column", marginTop: 40}}>
                  <Grid item container justifyContent="center" alignItems="center" spacing={5}>
                    <Grid item>
                      <CellsLogoGrad fontSize="large" style={{fontSize: isMobile ? "5em" : "11em"}} />
                    </Grid>
                    <Grid item>
                      <CellsLogoType fontSize="large" style={{fontSize: isMobile ? "5em" : "11em"}} /> 
                    </Grid>
                  </Grid>               
                {/*   <Paper elevation={5} style={{margin: 10, padding: 20, backgroundColor: "rgba(0,0,0,0.5)", borderRadius: 10, color: "#12ccc5"}} >
                    <Typography variant={isMobile ? "subtitle2" : "subtitle1"} align="center">
                      <b>Cells</b> is a collaborative art project where you can customize and interact with your NFTs using our map. Each Cell is a NFT with different attributes from rarity to location, making them perfect for trading and collecting. Owning a Cell grants you access to it's Playground, where imagination is the limit!
                    </Typography>                  
                  </Paper> */}
                  {/* <Utils.CellsLogo style={{fontSize:"60px"}} className="rotating rotatingcolor"/> */}
                </Grid>
                {/* <Grid item>
                  <Typography variant="subtitle1" align="center">
                    We've assigned you a random username: <b>{userAccount.username}</b>.<br />You can change it in your profile ↘
                  </Typography>
                </Grid>                 */}
              </Grid>
              <Grid item container direction="column" spacing={5} justifyContent="center" alignItems="center">
                <Grid item container justifyContent="center" alignItems="center" direction="column">
                  {!connected && (
                  <>
                    <Typography variant={isMobile ? "subtitle2" : "subtitle1"} align="center" gutterBottom>
                      If you own Cells, connect your wallet and sign a message to login and start customizing them.
                    </Typography>
                    <Typography variant={isMobile ? "subtitle2" : "subtitle1"} align="center" gutterBottom>
                      You'll need MetaMask or a WalletConnect compatible wallet.
                    </Typography>
                    <br/>
                    <Grid item container alignItems="center" justifyContent="center" spacing={10}>
                      <Grid item>
                        <img src="https://cells.land/img/MetaMask_Fox.svg" alt="Metamask logo" height="70" style={{cursor: "pointer"}} onClick={() => window.open(process.env.REACT_APP_OPENSEA_FACTORY_URL, "_blank")}/>
                      </Grid>
                      <Grid item>
                        <img src="https://cells.land/img/walletconnect-logo.svg" alt="WalletConnect logo" height="50" style={{cursor: "pointer"}} onClick={() => window.open(process.env.REACT_APP_OPENSEA_FACTORY_URL, "_blank")}/>
                      </Grid>
                    </Grid>   
                    <Button variant="contained" size="large" className={classes.connect} startIcon={<AccountBalanceWalletIcon />} onClick={() => handleClick("connect")}><b>CONNECT WALLET</b></Button>
                  </>
                  )
                  }
                  {connected && !accessToken &&
                  <>
                    <Typography variant="h5" align="center" gutterBottom>
                      If you own Cells, connect your wallet and sign a message to login and start customizing them.
                    </Typography>
                    <Typography variant="h5" align="center" gutterBottom>
                      You'll need MetaMask or a WalletConnect compatible wallet.
                    </Typography>
                    <br/>
                    <Grid item container alignItems="center" justifyContent="center" spacing={10}>
                      <Grid item>
                        <img src="https://cells.land/img/MetaMask_Fox.svg" alt="Metamask logo" height="70" style={{cursor: "pointer"}} onClick={() => window.open("https://metamask.io", "_blank")}/>
                      </Grid>
                      <Grid item>
                        <img src="https://cells.land/img/walletconnect-logo.svg" alt="WalletConnect logo" height="50" style={{cursor: "pointer"}} onClick={() => window.open("https://registry.walletconnect.org/wallets", "_blank")}/>
                      </Grid>
                    </Grid>   
                    <Button variant="contained" size="large" className={classes.connect} startIcon={<VpnKeyIcon />} onClick={() => handleClick("login")}><b>SIGN A MESSAGE TO LOGIN</b></Button>
                    </>
                  }
                  {connected && !!accessToken && userAccount && userAccount.tokenBalance === 0 &&
                  <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant={isMobile ? "h6" : "h4"} align="center">
                      <span style={{color: "#12ccc5"}}>You don't have any Cells yet 🙁</span><br/><br/>
                      <Button onClick={() => setMint(true)} color="primary" variant="contained" target="_blank" rel="noopener noreferrer">Mint Cells</Button><br/><br/>
                      Or grab your favorite places at <a href="https://opensea.io/collection/cells-nft" style={{color: "#12ccc5"}} target="_blank" rel="noopener noreferrer">OpenSea</a>
                    </Typography>
                  </div>
                  }
                  {connected && !!accessToken && userAccount && userAccount.tokenBalance > 0 &&
                    <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', flexDirection: "column", justifyContent: 'space-around', width: "100%"}}>
                      <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', flexDirection: "column", justifyContent: 'center', marginTop: 10}}>
                        <Avatar className={classes.img} alt="" src={userAccount.avatarUrl} style={{fontFamily: "Roboto", backgroundColor: userAccount.avatarUrl ? "transparent" : "#12ccc5"}}>
                          {userAccount.username && userAccount.username.slice(0,1).toUpperCase()}
                        </Avatar>
                        <Typography variant="h4" align="center" style={{marginTop: 10}}>
                          <b style={{color: "#12ccc5"}}>{userAccount.username}</b>
                        </Typography>
                      </div>                        
                      <Typography variant="h6" align="center" style={{marginTop: 10}}>
                        Welcome! You own <b style={{color: "#12ccc5"}}>{userAccount.cells.length}</b> Cells! <span className="emoji" style={{fontSize: "inherit"}}>🎉</span><br />
                      </Typography>

                      <br/>
                      <Grid container spacing={5} justifyContent="center" alignItems="center" style={{maxWidth:1024}}>
                        <Grid item xs={12} md={4}>
                          <ButtonBase style={{width: "100%", borderRadius: 10}} onClick={() => handleClick("viewcells")} className="animate__animated animate__zoomIn animate__faster">
                            <Paper elevation={4} className={classes.optionpaper}>
                              <CellsLogoGrad fontSize="large" style={{fontSize: "5em"}} />
                              <Typography variant="h5">
                                <b>VIEW YOUR CELLS</b>
                              </Typography>
                            </Paper>
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ButtonBase style={{width: "100%", borderRadius: 10}} onClick={() => handleClick("profile")} className="animate__animated animate__zoomIn animate__faster">
                            <Paper elevation={4} className={classes.optionpaper}>
                              <CellsLogoGrad fontSize="large" style={{fontSize: "5em"}} />
                              <Typography variant="h5">
                                <b>CITIZEN PROFILE</b>
                              </Typography>
                            </Paper>
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ButtonBase style={{width: "100%", borderRadius: 10}} onClick={() => handleClick("stats")} className="animate__animated animate__zoomIn animate__faster">
                            <Paper elevation={4} className={classes.optionpaper}>
                              <CellsLogoGrad fontSize="large" style={{fontSize: "5em"}} />
                              <Typography variant="h5">
                                <b>CELL STATS</b>
                              </Typography>
                            </Paper>
                          </ButtonBase>
                        </Grid>                                                
                      </Grid>
                    </div>
                  }
                  {connected && !!accessToken && !userAccount && 
                    <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', flexDirection: "column", justifyContent: 'space-around', width: "100%"}}>
                      <CircularProgress color="secondary" />
                    </div>
                  }
                </Grid>                  
                <Grid item container justifyContent="center" alignItems="center" direction="column">
                  {!accessToken && 
                    <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                      <Typography variant="subtitle1" align="center">
                        Or if you're just visiting
                      </Typography>
                    </div>
                  }
                  <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => handleClose("gotit")}  style={{color: "white"}} size="large">
                      GO TO THE MAP
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
              <Grid item container direction="column" alignItems="center" style={{textAlign: "center", margin: 10}}>
                <FormControl variant="outlined">
                  <FormControlLabel 
                    label={"DON'T SHOW THIS AGAIN"}
                    control={<Checkbox name="dismiss" onChange={handleDismiss} checked={dismiss} />}
                    style={{marginRight: "5px"}}
                  />
                </FormControl>
              </Grid>
              {/* <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item>
                      <ButtonBase className={classes.image} disabled>
                          <Avatar className={classes.img} alt="" src={userAccount.avatarUrl}>
                            {userAccount.username && userAccount.username.slice(0,1).toUpperCase()}
                          </Avatar>
                      </ButtonBase>
                  </Grid>
   
                  <Grid item xs={12} sm container wrap="nowrap" justifyContent="center" spacing={2} direction="column">
                    <Grid item xs container >
                      <Tooltip title="View on etherscan">
                        <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                          <ButtonBase onClick={() => window.open(etherscan_url + userAccount.address, '_blank')} disableRipple>
                            <Typography variant="caption" style={{color:"rgba(255,255,255,0.54)"}}>
                              {userAccount.address}
                            </Typography>
                          </ButtonBase>
                        </div>
                      </Tooltip>
                      <Grid item wrap="nowrap" container>
                        <Grid item xs={12} style={{display: "flex"}}>
                          <Typography variant="h6">
                            <b>{userAccount.username}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                      {userAccount.email && (
                        <Grid item wrap="nowrap" container>
                          <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                              <Typography variant="body2">
                                {userAccount.email}
                              </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {userAccount.desc && (
                        <Grid item wrap="nowrap" container>
                          <Grid item xs={12} style={{display: "flex", alignItems: "center", flexShrink: 1}}>
                              <Typography variant="body2" style={{margin: "6px 0", fontStyle: "italic"}}>
                                {userAccount.desc}
                              </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {userAccount.cells && (
                      <Grid item wrap="nowrap" container style={{position: "absolute", bottom: 8, right: 10}}>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{position: "absolute", bottom: 8, right: 10}}>
                            Owns <b>{userAccount.cells.length}</b> cell{userAccount.cells.length !== 1 ? "s" : ""}
                            </Typography>
                        </Grid>
                      </Grid>
                    )}                        
                  </Grid>
              </Grid> */}
          </Paper>
        </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions> */}
      </Dialog>
      {settingsOpen && <UserSettings open={settingsOpen} setOpen={setSettingsOpen} map={map} />}
      {statsOpen && <Stats open={statsOpen} setOpen={setStatsOpen} stats={currentStats} setStats={setCurrentStats} map={map} />}
      {cellsDialog && cellsDialog.cells && <CellsDialog title={cellsDialog.title} cells={cellsDialog.cells} setCells={setCellsDialog} map={map} />}        
      {mint && <MintCells mint={mint} setMint={setMint} /> }
    </div>
  );
}

export default WelcomeDialog