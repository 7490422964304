import React, {useState, useContext, useEffect} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import EvolveIcon from '@material-ui/icons/OfflineBoltRounded';
import { Button, Grow, CircularProgress, Typography } from '@material-ui/core';
import {ethers} from 'ethers';
import {CellsLogoWhite} from './Utils';
import GlobalContext from "./Context";
import Draggable from 'react-draggable';

const cellsABI = require('./contracts/Cells.json').abi;
const cellsContractAddress = !process.env.REACT_APP_DEBUG ? "0x0f75214ac5af0bb9ed5e3475bc7819a1a3980c3e" : "0xe2D820b014081Fd94A3388AC54D4cf371751d2a6"  // CELLS ETHEREUM

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden',
    textAlign: 'center'
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
	select: {
	  color: "#ccc !important",
	  fontSize: "0.9em",
	  "& label": {
		color: "#ccc !important"
	  },
	  "& .MuiOutlinedInput-notchedOutline": {
		color: "#ccc !important",
		borderColor: "#ccc !important"
	  }
	},
}));




const MintCells = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {mint, setMint} = props;
  const classes = useStyles();
  const [quantity, setQuantity] = useState(3);
  const [minting, setMinting] = useState(false);
  const [minted, setMinted] = useState(false);
  const [tx, setTx] = useState(false);
  const {currentChain} = context.currentChain
  const {provider} = context.provider
  const {snackbarShowMessage, refreshToken} = context
  const {setUserAccount} = context.userAccount
  const {cells, dispatchCells} = context.cells
  const {setFilters} = context.filters
  const cellsContract = new ethers.Contract( cellsContractAddress , cellsABI , provider.getSigner() )
  const isEthereum = currentChain === "0x1" || currentChain === "0x4"

  const MINT_PRICE = 0.01

  const handleClose = () => {
    setMint(false);
  };

  const switchToEthereum = async () => {
    if (!window.ethereum) return
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: process.env.REACT_APP_DEBUG ? "0x4" : "0x1" }], // chainId must be in hexadecimal numbers
      });
    } catch (error) {     
      console.error(error);
    }
  }

  const handleMint = async () => {
    if (quantity < 1 || quantity > 25) {
      alert('Invalid amount. Max is 25.')
      return
    }
    let q = quantity
    if (!isEthereum) {
      switchToEthereum()
    } else {
      setMinting(true)
      let amount = ethers.utils.parseUnits(String(q * MINT_PRICE), "ether")
      let t
      try {
         t = await cellsContract.mintCells(q, {value: String(amount)})
      } catch (ex) {
        console.log(ex)
        setMinting(false)
        alert(ex.data?.message || ex.message)
      }
      if (t) {
        setTx(t.hash)
        await provider.waitForTransaction(t.hash)
        setTx()
        snackbarShowMessage(<><b>Woohoo!! You minted {q} Cells!</b><br />Wait a few seconds while we fetch your new Cells...</>)
        setMinted(true)
        setMinting(false)
        setTimeout(() => getCells(), 5000)
      }
    }
  };

  function getCells() {
    try {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
      }

      let url = process.env.REACT_APP_API_URL + '/getCells'
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (r) => { 
          if (r) {
            if (!r.error) {
              dispatchCells({type: "reset", payload: r})
              fetchUserData()
            }
          }
        },
        (error) => {
          if (process.env.REACT_APP_DEBUG) console.log("error",error.error)
        }
      ); 

    } catch (err) {
      if (process.env.REACT_APP_DEBUG) console.log("error",err)
    }
  }

  
  function fetchUserData() {
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("fetchUserData", "at not present")
        return null
      }
      let at = result.at
      try {
        let requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at},
        }
  
        let url = process.env.REACT_APP_API_URL + '/getUserData'
        // setLoading("FETCHING USER DATA...")
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (r) => { 
            if (r) {
              setUserAccount(r)
              setFilters({onlymycells: true})
              setMint(false)
            }
          },
          (error) => {
            if (process.env.REACT_APP_DEBUG) console.log("no user data")
          }
        ); 
  
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log(err)
      }
    }, (err) => {
      if (process.env.REACT_APP_DEBUG) console.log("error", err)
    })
  }
  return (
    <div>
      <Dialog
        open={mint}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="xs"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root} >
            <Paper elevation={3} className={classes.paper}>
              {!minted ? 
                <>
                  <DialogTitle>HOW MANY <b>GENESIS CELLS</b> DO YOU WANT TO MINT?</DialogTitle>
                  <div style={{textAlign: "center", padding: 20, display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: 10, paddingTop: 0}}>
                    <TextField
                      margin="dense"
                      id="quantity"
                      type="number"
                      variant="outlined"
                      color="primary"
                      value={quantity || 0}
                      onChange={(e) => setQuantity(e.target.value)}
                      inputProps={{style:{color: "#ccc", borderColor: "#ccc"}}}
                      style={{color: "#ccc", borderColor: "#ccc"}}
                      className={classes.select}
                    />             
                    <Button variant="contained" onClick={handleMint} color="primary" style={{marginLeft: 10, top: 2, position: "relative"}} disabled={minting} endIcon={<CellsLogoWhite />} >
                      {minting ? <b>MINTING...</b> : <b>MINT CELLS!</b> }
                    </Button>
                  </div>
                  <hr />
                  <div style={{textAlign: "center", padding: 10}}>Each <b>Genesis Cell</b> comes with <b>1000 $CELDA</b>:<br /><br /><b>500 $CELDA</b> in your Citizen Wallet<br /><br /><b>500 $CELDA</b> delivered to your <b>Ethereum</b> Wallet on mint transaction.</div>
                  <hr />
                  <div style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                    <div style={{textAlign: "center", paddingBottom: 20}}><span style={{fontSize: "0.8em", color: "#ffffff80"}}>PRICE</span><br /><b>{MINT_PRICE} ETH</b></div>
                    <div style={{textAlign: "center", paddingBottom: 20}}><span style={{fontSize: "0.8em", color: "#ffffff80"}}>MINTED</span><br /><b>{cells.data.length} / 3333</b></div>
                  </div>
                </>
                :
                <>
                  <CircularProgress size={100} style={{color: "#12ccc5", margin: 20}} />        
                  <Typography variant="h6" style={{textAlign: "center", padding: 20}}>Fetching your new Cells...</Typography>
                </>
              }

          </Paper>
        </div>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default MintCells