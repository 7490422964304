import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LocationIcon from '@material-ui/icons/LocationOn';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MapIcon from '@material-ui/icons/Map';
import RerollIcon from '@material-ui/icons/SwapHorizontalCircle';
import EvolveIcon from '@material-ui/icons/OfflineBoltRounded';
import { OpenSeaIconWhite } from './Utils';
import { IconButton, SvgIcon } from '@material-ui/core';
import CellEditMetadata from "./CellEditMetadata";
import CellInfo from './CellInfo'
import CellReroll from './CellReroll'
import CellEvolve from './CellEvolve'
import GlobalContext from './Context'
import { ReactComponent as CeldaIcon } from "./img/CELDA_c.svg";

const useStyles = makeStyles((theme) => ({
  cellpaper: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    minHeight: 110,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    flexGrow: 1,
    color: "white",
    position: "relative",
    border: "1px solid #12ccc5",
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    transition: "all 300ms ease-in-out",
    backgroundColor: "#333",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    zIndex: 0,
    '& > *': {
      transition: "all 300ms ease-in-out",
    },
    '& > * > .buttons': {
      opacity: 0,
      position: "absolute",
      bottom: 0
    },
    '&:hover': {
      boxShadow: "0 0 6px #12ccc5",
      animation: "slidevertical 20s infinite",
      '& > * > .buttons': {
        background: "linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)",
        opacity: 1,
        animation: "fadeIn",
        animationDuration: ".5s"
      }
    }
  },
  backdrop: {
    position:"absolute",
    left:0,
    right:0,
    top:0,
    bottom:0,
    minHeight: 110,
    zIndex: -1,
    borderRadius: 0,
    background: "linear-gradient(70deg,rgba(0,0,0,0) 33%,rgba(255,255,255,0.2) 50%,rgba(0,0,0,0) 66%) rgba(0,0,0,0.5)",
  },
  card: {
    margin: 5,
    background: "#00000070",
    color: "#fff",
    overflow: 'hidden',
    height: 30,
    width: 30,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid transparent",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundSize: "cover !important",
    backgroundPosition: "50% 50%",
    fontWeight: "bold",
    userSelect: "none"
  },
  Fire: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/fire.gif)",
    border: "2px solid orange"
  },
  Water: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/water.gif)",
    border: "2px solid cyan"
  },
  Air: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/air.gif)",
    border: "2px solid white"
  },
  Earth: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/earth.gif)",
    border: "2px solid brown"
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 5,
    fontSize: "0.9em",
    zIndex: 30
  }
}));
  
const CellRow = React.memo((props) => {
  const cellData = props.data
  const classes = useStyles();
  const map = props.map
  const [cellInfo, setCellInfo] = useState()
  const [cellEvolve, setCellEvolve] = useState()
  const [cellReroll, setCellReroll] = useState()
  const context = useContext(GlobalContext)
  const {cells, dispatchCells} = context && context.cells ? context.cells : props
  const {userAccount, setUserAccount} = context.userAccount
  const { setCells, setUserSettings } = props
  const {celdaAmounts} = context.celdaAmounts
  
  const CPD = celdaAmounts?.find(a => a.key === "PASSIVE_AMOUNT")?.value || 2
  const ELEMENTAL_BOOST = celdaAmounts?.find(a => a.key === "ELEMENTAL_BOOST")?.value || 1.5
  const GLOBAL_BOOST = celdaAmounts?.find(a => a.key === "GLOBAL_BOOST")?.value || 1
  const ORIGIN_BOOST = celdaAmounts?.find(a => a.key === "ORIGIN_BOOST")?.value || 2
  const MAX_EVOLUTIONS = cellData.evolutions && cellData.evolutions.count > 11 ? 2 : 1
  const EVOLUTIONS_BOOST = 1 + ((cellData.evolutions && cellData.evolutions.boost) || 0) / 100 * MAX_EVOLUTIONS
  const isElemental = cellData.zone && ["fire","air","earth","water"].includes(cellData.zone.type)
  const isOrigin = !!cellData.traits.origin
  const CELDA_MODIFIER = isElemental ? ELEMENTAL_BOOST : isOrigin ? ORIGIN_BOOST : 1
  const CELDA_PER_DAY = (CPD * CELDA_MODIFIER * GLOBAL_BOOST * EVOLUTIONS_BOOST).toFixed(2)
  
  
  const goToCell = () => {
    const cell = cells && cells.data.find((c) => c.celdaCod === cellData.celdaCod)
    if (cell) {
      map.fitBounds([cell.v1, cell.v2, cell.v3, cell.v4], {padding: [100, 100], animate: false})
    } else {
      getCell()
    }
    setCells([])
    try {
      setUserSettings(false)
    } catch (err) {

    }
    
  }

  const getCell = async () => {
    try {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const url = process.env.REACT_APP_API_URL + '/getByCeldaCod/' + cellData.celdaCod
      await fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          if (result[0]) { 
            dispatchCells({type: "add", payload: [...result] })
            map.fitBounds([result[0].v1, result[0].v2, result[0].v3, result[0].v4], {padding: [100, 100], animate: false})
          }
          if (process.env.REACT_APP_DEBUG) console.log(url + " result", result);
        },
        (error) => {
          console.error(url + " error", error);
        }
      ); 
    } catch (err) {
        console.error(err)
    }
  }

  const openCellInfo = () => {
    setCellInfo(cellData)
  }  

  const evolveCell = () => {
    setCellEvolve(cellData)
  }  

  const rerollCell = () => {
    setCellReroll(cellData)
  }  

  
  const Evos = () => {
    const Fire = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Fire")
    const Water = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Water")
    const Air = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Air")
    const Earth = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Earth")
    return <Grid container direction="column" justifyContent="flex-end">
      <Grid container justifyContent="space-around" alignItems="center">
        <Tooltip title={`${(Earth && Earth.length) || 0} Earth Evolutions`}>
          <Grid item className={`${classes.card} ${classes.Earth}`}>
            <Typography variant="body1">
              {(Earth && Earth.length) || 0}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title={`${(Air && Air.length) || 0} Air Evolutions`}>
          <Grid item className={`${classes.card} ${classes.Air}`}>
            <Typography variant="body1">
              {(Air && Air.length) || 0}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title={`${(Water && Water.length) || 0} Water Evolutions`}>
          <Grid item className={`${classes.card} ${classes.Water}`}>
            <Typography variant="body1">
              {(Water && Water.length) || 0}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title={`${(Fire && Fire.length) || 0} Fire Evolutions`}>
          <Grid item className={`${classes.card} ${classes.Fire}`}>
            <Typography variant="body1">
              {(Fire && Fire.length) || 0}
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
      <Typography variant="body2" style={{display: "flex", justifyContent: "flex-end", marginTop: 5}}>
        <b>{CELDA_PER_DAY}</b> <SvgIcon component={CeldaIcon} viewBox="0 0 720 720" style={{ height: 20, width: 20, color: "#12ccc5" }} />/ DAY
      </Typography>
      <Typography variant="body2" style={{display: "flex", justifyContent: "flex-end", marginTop: 5}}>
        <b>{Number(EVOLUTIONS_BOOST.toFixed(2))}x</b>&nbsp;BOOST
      </Typography>
    </Grid>
  }
  let custom_image = cellData.custom_image
  if (cellData.custom_image_hash) {
    custom_image = 'https://ipfs.thecellszone.com/ipfs/' + cellData.custom_image_hash
    if (!cellData.custom_type || cellData.custom_type === "image") custom_image += '?img-width=400'
  }
  if (cellData.custom_image_metadata) custom_image = cellData.custom_image_metadata.replace("/img/","/images/400/")

  return (
    <Paper elevation={3} className={classes.cellpaper} style={{backgroundImage: `url(${custom_image && cellData.custom_type !== "video" ? custom_image : `https://cells.land/images/400/cell/${cellData.celdaCod}.jpg`})`}}>
      <div className={classes.backdrop}></div>
      <Grid container direction="row" alignItems="flex-start" justifyContent="center" style={{flexGrow: 1}} >
        <Grid item container direction="column" justifyContent="center" alignItems="flex-start">
          <Grid item container direction="row" alignItems="center" justifyContent="flex-start" style={{padding: "0 10px", background: "rgba(0,0,0,.5)", zIndex: 20}}>
            <Typography variant="h6" align="center" style={{color: "#12ccc5", marginRight: 10}}>
              CODE <b>{cellData.celdaCod}</b> TOKEN <b>#{cellData.tokenId}</b>
            </Typography>
            {cellData.custom_name && (
              <Typography variant="h6" align="center" style={{overflow: "hidden", alignItems: "center", justifyContent: "flex-start"}}>
                {cellData.custom_name}
              </Typography>
            )}
          </Grid>
          <Grid item style={{padding: "2px 10px", fontSize: "0.9em", zIndex: 20}}>
            RANK <b>#{cellData.rarityRank}</b> SCORE <b>{cellData.rarity}</b> {cellData.zone && <b className={`rainbowtext zonetext-${cellData.traits.overlay.toLowerCase()}`}> {cellData.traits.overlay.toUpperCase()}</b>}{cellData.traits.origin && <b className={`rainbowtext`}> {cellData.traits.origin.toUpperCase()}</b>}
          </Grid>
          <Grid item style={{padding: "2px 10px", fontSize: "0.9em", zIndex: 20}}>
            OWNER <b>{cellData.owner.username}</b>
          </Grid>
          <Grid container item direction="row" alignItems="center" justifyContent="flex-start" style={{padding: "0px 10px", zIndex: 20}}>
            <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
                <Tooltip title={<b>Visited {cellData.visitCount} time{cellData.visitCount !== 1 ? "s" : ""}</b>} placement="top">
                  <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><VisibilityIcon style={{paddingRight: 5, fontSize: "1em"}} /><span>{cellData.visitCount}</span></div>
                </Tooltip>
            </Grid>
            <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
                <Tooltip title={`Favorited ${cellData.favoriteCount} time${cellData.favoriteCount !== 1 ? "s" : ""}`} placement="top">
                  <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><StarIcon style={{paddingRight: 5, fontSize: "1em"}} /><span>{cellData.favoriteCount}</span></div>
                </Tooltip>
            </Grid>
            {cellData.country.map((c, idx) => {
              return (
                <Grid item key={cellData.celdaCod + "-" + c} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em", zIndex: 20}}>
                    <Tooltip title={c} placement="top">
                      <img alt={c} src={`https://cells.land/img/flags-iso/shiny/24/${cellData.countryCode[idx]}.png`} width={24} height={24}/>
                    </Tooltip>
                </Grid>
              )
            })}

          </Grid>
        </Grid>
        <Grid container item direction="row" alignItems="center" justifyContent="flex-end" style={{position: "absolute", right: 0, top: 0, width: "auto", height: "100%"}}>
          <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em", zIndex: 20}}>
            <Evos />
          </Grid>
        </Grid>        
        <Grid container item direction="row" alignItems="center" justifyContent="center" className="buttons">
          <Grid item className={classes.icons}>
              <Tooltip title={"View on map"} placement="top">
                <IconButton onClick={goToCell} ><MapIcon style={{color: "white",opacity: 0.7}}/></IconButton>
              </Tooltip>
          </Grid>
          <Grid item className={classes.icons}>
              <Tooltip title={"More information"} placement="top">
                <IconButton onClick={openCellInfo}><InfoIcon style={{color: "white", opacity: 0.7}}/></IconButton>
              </Tooltip>
          </Grid>
          {userAccount?.cells.find(c => c.tokenId === cellData.tokenId) &&
            <>
              <Grid item className={classes.icons}>
                <CellEditMetadata data={cellData} type="card" />
              </Grid>
              <Grid item className={classes.icons}>
                <Tooltip title={"Reroll"} placement="top">
                  <IconButton onClick={rerollCell}><RerollIcon style={{color: "white", opacity: 0.7}}/></IconButton>
                </Tooltip>
              </Grid>
              <Grid item className={classes.icons}>
                <Tooltip title={"Evolve"} placement="top">
                  <IconButton onClick={evolveCell}><EvolveIcon style={{color: "white", opacity: 0.7}}/></IconButton>
                </Tooltip>
              </Grid>
            </>
          }                
          <Grid item className={classes.icons}>
              <Tooltip title={"View in OpenSea"} placement="top">
                <IconButton onClick={() => window.open(process.env.REACT_APP_OPENSEA_URL + cellData.tokenId, "_blank")}><OpenSeaIconWhite style={{opacity: 0.7, fontSize: "0.9em"}}/></IconButton>
              </Tooltip>
          </Grid>    
        </Grid>                        
      </Grid>
      <CellInfo cellData={cellInfo} setOpen={setCellInfo} />
      { !!cellReroll && <CellReroll cellReroll={cellReroll} setCellReroll={setCellReroll} map={map} /> }            
      { !!cellEvolve && <CellEvolve cellEvolve={cellEvolve} setCellEvolve={setCellEvolve} map={map} /> }

    </Paper>
  )
})

export default CellRow