import L from 'leaflet';

L.ImageOverlay.Transform = {};

L.ImageOverlay.Transform.Marker = L.CircleMarker.extend({
  options: {
    name: null,
    radius: 4,
    fillColor: '#FF0000',
    color: '#000',
    fillOpacity: 1,
    weight: 1,
    opacity: 1,
    cursor: 'all-scroll',
    className: 'leaflet-image-overlay-transform-marker'
  },

  onAdd: function (map) {
    L.CircleMarker.prototype.onAdd.call(this, map);

    var path = this._path;
    var options = this.options;
    if (path) {
      if (options.cursor) {
        path.style.cursor = options.cursor;
      }
      if (options.name === "bottomLeft" || options.name === "topRight") {
        path.style.cursor = 'nesw-resize';
      }
      if (options.name === "bottomRight" || options.name === "topLeft") {
        path.style.cursor = 'nwse-resize';
      }      
      if (options.name === "top" || options.name === "bottom") {
        path.style.cursor = 'ns-resize';
      }      
      if (options.name === "right" || options.name === "left") {
        path.style.cursor = 'ew-resize';
      }      
      if (options.title) {
        this.bindTooltip(options.title, {direction: "top"}) 
      }      
      path.style.zIndex = 200;
      if (options.name) {
        L.DomUtil.addClass(path, 'leaflet-image-overlay-transform-marker--' + options.name);
      }
    }
    
  },

  setCursor: function (cursor) {
    var path = this._path;
    path.style.cursor = cursor;
  }
});

L.ImageOverlay.Transform = L.ImageOverlay.extend({
  options: {
    rotatable: true,
    scalable: true,
    draggable: true,

    keepRatio: false,
    fit: true,
    type: "image",
    width: 200,
    height: 100,
    videoAutoplay: true,
    videoLoop: true,
    videoMute: true,
    videoControls: false,
    divWidth: 200,
    divHeight: 100,
    divColor: "white",
    divBackgroundColor: "black",
    divHtml: "<b>DEFAULT TEXT</b>",
    id: undefined,
    zIndex: 125,
    locked: false,
    mapImage: false,
    noTransform: false,
    orientation: undefined,
    // Makers
    markerClass: L.ImageOverlay.Transform.Marker,
    // Draw line
    lineGuideOptions: {
      weight: 1,
      opacity: 1,
      dashArray: [3, 3],
      fill: false
    }
  },

  initialize: function (image, latlngs, options) {
    this._polygon = null;
    this._handlers = null;
    this._handlersGroup = null;

    // Parse latLngs
    this._parseLatLngs(latlngs);

    // Assign URL or DOM image
    if (typeof image === 'string') {
      this._url = image;
    } else {
      this._rawImage = image;
    }

    this.setOptions(options);
  },

  onAdd: function (map) {
    var options = this.options;
    var element = this._image;

    // Init element
    if (!element) {
      this._initImage();
      element = this._image;
    }

    // Opacity
    if (options.opacity < 1) {
      this._updateOpacity();
    }

    // Interactive for dragging
    var rawImage = this._rawImage;
    L.DomUtil.addClass(rawImage, 'leaflet-interactive');
    this.addInteractiveTarget(rawImage);
    if (this._type === "div") L.DomUtil.addClass(rawImage, 'divHtml');
    if (this._type === "badge") L.DomUtil.addClass(rawImage, 'cellBadge');

    // Add to pane
    this.getPane().appendChild(element);

    // Reset when needed
    map.on('zoomend resetview', this._reset, this)
      .on('zoomend', this._onMapZoomEnd, this)
      .on('mouseup mouseout', this._endAction, this)

    this.on('mousedown', this._onDragStart, this);
    //this.on('error', (e) => if (process.env.REACT_APP_DEBUG) console.log('error:' + e) , this);

    // Add to property
    this._map = map;

    // Reset element
    this._reset();

    // Reset markers and guideline
    this._resetMarkers();
  },

  onRemove: function (map) {
    // Clear events on map
    map.off('zoomend resetview', this._reset, this)
      .off('zoomend', this._onMapZoomEnd, this)
      .off('mouseup mouseout', this._endAction, this)

    // Clear events on image overlay
    this.off('mousedown', this._onDragStart, this);
    //this.off('error', (e) => if (process.env.REACT_APP_DEBUG) console.log('error:' + e) , this);

    // Remove markers and guideline
    this._removeMarkers();

    // Call parent remove function
    L.ImageOverlay.prototype.onRemove.call(this, map);
  },

  setOptions: function (options) {
    options = L.extend({}, this.options, options);

    // Make sure this will be interactive
    this.options.interactive = true;

    // Set options
    L.setOptions(this, options);

    if (options.draggable) {
      this._draggable = true;
    }
    if (options.scalable) {
      this._scalable = true;
    }
    if (options.rotatable) {
      this._rotatable = true;
    }
    if (options.fit) {
      this._fit = true;
    }
    if (options.minWidth) {
      this._minWidth = options.minWidth;
    }
    if (options.minHeight) {
      this._minHeight = options.minHeight;
    }
    if (options.keepRatio) {
      this._keepRatio = true;
    }
    if (options.lineGuideOptions) {
      this._lineGuideOptions = options.lineGuideOptions;
    }
    if (options.markerClass) {
      this._markerClass = options.markerClass;
    }
    if (options.type === "video") {
      this._video = true;
    }
    if (options.type === "div") {
      this._div = true;
    }
    if (options.type) {
      this._type = options.type;
    }    
    if (options.locked !== undefined) {
      this._locked = options.locked;
    }        
    if (options.id) {
      this._id = options.id;
    }    
    if (options.noTransform) {
      this._noTransform = options.noTransform;
    } 
    if (options.videoAutoplay) {
      this._videoAutoplay = options.videoAutoplay;
    }
    if (options.videoLoop) {
      this._videoLoop = options.videoLoop;
    }
    if (options.videoMute) {
      this._videoMute = options.videoMute;
    }
    if (options.videoControls) {
      this._videoControls = options.videoControls;
    }    
    if (options.divWidth) {
      this._divWidth = options.divWidth;
    }    
    if (options.divHeight) {
      this._divHeight = options.divHeight;
    }    
    if (options.divHtml) {
      if (this._type === "div") { 
        this._divHtml = `<div class="divContainer"><div class="divText" id="divText-${this._id}"><div>${options.divHtml}</div></div></div>`
      } else {
        this._divHtml = options.divHtml
      }
      
    }    
    if (options.divColor) {
      this._divColor = options.divColor;
    }    
    if (options.divBackgroundColor) {
      this._divBackgroundColor = options.divBackgroundColor;
    }    
    if (options.width) {
      this._width = options.width;
    }    
    if (options.height) {
      this._height = options.height;
    }    
    if (options.zIndex && !options.mapImage) {
      this._zIndex = Number(options.zIndex) + 100;
    }   
    if (options.customClass) {
      this._customClass = options.customClass
    }
    if (options.orientation) {
      this._orientation = options.orientation
    }       
    this._reset();
    this._resetMarkers();
  },

  getLatLngs: function () {
    return this._latlngs;
  },

  // getLatLng: function () {
  //   return this._getCenterOf2LatLngs(this._latlngs[0], this._latlngs[2])
  // },  

  setLatLngs: function (latlngs) {
    this._parseLatLngs(latlngs);
    this._reset();
    this._resetMarkers();
  },

  setUrl: function (url) {
    this._url = url;
    if (this._rawImage) {
      this._rawImage.src = url;
    }
    return this;
  },

  setImg: function (img) {
    if (this._rawImage) {
      this._rawImage = img;
      this._parseLatLngs(this._latlngs);
      this._reset();
      this._resetMarkers();
      L.DomUtil.addClass(this._rawImage, 'leaflet-image-layer');
      this._image.innerHTML = ""
      this._image.appendChild(this._rawImage);      
    }
    return this;
  },

  _onMapZoomEnd: function () {
    this._resetMarkers();
  },

  _onContextMenu: function (e) {
    var latlngs = this._latlngs;
    this.fire('contextmenu', {
      ...e,
      latlngs: latlngs,
    });
  },

  _onDragStart: function (e) {
    var draggable = this._draggable;
    
    if (draggable && e.originalEvent.which === 1) {
      var map = this._map;
      var latlngs = this._latlngs;

      this._dragging = true;
      this._startPoint = e.layerPoint;
      this._startLatLngs = latlngs;

      this.bringToFront();

      if (map.dragging.enabled()) {
        this._disabledDrag = true;
        map.dragging.disable();
      }

      map
        .on('mousemove', this._onDrag, this)
        .on('mouseup', this._onDragEnd, this);

      this.fire('dragstart', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onDrag: function (e) {
    if (this._dragging) {
//      var map = this._map;
      var startLatLngs = this._startLatLngs;
      var startPoint = this._startPoint;
      var currentPoint = e.layerPoint;
      var dx = currentPoint.x - startPoint.x;
      var dy = currentPoint.y - startPoint.y;

      if (dx || dy) {
        var latlngs = this._moveLatLngs(dx, dy, startLatLngs);
        this.setLatLngs(latlngs);

        this.fire('drag', {
          ...e,
          latlngs: latlngs,
        });
      }
    }
  },

  _onDragEnd: function (e) {
    var map = this._map;

    if (this._disabledDrag) {
      map.dragging.enable();
      delete this._disabledDrag;
    }
    if (this._dragging) {
      var latlngs = this._latlngs;

      delete this._dragging;
      delete this._startPoint;
      delete this._startLatLngs;

      this.fire('dragend', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onScaleStart: function (e) {
    var scalable = this._scalable;

    if (scalable) {
      var map = this._map;
      var latlngs = this._latlngs;

      this._scaling = true;
      this._startPoint = e.layerPoint;
      this._startLatLngs = latlngs.slice(0);
      this._activeMarkerName = e.target.options.name;
      this._startDiagonal = this._diagonal;

      this.bringToFront();

      if (map.dragging.enabled()) {
        this._disabledDrag = true;
        map.dragging.disable();
      }

      map
        .on('mousemove', this._onScale, this)
        .on('mouseup', this._onScaleEnd, this);

      this.fire('scalestart', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onScale: function (e) {
    if (this._scaling) {
      var startPoint = this._startPoint;
      var currentPoint = e.layerPoint;
      var dx = currentPoint.x - startPoint.x;
      var dy = currentPoint.y - startPoint.y;

      if (dx || dy) {
//        var map = this._map;
        var startDiagonal = this._startDiagonal;
        var startLatLngs = this._startLatLngs;
        var activeMarkerName = this._activeMarkerName;
        var keepRatio = this._keepRatio;

        var latlngs = this._scaleLatLngs(dx, dy, activeMarkerName, startLatLngs, keepRatio, startDiagonal);
        this.setLatLngs(latlngs);

        this.fire('scale', {
          ...e,
          latlngs: latlngs,
        });
      }
    }
  },

  _onScaleEnd: function (e) {
    var map = this._map;

    if (this._disabledDrag) {
      map.dragging.enable();
      delete this._disabledDrag;
    }

    if (this._scaling) {
      var latlngs = this._latlngs;
      delete this._scaling;
      delete this._startPoint;
      delete this._startLatLngs;
      delete this._activeMarker;
      delete this._radian;
      delete this._startWidth;
      delete this._startHeight;

      this.fire('scaleend', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onRotateStart: function (e) {
    var rotatable = this._rotatable;

    if (rotatable) {
      var map = this._map;
      var latlngs = this._latlngs;

      this._rotating = true;
      this._startPoint = e.layerPoint;
      this._startLatLngs = latlngs.slice(0);

      this.bringToFront();

      if (map.dragging.enabled()) {
        this._disabledDrag = true;
        map.dragging.disable();
      }

      map
        .on('mousemove', this._onRotate, this)
        .on('mouseup', this._onRotateEnd, this);

      this.fire('rotatestart', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onRotate: function (e) {
    if (this._rotating) {
      var map = this._map;
      var startPoint = this._startPoint;
      var startLatLngs = this._startLatLngs;
      var currentPoint = e.layerPoint;
      var centerPoint = map.latLngToLayerPoint(this._getCenterOfLatLngs(startLatLngs));

      // move to center and calculate radian
      var radian = Math.atan2(currentPoint.y - centerPoint.y, currentPoint.x - centerPoint.x) - Math.atan2(startPoint.y - centerPoint.y, startPoint.x - centerPoint.x);
      while (radian > 2 * Math.PI) {
        radian /= 2 * Math.PI;
      }
      var latlngs = this._rotateLatLngs(radian, startLatLngs, centerPoint);
      this.setLatLngs(latlngs);

      this.fire('rotate', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _onRotateEnd: function (e) {
    var map = this._map;

    if (this._disabledDrag) {
      map.dragging.enable();
      delete this._disabledDrag;
    }

    if (this._rotating) {
      var latlngs = this._latlngs;
      delete this._rotating;
      delete this._startPoint;
      delete this._startLatLngs;

      this.fire('rotateend', {
        ...e,
        latlngs: latlngs,
      });
    }
  },

  _endAction: function (e) {
    this._onDragEnd(e);
    this._onScaleEnd(e);
    this._onRotateEnd(e);
  },

  _reset: function () {
    var map = this._map;
    if (!map) {
      return;
    }

    var rawImage = this._rawImage;
    var element = this._image;
    var latlngs = this._latlngs;
    var topLeftPoint = map.latLngToLayerPoint(this._topLeft);
    var topRightPoint = map.latLngToLayerPoint(this._topRight);
    var bottomLeftPoint = map.latLngToLayerPoint(this._bottomLeft);
    var bottomRightPoint = map.latLngToLayerPoint(this._bottomRight);

    // if (process.env.REACT_APP_DEBUG) console.log("_topLeft",this._topLeft)
    // if (process.env.REACT_APP_DEBUG) console.log("_topRight",this._topRight)
    // if (process.env.REACT_APP_DEBUG) console.log("_bottomLeft",this._bottomLeft)
    // if (process.env.REACT_APP_DEBUG) console.log("_topLeft",this._topLeft)
    
    var boundsPoint = L.bounds([topLeftPoint, topRightPoint, bottomLeftPoint, bottomRightPoint]);
    //var size = boundsPoint.getSize();
    var topLeftPointInElement = topLeftPoint.subtract(boundsPoint.min);
    var vectorX = topRightPoint.subtract(topLeftPoint);
    var vectorY = bottomLeftPoint.subtract(topLeftPoint);
    var skewX = Math.atan2(vectorX.y, vectorX.x);
    var skewY = Math.atan2(vectorY.x, vectorY.y);

    // Set bounds
    this._bounds = L.latLngBounds(map.layerPointToLatLng(boundsPoint.min), map.layerPointToLatLng(boundsPoint.max));

    // if (process.env.REACT_APP_DEBUG) console.log(rawImage)
    var imageWidth = (!this._video) ? rawImage.width : rawImage.videoWidth;
    var imageHeight = (!this._video) ? rawImage.height : rawImage.videoHeight;
    // Image isn't ready
    if (!imageWidth && !imageHeight) {
      return;
    }

    var fit = this._fit;
    var width = Math.ceil(topLeftPoint.distanceTo(topRightPoint));
    var height = Math.ceil(topLeftPoint.distanceTo(bottomLeftPoint));
    var ratio = width / height;
    var imageRatio = imageWidth / imageHeight;
    if (!fit) {
      // Re-calculate image size
      if (imageRatio > ratio) {
        latlngs = this._scaleLatLngs(0, width / imageRatio - height, 'bottomRight', latlngs.slice(0), false);
        height = Math.ceil(width / imageRatio);
      } else {
        latlngs = this._scaleLatLngs(height * imageRatio - width, 0, 'topRight', latlngs.slice(0), false);
        width = Math.ceil(height * imageRatio);
      }
      this._parseLatLngs(latlngs);
      this._resetMarkers();
      this._fit = true;
    }
    this._width = width;
    this._height = height;
    topLeftPoint = map.latLngToLayerPoint(this._topLeft);
    bottomRightPoint = map.latLngToLayerPoint(this._bottomRight);
    this._diagonal = Math.ceil(topLeftPoint.distanceTo(bottomRightPoint));
    element.style.width = width + 'px';
    element.style.height = height + 'px';
    

    L.DomUtil.setPosition(element, boundsPoint.min);

    var scaleX = width / imageWidth * Math.cos(skewX);
    var scaleY = height / imageHeight * Math.cos(skewY);

    rawImage.style.transformOrigin = '0px 0px 0px';
    if (this._type !== "div") {
      rawImage.style.transform = 'translate(' + topLeftPointInElement.x + 'px, ' + topLeftPointInElement.y + 'px) skew(' + skewY + 'rad, ' + skewX + 'rad) scale(' + scaleX + ', ' + scaleY + ') ';
    } else {
      rawImage.style.width = width + 'px';
      rawImage.style.height = height + 'px';
    }
    
  },

  _resetVideo: function() {
    var rawImage = this._rawImage;
    rawImage.autoplay = this._videoAutoplay
    rawImage.muted = this._videoMute
    rawImage.loop = this._videoLoop
    rawImage.controls = this._videoControls
  },
  _resetDiv: function() {
    var rawImage = this._rawImage;
    rawImage.width = this._divWidth
    rawImage.height = this._divHeight
    rawImage.style.color = this._divColor
    rawImage.style.backgroundColor = this._divBackgroundColor
    rawImage.style.width = this._divWidth;
    rawImage.style.height = this._divHeight;    
  },
  _resetDivContent: function() {
    var rawImage = this._rawImage;
    if (rawImage.innerHTML !== this._divHtml) {
      rawImage.innerHTML = `<div class="divContainer"><div class="divText" id="divText-${this._id}">${this._divHtml}</div></div>`
    }
  },
  _resetZIndex: function() {
    var image = this._image
    image.style.zIndex = this._zIndex;  
  },
  _initImage: async function () {
    var url = this._url;
    var rawImage = this._rawImage;
    var options = this.options;
    var type = this._type;
    var orientation = this._orientation;

    if (type === "image") type = "img"
    if (type === "badge") type = "div"

    // If passing URL, create new image DOM
    if (url || type === "div") {
      rawImage = this._rawImage = L.DomUtil.create(type);
      if (type === "div") {
        rawImage.width = this._divWidth;  
        rawImage.height = this._divHeight; 
        rawImage.innerHTML = this._divHtml;
        rawImage.style.width = this._divWidth;
        rawImage.style.height = this._divHeight;
        rawImage.style.backgroundColor = this._divBackgroundColor;
        rawImage.style.color = this._divColor;
      //  rawImage.style.objectFit = "fill";
        //this._fit = false
      } else {
        // if (type === "img" && orientation && orientation > 0) {
        //   loadImage(
        //     url, // img or canvas element
        //     { orientation: orientation }
        //   ).then(l => {
        //     const limg = l.image
        //     this.setImg(limg);
        //   })
        //   //rawImage = rotatedImg
        // } else {
          rawImage.src = url;
        //}
        rawImage.style.display = 'none';
        // rawImage.width = this._width;  
        // rawImage.height = this._height; 
      }
      if (this._video) {
        rawImage.autoplay = this._videoAutoplay
        rawImage.muted = this._videoMute
        rawImage.loop = this._videoLoop
        rawImage.controls = this._videoControls
      }

     // rawImage.style.zIndex = this._zIndex

      if (options.crossOrigin) {
        rawImage.crossOrigin = '';
      }
    }
    
    // Image alt
    rawImage.alt = options.alt;

    // Add classes
    L.DomUtil.addClass(rawImage, 'leaflet-image-layer');
    if (this._customClass) {
      L.DomUtil.addClass(rawImage, this._customClass);
    }

    // Create new div replace image DOM for position
    var element = this._image = L.DomUtil.create('div', 'leaflet-image-layer leaflet-zoom-animated leaflet-image-transform');
    element.appendChild(rawImage);
    element.onselectstart = L.Util.falseFn;
    element.onmousemove = L.Util.falseFn;
    element.style.zIndex = this._zIndex
    
    rawImage.onload = function () {
      this._reset();
      rawImage.style.display = 'block';
      this.fire('load');
    }.bind(this);

    if (this._video) {
      rawImage.onloadeddata = function () {
        this._reset();
        rawImage.style.display = 'block';
        this.fire('load');
      }.bind(this);    
      rawImage.onerror = function () {
        //this._reset();
        //rawImage.style.display = 'block';
        this.fire('error',"error");
      }.bind(this);            
    }
},

  _parseLatLngs: function (latlngs) {
    this._latlngs = latlngs;
    this._bottomLeft = L.latLng(latlngs[0]);
    this._topLeft = L.latLng(latlngs[1]);
    this._topRight = L.latLng(latlngs[2]);
    this._bottomRight = L.latLng(latlngs[3]);
  },

  _getCenterOfLatLngs: function (latlngs) {
    return this._getCenterOf2LatLngs(latlngs[1], latlngs[3]);
  },

  _getCenterOf2LatLngs: function (latlngA, latlngB) {
    var map = this._map;
    if (map) {
      var pointA = map.latLngToLayerPoint(latlngA);
      var pointB = map.latLngToLayerPoint(latlngB);
      return map.layerPointToLatLng(L.point((pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2));
    }
    return L.latLng((latlngA.lat + latlngB.lat) / 2, (latlngA.lng + latlngB.lng) / 2);
  },

  _getRadianFromLatLngs: function (latlngs) {
    var map = this._map;
    if (!map) {
      return;
    }

    var bottomLeftPoint = map.latLngToLayerPoint(latlngs[0]);
    var topLeftPoint = map.latLngToLayerPoint(latlngs[1]);
    var topRightPoint = map.latLngToLayerPoint(latlngs[2]);
    var bottomRightPoint = map.latLngToLayerPoint(latlngs[3]);
    // top left minus bottom left
    var dx1 = topLeftPoint.x - bottomLeftPoint.x;
    var dy1 = topLeftPoint.y - bottomLeftPoint.y;
    // top right minus bottom right
    var dx2 = topRightPoint.x - bottomRightPoint.x;
    var dy2 = topRightPoint.y - bottomRightPoint.y;
    var radian = 0;
    if (dy1 < 0) {
      // -90 to 90 degree (-PI/2 to PI/2 radian)
      radian = - Math.atan(dx1 / dy1);
    } else {
      // 90 to -90 degree (PI/2 to -PI/2 radian)
      radian = Math.PI - Math.atan(dx2 / dy2);
    }
    return radian;
  },

  _scaleLatLngs: function (dx, dy, pointName, latlngs, keepRatio, diagonal) {
    var map = this._map;
    if (!map) {
      return;
    }

    var centerPoint = map.latLngToLayerPoint(this._getCenterOfLatLngs(latlngs));
    var latlngsClone = latlngs.slice(0);
    var radian = this._getRadianFromLatLngs(latlngsClone);
    var latlngsCloneIndex;

    // var bottomLeftPoint = map.latLngToLayerPoint(latlngs[0]);
    // var topLeftPoint = map.latLngToLayerPoint(latlngs[1]);
    // var topRightPoint = map.latLngToLayerPoint(latlngs[2]);
    // var bottomRightPoint = map.latLngToLayerPoint(latlngs[3]);

    switch (pointName) {
      case 'bottomLeft':
        latlngsCloneIndex = 0;
        break;
      case 'bottom':
        latlngsCloneIndex = 0;
        dx = 0;
        break;
      case 'topLeft':
        latlngsCloneIndex = 1;
        if (keepRatio) {
        }
        break;
      case 'left':
        latlngsCloneIndex = 1;
        dy = 0;
        break;
      case 'topRight':
        latlngsCloneIndex = 2;
        if (keepRatio) {
        }
        break;
      case 'top':
        latlngsCloneIndex = 2;
        dx = 0;
        break;
      case 'bottomRight':
        latlngsCloneIndex = 3;
        if (keepRatio) {
        }
        break;
      case 'right':
        latlngsCloneIndex = 3;
        dy = 0;
        break;
      default:
        
    }

    var startPoint = map.latLngToLayerPoint(latlngsClone[latlngsCloneIndex]);
    var newPoint = L.point(startPoint.x + (dx || 0), startPoint.y + (dy || 0));

    if (keepRatio) {
      var oppositePointIndex = (latlngsCloneIndex + 2) % 4;
      var oppositePoint = map.latLngToLayerPoint(latlngsClone[oppositePointIndex]);
      var currentDiagonal = oppositePoint.distanceTo(newPoint);
      var ratio = currentDiagonal/diagonal;
      newPoint = L.point(
        oppositePoint.x + (startPoint.x - oppositePoint.x) * ratio,
        oppositePoint.y + (startPoint.y - oppositePoint.y) * ratio
      );
    }

    latlngsClone[latlngsCloneIndex] = map.layerPointToLatLng(newPoint);

    if (radian && radian !== 0) {
      latlngsClone = this._rotateLatLngs(- radian, latlngsClone, centerPoint);
    }

    switch (latlngsCloneIndex) {
      case 0:
        latlngsClone[1] = L.latLng(latlngsClone[2].lat, latlngsClone[0].lng);
        latlngsClone[3] = L.latLng(latlngsClone[0].lat, latlngsClone[2].lng);
        break;
      case 1:
        latlngsClone[0] = L.latLng(latlngsClone[3].lat, latlngsClone[1].lng);
        latlngsClone[2] = L.latLng(latlngsClone[1].lat, latlngsClone[3].lng);
        break;
      case 2:
        latlngsClone[1] = L.latLng(latlngsClone[2].lat, latlngsClone[0].lng);
        latlngsClone[3] = L.latLng(latlngsClone[0].lat, latlngsClone[2].lng);
        break;
      case 3:
        latlngsClone[0] = L.latLng(latlngsClone[3].lat, latlngsClone[1].lng);
        latlngsClone[2] = L.latLng(latlngsClone[1].lat, latlngsClone[3].lng);
        break;
      default:
    }
    if (radian && radian !== 0) {
      latlngsClone = this._rotateLatLngs(radian, latlngsClone, centerPoint);
    }
    return latlngsClone;
  },

  _moveLatLngs: function (dx, dy, latlngs) {
    var map = this._map;
    if (!map) {
      return;
    }
    
    var newLatLngs = [];
    for (var point, i = 0; i < 4; i++) {
      point = map.latLngToLayerPoint(latlngs[i]);
      point.x += dx;
      point.y += dy;
      let newLL = map.layerPointToLatLng(point)
      if (newLL.lat > 85 || newLL.lat < -85) return this._draggingLatLngs
      newLatLngs.push(newLL);
    }
    this._draggingLatLngs = newLatLngs
    return newLatLngs;
  },

  _rotateLatLngs: function (radian, latlngs, centerPoint) {
    var map = this._map;
    if (!map) {
      return;
    }

    centerPoint = centerPoint || map.latLngToLayerPoint(this._getCenterOfLatLngs(latlngs));
    var sin = Math.sin(radian) || 0;
    var cos = Math.cos(radian) || 0;
    var newLatLngs = [];
    for (var point, i = 0; i < 4; i++) {
      point = map.latLngToLayerPoint(latlngs[i]);
      point.x -= centerPoint.x;
      point.y -= centerPoint.y;
      newLatLngs.push(map.layerPointToLatLng(L.point([
        centerPoint.x + point.x * cos - point.y * sin,
        centerPoint.y + point.x * sin + point.y * cos
      ])));
    }
    return newLatLngs;
  },

  _createMarkers: function () {
    var map = this._map;
    if (!map) {
      return;
    }
    var markerClass = this._markerClass;
//    var latlngs = this._latlngs;
    var bottomLeft = this._bottomLeft;
    var topLeft = this._topLeft;
    var topRight = this._topRight;
    var bottomRight = this._bottomRight;
    var scalable = this._scalable;
    var rotatable = this._rotatable;
    var draggable = this._draggable;
    var lineGuideOptions = this._lineGuideOptions;
    var keepRatio = this._keepRatio;
    var type = this._type;
    var locked = this._locked;
    var editMode = this._editMode;
    var noTransform = this._noTransform;

    if (noTransform) return;

    var handlers = this._handlers = {};
    var handlersGroup = this._handlersGroup = this._handlersGroup || new L.LayerGroup();

    // Draw line guide
    var polylineLatLngs = [bottomLeft, topLeft, topRight, bottomRight, bottomLeft];
    var guideLine = L.polyline(polylineLatLngs, lineGuideOptions);
    handlers.guideLine = guideLine;
    handlersGroup.addLayer(guideLine);

    if ((draggable || (locked && editMode)) && type === "div") {
      var middleTopLatLng = this._getCenterOf2LatLngs(topLeft, topRight);
      var middleBottomPoint = map.latLngToLayerPoint(this._getCenterOf2LatLngs(bottomLeft, bottomRight));
      var middleTopPoint = map.latLngToLayerPoint(this._getCenterOf2LatLngs(topLeft, topRight));
      var distance = middleBottomPoint.distanceTo(middleTopPoint);
      var ratio;
      if (distance !== 0) {
        ratio = 20 / distance + 1;
      } else {
        ratio = 1;
      }
      var divDragMarkerLatLng = map.layerPointToLatLng(L.point(
        middleBottomPoint.x + (middleTopPoint.x - middleBottomPoint.x) * ratio,
        middleBottomPoint.y + (middleTopPoint.y - middleBottomPoint.y) * ratio
      ));

      // Create marker
      var divDragMarker = new markerClass(divDragMarkerLatLng, {
        name: 'divDrag',
        fillColor: "white",
        title: "Drag or right click me", 
        bubblingMouseEvents: false
      });

      // Add event listeners
      divDragMarker.on('mousedown', this._onDragStart, this);
      divDragMarker.on('contextmenu', this._onContextMenu, this);
      var divDragline = L.polyline([middleTopLatLng, divDragMarkerLatLng], {
        weight: 1,
        opacity: 1,
        dashArray: [2, 2],
        color: "white",
        fill: false
      });

      // Add to handlers
      handlers.divDrag = divDragMarker;
      handlers.divDragLine = divDragline;

      // Add to group
      handlersGroup.addLayer(divDragline);
      handlersGroup.addLayer(divDragMarker);      
    }

    if (rotatable) {
      middleTopLatLng = this._getCenterOf2LatLngs(topLeft, topRight);
      middleBottomPoint = map.latLngToLayerPoint(this._getCenterOf2LatLngs(bottomLeft, bottomRight));
      middleTopPoint = map.latLngToLayerPoint(this._getCenterOf2LatLngs(topLeft, topRight));
      distance = middleBottomPoint.distanceTo(middleTopPoint);

      if (distance !== 0) {
        ratio = 20 / distance + 1;
      } else {
        ratio = 1;
      }
      var rotationMarkerLatLng = map.layerPointToLatLng(L.point(
        middleBottomPoint.x + (middleTopPoint.x - middleBottomPoint.x) * ratio,
        middleBottomPoint.y + (middleTopPoint.y - middleBottomPoint.y) * ratio
      ));

      // Create marker
      var rotationMarker = new markerClass(rotationMarkerLatLng, {
        name: 'rotation',
        bubblingMouseEvents: false
      });

      // Add event listeners
      rotationMarker.on('mousedown', this._onRotateStart, this);

      // Draw line
      var line = L.polyline([middleTopLatLng, rotationMarkerLatLng], lineGuideOptions);

      // Add to handlers
      handlers.rotation = rotationMarker;
      handlers.rotationLine = line;

      // Add to group
      handlersGroup.addLayer(line);
      handlersGroup.addLayer(rotationMarker);
    }

    if (scalable) {
      // Create bottom left marker
      var bottomLeftMarker = new markerClass(bottomLeft, {
        name: 'bottomLeft',
        bubblingMouseEvents: false
      });

      // Create top left marker
      var topLeftMarker = new markerClass(topLeft, {
        name: 'topLeft',
        bubblingMouseEvents: false
      });

      // Create top right marker
      var topRightMarker = new markerClass(topRight, {
        name: 'topRight',
        bubblingMouseEvents: false
      });

      // Create bottom right marker
      var bottomRightMarker = new markerClass(bottomRight, {
        name: 'bottomRight',
        bubblingMouseEvents: false
      });

      // Add event listeners
      bottomLeftMarker.on('mousedown', this._onScaleStart, this);
      topLeftMarker.on('mousedown', this._onScaleStart, this);
      topRightMarker.on('mousedown', this._onScaleStart, this);
      bottomRightMarker.on('mousedown', this._onScaleStart, this);
      bottomLeftMarker.on('contextmenu', this._onContextMenu, this);
      topLeftMarker.on('contextmenu', this._onContextMenu, this);
      topRightMarker.on('contextmenu', this._onContextMenu, this);
      bottomRightMarker.on('contextmenu', this._onContextMenu, this);

      // Add to handlers
      handlers.bottomLeft = bottomLeftMarker;
      handlers.topLeft = topLeftMarker;
      handlers.topRight = topRightMarker;
      handlers.bottomRight = bottomRightMarker;

      // Add to group
      handlersGroup.addLayer(bottomLeftMarker);
      handlersGroup.addLayer(topLeftMarker);
      handlersGroup.addLayer(topRightMarker);
      handlersGroup.addLayer(bottomRightMarker);

      if (!keepRatio) {
        // Create left marker
        var leftMarkerLatLng = this._getCenterOf2LatLngs(bottomLeft, topLeft);
        var leftMarker = new markerClass(leftMarkerLatLng, {
          name: 'left',
          bubblingMouseEvents: false
        });

        // Create top marker
        var topMarkerLatLng = this._getCenterOf2LatLngs(topLeft, topRight);
        var topMarker = new markerClass(topMarkerLatLng, {
          name: 'top',
          bubblingMouseEvents: false
        });

        // Create right marker
        var rightMarkerLatLng = this._getCenterOf2LatLngs(topRight, bottomRight);
        var rightMarker = new markerClass(rightMarkerLatLng, {
          name: 'right',
          bubblingMouseEvents: false
        });

        // Create bottom marker
        var bottomMarkerLatLng = this._getCenterOf2LatLngs(bottomRight, bottomLeft);
        var bottomMarker = new markerClass(bottomMarkerLatLng, {
          name: 'bottom',
          bubblingMouseEvents: false
        });

        // Add event listeners
        leftMarker.on('mousedown', this._onScaleStart, this);
        topMarker.on('mousedown', this._onScaleStart, this);
        rightMarker.on('mousedown', this._onScaleStart, this);
        bottomMarker.on('mousedown', this._onScaleStart, this);
        leftMarker.on('contextmenu', this._onContextMenu, this);
        topMarker.on('contextmenu', this._onContextMenu, this);
        rightMarker.on('contextmenu', this._onContextMenu, this);
        bottomMarker.on('contextmenu', this._onContextMenu, this);        

        // Add to handlers
        handlers.left = leftMarker;
        handlers.top = topMarker;
        handlers.right = rightMarker;
        handlers.bottom = bottomMarker;

        // Add to group
        handlersGroup.addLayer(leftMarker);
        handlersGroup.addLayer(topMarker);
        handlersGroup.addLayer(rightMarker);
        handlersGroup.addLayer(bottomMarker);
      }
    }

    // Add group to map
    handlersGroup.addTo(map);

    // @TODO set cursors for markers
  },

  _removeMarkers: function () {
    var map = this._map;
    if (!map) {
      return;
    }

    var handlersGroup = this._handlersGroup;
    if (handlersGroup) {
      map.removeLayer(handlersGroup);
      handlersGroup = this._handlersGroup = null;
    }
  },

  _resetMarkers: function () {
    this._removeMarkers();
    this._createMarkers();
  },

  _hideMarkers: function () {}
});

/**
 * Factory
 */
L.imageOverlay.transform = function (image, latlngs, options) {
  return new L.ImageOverlay.Transform(image, latlngs, options);
};