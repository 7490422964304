import React from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Grow, SvgIcon } from '@material-ui/core';
import Draggable from 'react-draggable';
import { ReactComponent as CeldaIcon } from "./img/CELDA_c.svg";

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden'
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }  
}));




const CeldaReward = React.memo((props) => {
  const {celdaReward, setCeldaReward} = props;
  const classes = useStyles();

  const handleClose = (action) => {
    setCeldaReward();
  };

  return (
    <div>
      <Dialog
        open={!!celdaReward}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="xs"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0}, className:"animate__animated animate__flipInY"}}
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root} >
            <Paper elevation={3} className={classes.paper}>
              <Grid container direction="column" style={{justifyContent:"space-around", alignItems: "center", flexGrow: 1,padding: 20, position: "relative"}}>
                <Grid item style={{textAlign: "center"}}>
                  <SvgIcon
                    component={CeldaIcon}
                    viewBox="0 0 720 720"
                    style={{ height: "250px", width: "250px", color: "#12ccc5" }}
                  />
                </Grid>
                <Grid item style={{textAlign: "center"}}>
                  <Typography variant={"h1"} align="center" className={"rainbowtext"}>
                    <b>{celdaReward.amount}</b>
                  </Typography>
                  <b style={{color: "#FFFFFF90", fontSize: "2em"}}>$CELDA</b>
                </Grid>
              </Grid>
              <div style={{textAlign: "center", padding: 20}} className="animate__animated animate__fadeIn">
                {celdaReward.desc}
                <br/>
                <b>Keep it up!</b>
              </div>
              <div style={{textAlign: "center", paddingBottom: 20}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                  <b>AWESOME!</b>&nbsp;🎉
                </Button>
              </div>              
          </Paper>
        </div>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default CeldaReward