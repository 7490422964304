import React, { useState, useContext, useRef } from 'react';
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import GlobalContext from "./Context";
import {tileLayers, handleLogin, isImageOrVideo, randomPattern} from "./Utils";
import BrowseDialog from "./BrowseDialog";
import EditIcon from '@material-ui/icons/Edit';
import RerollIcon from '@material-ui/icons/SwapHorizontalCircle';
import EvolveIcon from '@material-ui/icons/OfflineBoltRounded';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BattlesIcon } from "./img/battles.svg";

import { Grow, MenuItem, Typography } from '@material-ui/core';
import isURL from 'validator/lib/isURL';
import Draggable from 'react-draggable';
import { green } from '@material-ui/core/colors';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-metadata" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    backgroundColor: "rgba(255,255,255,0.3)",
    borderRadius: 30
  },
  paper: {
    padding: isMobile ? 3 : theme.spacing(2),
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    borderRadius: 30
  },
  formControl: {
    flexGrow: 1,
    margin: 5,
    padding: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  green: {
    color: "white",
    backgroundColor: green['500'],
    position: "relative",
    top: 2,
    '&:hover': {
      backgroundColor: green['900'],
    },
  },
  imgdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    maxHeight: 400,
    maxWidth: "100%",
    margin: 10,
    position: "relative",
    overflow: "hidden",
    borderRadius: 10
  },
  img: {
    width: "100%",
    borderRadius: 10
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 3,
    right: 0,
    zIndex: 2,
    borderRadius: 10
  },
	input: {
	  color: "white !important",
	  borderColor: "white !important",
	  "& label": {
		color: "#ccc !important"
	  },
	  "& .MuiOutlinedInput-notchedOutline": {
		color: "#ccc !important",
		borderColor: "#ccc !important"
	  }
	},
	select: {
	  color: "#ccc !important",
	  fontSize: "0.9em",
	  "& label": {
		color: "#ccc !important"
	  },
	  "& .MuiOutlinedInput-notchedOutline": {
		color: "#ccc !important",
		borderColor: "#ccc !important"
	  }
	},
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }  
}));

const CellDialog = React.memo((props) => {
  let data = props.data
  let type = props.type
  const context = useContext(GlobalContext)
  const [alreadyLocked, ] = useState(data.lockedStatus === "locked")
  const [open, setOpen] = useState(false);
  const [txtName, setTxtName] = useState(data.custom_name);
  const [txtDesc, setTxtDesc] = useState(data.custom_description);
  const [txtImageUrl, setTxtImageUrl] = useState(data.custom_image);
  const [txtExternalUrl, settxtExternalUrl] = useState(data.custom_external_url);
  const [locked, ] = useState(alreadyLocked);
  const [customMap, setCustomMap] = useState(!!data.custom_map ? data.custom_map : "Satellite");
  const [rotation, setRotation] = useState(!!data.custom_image_rotation ? data.custom_image_rotation : "Normal");
  const [opacity, setOpacity] = useState(data.custom_opacity || 10);
  const [contentType, setContentType] = useState(data.custom_type ? data.custom_type : "image");
  const classes = useStyles();
  const mapStyle = context.mapStyle
  const [userAddress, ] = context.userAddress
  const [connected, ] = context.connected
  const [accessToken, ] = context.accessToken
  const {setLoading} = context.loading
  const [browseFiles, setBrowseFiles] = useState(false)
  const {dispatchCells} = context.cells
  const {setCellReroll} = context.cellReroll
  const {setCellEvolve} = context.cellEvolve
  const {setCeldaReward} = context.celdaReward
  const {userAccount, setUserAccount} = context.userAccount
  const [error, setError] = useState({field: undefined, message: undefined})
  //const lcontext = useLeafletContext()
  const randPattern = useRef(data.traits.overlay === "RANDOM" && randomPattern())
  const { refreshToken, uploadFileToIPFS, snackbarShowMessage, downloadFile } = context

  function updateData(body) {
    refreshToken().then((ok) => {
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
        body: JSON.stringify(body),
      }
      //if (process.env.REACT_APP_DEBUG) console.log(process.env.NODE_ENV);
      let url = process.env.REACT_APP_API_URL + '/updateCell';
  
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          //if (process.env.REACT_APP_DEBUG) console.log(result)
          if (result.ok === 1) {
            setOpen(false);
            dispatchCells({type: "replace", payload: {celdaCod: body.celdaCod, content: body}})
            if (result.reward) {
              setCeldaReward(result.reward)
              let ua = {...userAccount}
              ua.wallet.balance += result.reward.amount
              setUserAccount(ua)
            }
            snackbarShowMessage(<React.Fragment>NFT <b>{body.custom_name}</b> updated!</React.Fragment>);
            mapStyle.current = customMap
          } else {
            snackbarShowMessage("ERROR: " + result.error.message, "error");                
          }
        },
        (error) => {                                
          snackbarShowMessage("ERROR: " + error, "error");
        }
      );       
    },(err) => {if (process.env.REACT_APP_DEBUG) console.log("error",err)})
    
  }

  function updatePicture(body) {
    refreshToken().then((ok) => {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
      }
      //if (process.env.REACT_APP_DEBUG) console.log(process.env.NODE_ENV);
      let url = process.env.REACT_APP_PICAPI_URL + '/updateCellPicture/' + body.celdaCod;
  
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          if (result) {
            if (process.env.REACT_APP_DEBUG) console.log(result)
          } else {
            if (process.env.REACT_APP_DEBUG) console.log("no result")
          }
        },
        (error) => {                                
          snackbarShowMessage("ERROR: " + error, "error");
        }
      );       
    },(err) => {if (process.env.REACT_APP_DEBUG) console.log("error",err)})
    
  }

  
  function updateBattleCell(tokenId) {
    refreshToken().then((ok) => {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
      }
      //if (process.env.REACT_APP_DEBUG) console.log(process.env.NODE_ENV);
      let url = process.env.REACT_APP_API_URL + '/setBattleCell/' + tokenId;
  
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          if (result && !result.error) {
            snackbarShowMessage(<>Cell #{tokenId} selected as Battle Cell!<br />Your wins and losses will be counted on this Cell</>);
            setUserAccount({...userAccount, battleCell: tokenId})
            if (process.env.REACT_APP_DEBUG) console.log(result)
          } else {
            if (result.error) snackbarShowMessage("ERROR: " + result.error, "error");
            if (process.env.REACT_APP_DEBUG) console.log("no result")
          }
        },
        (error) => {                                
          snackbarShowMessage("ERROR: " + error, "error");
        }
      );       
    },(err) => {if (process.env.REACT_APP_DEBUG) console.log("error",err)})
    
  }


  const handleClickOpen = async () => {
    if (!(connected && !!accessToken && !!userAddress)) {
      await handleLogin(context).then((success) => {
        snackbarShowMessage("Connected!");
        setOpen(true)
        setLoading(false)
      }, (err) => {
        snackbarShowMessage("ERROR: " + String(err), "error");
        setOpen(false)
        setLoading(false)
      })
      return
    }
    setOpen(true);
  };

  const handleClose = async (result) => {
    if (result === "ok") {
      // if (locked && data.lockedStatus !== "locked") { 
      //   if (!window.confirm("LAST WARNING! Once you lock your cell, it cannot be unlocked.")) return 
      // }
      if (txtExternalUrl && !isURL(txtExternalUrl)) {
        setError({field: "txtExternalUrl", message: "Invalid External URL."})
        return
      }
      if (txtImageUrl && !isURL(txtImageUrl)) {
        setError({field: "txtImageUrl", message: "Invalid Image or Video URL."})
        return
      }      
      try {
          let body = {celdaCod: data.celdaCod, custom_map: customMap, custom_image_rotation: rotation}
          if (!alreadyLocked) {
            if (txtName && txtName.length > 100) {
              setError({field: "txtName", message: "Names are restricted to 100 characters."})
              return
            }
            if (txtDesc && txtDesc.length > 1000) {
              setError({field: "txtDesc", message: "Description is restricted to 1000 characters."})
              return
            }            
            body.custom_name = txtName
            body.custom_description = txtDesc
            body.custom_image = txtImageUrl
            body.custom_external_url = txtExternalUrl
            body.custom_opacity = opacity
            // if (locked) {
            //   body.lockedStatus = "locked"
            //   body.lockedAddress = userAddress || ""
            // }
          }
          if (body.custom_image && body.custom_image !== data.custom_image) {
            await isImageOrVideo(body.custom_image).then( async iOrV => {
              if (iOrV.type) {
                body.custom_type = iOrV.type
                const gatewayTools = new IPFSGatewayTools();
                const containsCID = gatewayTools.containsCID(body.custom_image)
                if (containsCID.containsCid) {
                  body.custom_image = gatewayTools.convertToDesiredGateway(body.custom_image, "https://ipfs.thecellszone.com")
                  body.custom_image_hash = containsCID.cid
                  updateData(body)                  
                } else {
                  downloadFile(body.custom_image).then(async (res) => {
                    if (res.file) {
                      const fileData = await uploadFileToIPFS(res.file, true)
                      let url = "https://ipfs.thecellszone.com/ipfs/" + fileData.hash
                      body.custom_image = url
                      body.custom_image_hash = fileData.hash  
                      setTxtImageUrl(url)
                      updateData(body)                      
                    } else {
                      if (res.error) setError({field: "txtImageUrl", message: res.error})
                      return
                    }  
                  }, (err) => {
                    if (process.env.REACT_APP_DEBUG) console.log(err)
                    if (err) setError({field: "txtImageUrl", message: err.error.message})
                    return      
                  })
                }
              } else {
                setError({field: "txtImageUrl", message: "Invalid image or video URL."})
                return
              }
            })
          } else { 
            updateData(body)
          }
          if ((body.custom_image !== data.custom_image) || (body.custom_opacity !== data.custom_opacity) || (body.custom_map !== data.custom_map)) {
            if (process.env.REACT_APP_DEBUG) console.log("changed picture", body.custom_image)
            updatePicture(body)
          }
          
         
      } catch (err) {
        snackbarShowMessage(err, "error");
      }


    } else {
      setOpen(false);
      // if (data.lockedStatus !== "locked") setLocked(false);
    }
    if (result === "reroll") {
      setCellReroll(data)
    }
    if (result === "evolve") {
      setCellEvolve(data)
    }    
    //setOpenSnack(true);     
  };

  const handleNameChange = (event) => { 
    setTxtName(event.target.value)
    if (error.field === "txtName") setError({field: undefined, message: undefined})
  }
  const handleDescChange = (event) => {
    setTxtDesc(event.target.value)
    if (error.field === "txtDesc") setError({field: undefined, message: undefined})
  } 
  const handleURLChange = (event) => {
    settxtExternalUrl(event.target.value)
    if (error.field === "txtExternalUrl") setError({field: undefined, message: undefined})
  }
  const handleImageChange = (event) => { 
    setTxtImageUrl(event.target.value)
    if (error.field === "txtImageUrl") setError({field: undefined, message: undefined})
    if (isURL(event.target.value)) {
      isImageOrVideo(event.target.value).then((res) => {
        if (res.type) setContentType(res.type)
      })
      
    }
  }

  const handleMapChange = (event) => setCustomMap(event.target.value)
  const handleRotationChange = (event) => setRotation(event.target.value)
  const handleOpacityChange = (event, newValue) => setOpacity(newValue)

  const handleKeyPress = (e) => {
		if (e && e.charCode === 13) {
        if (!!txtName && !!txtDesc && !!txtImageUrl) {
          handleClose("ok")
        }
		 }  
  }
  // const handleLockedChange = (event) => {
  //   if (alreadyLocked) return;
  //   setLocked(!locked);
  // };

  const selectFile = (url) => {
    if (process.env.REACT_APP_DEBUG) console.log("selectFile",url)
    setTxtImageUrl(url)
    if (error.field === "txtImageUrl") setError({field: undefined, message: undefined})
    if (isURL(url)) {
      isImageOrVideo(url).then((res) => {
        if (res.type) setContentType(res.type)
      })      
    }
  }

  let cellpattern = data.traits.overlay
  if (cellpattern) {
    if (cellpattern === "RANDOM") {
      cellpattern = randPattern.current
    } else if (cellpattern === "CHROMATIC") {
      cellpattern = ""
    } else {
      if (cellpattern.indexOf(" ") === -1) cellpattern =  'Special/' + cellpattern + '.jpg'
      else cellpattern = data.traits.overlay.replace(" ","/") + '.jpg'
      cellpattern = "https://cells.land/img/patterns/" + cellpattern  
    }
  }

  let isBattleCell = userAccount && userAccount.battleCell === data.tokenId

  return (
    <React.Fragment>
      <Tooltip title={type === "card" ? "Edit" : "Edit Cell"} placement="top">
        {type === "card" ? 
          <IconButton onClick={handleClickOpen} ><EditIcon style={{color: "white",opacity: 0.7}}/></IconButton>
        :
          <Fab aria-label="Edit Cell" size="small" color="primary" onClick={handleClickOpen}>
            <EditIcon />
          </Fab>
        }
      </Tooltip>
      <Dialog 
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-metadata"
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "transparent", padding: 0, width: "100%", margin: 0, overflowX: "hidden",borderRadius: 30}}}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-metadata"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}} className={classes.root}>        
          <Paper elevation={3} className={classes.paper}>
            <DialogTitle style={{textAlign: "center", paddingTop: 0}}>
              <b style={{fontSize: "1.5em"}}>
                EDIT CELL
              </b>
            </DialogTitle>
            <Typography align="center" variant="body2" style={{marginBottom: 20}}> 
              Your cell is a dynamic NFT. You can change it's metadata as many times as you want.<br/> Use the refresh button on OpenSea once you are done!
            </Typography>            
            <Grid container alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6} style={{display: "flex", alignItems: "stretch", justifyContent: "center"}}>
                <div className={classes.imgdiv}>
                  {!!txtImageUrl && isURL(txtImageUrl) ?
                      <div style={{position: "relative", padding: 0, margin: 0, display: "inline-block"}}>
                        <div className={classes.overlay} style={{overflow:"hidden", backgroundImage:`url(${cellpattern || randomPattern()})`, opacity: ((opacity || 0) / 100), transform: `rotate(${rotation === "Normal" ? 0 : rotation})`}}></div>
                        {contentType === "image" ?
                          <img className={classes.img} src={txtImageUrl} alt="Cell" style={{transform: `rotate(${rotation === "Normal" ? 0 : rotation})`}} />
                          : 
                          <video autoPlay muted loop className={classes.img} src={txtImageUrl} alt="Cell" style={{transform: `rotate(${rotation === "Normal" ? 0 : rotation})`}} />
                        }
                      </div>
                      :
                      <div style={{width:"100%", height: "100%",backgroundColor: "rgb(108 103 104)", flexGrow: 1, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", color: "#12ccc5", fontSize: 100, fontWeight: "bold"}}>?</div>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{display: "flex", alignItems: "stretch", justifyContent: "center", flexDirection: "column"}}>
                <Collapse in={!alreadyLocked}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="NFT Title"
                    type="text"
                    fullWidth
                    required
                    variant="outlined"
                    defaultValue={(data.custom_name) ? txtName : ""}
                    onChange={handleNameChange}
                    disabled={locked}
                    error={error.field === "txtName"}
                    helperText={error.field === "txtName" ? (error.message) : undefined}              
                    className={classes.input}
                    inputProps={{style:{color: "#fff", borderColor: "#fff"}}}
                    style={{color: "#fff", borderColor: "#fff"}}
                    onKeyPress={handleKeyPress}
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="NFT Description"
                    type="text"
                    fullWidth
                    required
                    variant="outlined"
                    defaultValue={(data.custom_description) ? txtDesc : ""}
                    onChange={handleDescChange}
                    disabled={locked}
                    error={error.field === "txtDesc"}
                    helperText={error.field === "txtDesc" ? (error.message) : undefined}
                    className={classes.input}
                    inputProps={{style:{color: "#fff", borderColor: "#fff"}}}
                    style={{color: "#fff", borderColor: "#fff"}}
                    onKeyPress={handleKeyPress}
                  />
                  <Grid container spacing={1}>
                    <Grid item style={{flexGrow:1}}>
                      <Tooltip title="Any non-IPFS URLs will be automatically uploaded to IPFS">
                        <TextField
                          margin="dense"
                          id="url"
                          label="NFT Image or Video URL"
                          type="url"
                          fullWidth
                          required
                          variant="outlined"
                          value={txtImageUrl ? txtImageUrl : ""}
                          onChange={handleImageChange}
                          disabled={locked}
                          error={error.field === "txtImageUrl"}
                          helperText={error.field === "txtImageUrl" ? (error.message) : undefined}
                          className={classes.input}
                          inputProps={{style:{color: "#fff", borderColor: "#fff"}}}
                          style={{color: "#fff", borderColor: "#fff"}}
                          onKeyPress={handleKeyPress}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                      <Tooltip title={"Upload or pick from your IPFS Files"}>
                        <Button className={classes.green} onClick={() => setBrowseFiles(true)} >
                          IPFS
                        </Button>
                      </Tooltip>                
                    </Grid>
                  </Grid>
                  <TextField
                    margin="dense"
                    id="external_url"
                    label="NFT External Link URL"
                    type="url"
                    fullWidth
                    variant="outlined"
                    defaultValue={(data.custom_external_url) ? txtExternalUrl : ""}
                    onChange={handleURLChange}
                    disabled={locked}
                    error={error.field === "txtExternalUrl"}
                    helperText={error.field === "txtExternalUrl" ? (error.message) : undefined}
                    className={classes.input}
                    inputProps={{style:{color: "#fff", borderColor: "#fff"}}}
                    style={{color: "#fff", borderColor: "#fff"}}
                    onKeyPress={handleKeyPress}
                  />             
                </Collapse>
                <DialogContentText align="center" style={{marginTop: 5, color: "#FFF"}}>
                  Customize how your cell is displayed on the map.
                </DialogContentText>
                {/* <span style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                  {!!data.custom_image ? <img src={data.custom_image} /> : null}
                  <Select>
                    <MenuItem>Normal</MenuItem>
                    <MenuItem>90deg</MenuItem>
                    <MenuItem>180deg</MenuItem>
                    <MenuItem>-90deg</MenuItem>              
                  </Select>
                </span> */}
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} style={{display: "flex", alignItems: "stretch", justifyContent: "center"}} className={classes.select}>
                    <FormControl variant="outlined" margin="dense" style={{flexGrow:1}}>
                      <InputLabel id="custom-map-label">Custom map</InputLabel>
                      <Select label="Custom map" id="custom-map" value={customMap} onChange={handleMapChange} inputProps={{style:{color: "#fff", borderColor: "#fff"}}} style={{color: "#fff", borderColor: "#fff"}}>
                        <MenuItem value="">None</MenuItem>
                        {
                          tileLayers.map((tile) => (
                            <MenuItem key={tile.name} value={tile.name}>
                              {tile.name}
                            </MenuItem>
                          ))
                        }
                      </Select> 
                    </FormControl>                  
                  </Grid>
                  <Grid item xs={12} md={6} style={{display: "flex", alignItems: "stretch", justifyContent: "center"}} className={classes.select}>
                    <FormControl variant="outlined" margin="dense" style={{flexGrow:1}}>
                      <InputLabel id="custom-image-rotation-label">Image rotation</InputLabel>
                      <Select label="Image rotation" id="custom-image-rotation" value={rotation} onChange={handleRotationChange} inputProps={{style:{color: "#fff", borderColor: "#fff"}}} style={{color: "#fff", borderColor: "#fff"}}>
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="90deg">90º</MenuItem>
                        <MenuItem value="180deg">180º</MenuItem>
                        <MenuItem value="-90deg">-90º</MenuItem>              
                      </Select>         
                    </FormControl>                    
                  </Grid>                          
                </Grid>
                <div style={{flexGrow: 1,textAlign: "center", marginTop: 10, width: "100%"}}>
                  <Typography id="slider-label" gutterBottom variant="body2" align="center">
                    OVERLAY OPACITY
                  </Typography>                  
                  <Slider
                      margin="dense"
                      id="opacity"
                      variant="outlined"
                      aria-labelledby="slider-label"
                      value={Number(!!opacity && opacity)}
                      min={0}
                      max={100}
                      marks
                      valueLabelDisplay="auto"
                      onChange={handleOpacityChange} 
                      valueLabelFormat={(x) => <div style={{fontSize: "0.8em"}}>{x}%</div>}
                  /> 
                </div>  
                <DialogActions style={{alignSelf: "flex-end", width: "100%"}}>
                    <div style={{width: "70%", textAlign: "left", paddingLeft: "0"}}>
                      {!data.attachedTo && 
                      <>
                        {!data.traits.zone && !data.traits.origin && !data.attachedCount && 
                          <Tooltip title={<>Not happy with your Cell?<br /><b>REROLL it for 100 $CELDA</b></>}>
                            <IconButton color="primary" variant="contained" onClick={() => handleClose("reroll")}><RerollIcon htmlColor="#12ccc5" fontSize="large" /></IconButton>
                          </Tooltip>
                        }
                        <Tooltip title={<b>EVOLVE YOUR CELL!</b>}>
                          <IconButton color="primary" variant="contained" onClick={() => handleClose("evolve")}><EvolveIcon htmlColor="#12ccc5" fontSize="large" /></IconButton>
                        </Tooltip>
                        <Tooltip title={<><b>SELECT{isBattleCell && "ED"} AS BATTLE CELL!</b><br />Your NFT Battles wins and losses {isBattleCell ? "are": "will be"} counted on this Cell</>}>
                          <IconButton color="primary" variant="contained" onClick={() => updateBattleCell(data.tokenId)}>
                            <SvgIcon component={BattlesIcon} viewBox="0 0 800 800" style={{width: 24, height: 24, color: isBattleCell ? "gold" : "#12ccc5", filter: isBattleCell && "drop-shadow(0 0 10px gold)"}} />
                          </IconButton>
                        </Tooltip>      
                      </>
                      }
                    </div>
                  <Button onClick={() => handleClose("cancel")} color="primary" variant="contained" style={{color: "#ddd"}}>
                    Cancel
                  </Button>
                  <Button onClick={() => handleClose("ok")} color="primary" variant="contained" style={{fontWeight: "bold"}}>
                    Update
                  </Button>
                </DialogActions>             
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
      { browseFiles && <BrowseDialog selectFile={selectFile} setBrowseFiles={setBrowseFiles} browseFiles={browseFiles} />  }
    </React.Fragment>
  );
})

export default CellDialog

