import React, {useContext} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import { IconButton, Grow } from '@material-ui/core';
import Draggable from 'react-draggable';
import GlobalContext from "./Context";
import StarIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import verifiedImg from './img/verified_nft_green2.png'
import {getBadge} from './Utils'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

//const etherscan_url = process.env.REACT_APP_ETHERSCAN_URL
const isMobile = L.Browser.mobile

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(255,255,255,0.3)",
    borderRadius: 30
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    flexGrow: 1,
    borderRadius: 30
  },
  img: {
    margin: 'auto',
    display: 'flex',
    width: 128,
    height: 128,
    maxWidth: '100%',
    maxHeight: '100%',
    fontSize: 72,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#12ccc5"
  }
}));


export default function UserProfile(props) {
  const context = useContext(GlobalContext)
  const {userData, setOpen} = props;
  const classes = useStyles();
  const {setFilters} = context.filters
  const {currentStats} = context.currentStats

  let userStats = currentStats && currentStats.userStats && userData && currentStats.userStats.find(s => s.address === userData.address)

  const handleClose = () => {
    setOpen(false);
  };
  
  const viewCells = () => {
    setOpen(false)
    setFilters({userAddress: userData.address})
  };

  return (
    <div>
      <Dialog
        open={!!userData}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, width: "100%", margin: 0}}}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
        
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}} className={classes.root}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          
            <Paper elevation={5} className={`${classes.paper}`}>
              <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{position: "relative"}}>
                   <Grid container item direction="row" alignItems="center" justifyContent="flex-start" style={{position: "absolute", left: -10, top: -5, width: "auto"}}>
                      <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5}}>
                          <Tooltip title={`Visited ${userData.visitedCount} cell${userData.visitedCount !== 1 ? "s" : ""}`} placement="top">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><VisibilityIcon style={{paddingRight: 5, fontSize: "1em"}} /><span>{userData.visitedCount}</span></div>
                          </Tooltip>
                      </Grid>
                      <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5}}>
                          <Tooltip title={`Favorited ${userData.favoriteCount} cell${userData.favoriteCount !== 1 ? "s" : ""}`} placement="top">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><StarIcon style={{paddingRight: 5, fontSize: "1em"}} /><span>{userData.favoriteCount}</span></div>
                          </Tooltip>
                      </Grid>          
                  </Grid>                  
                  <Tooltip title={`${userData.verifiedNFTCount + userData.verifiedCustomNFTCount > 0 ? userData.verifiedNFTCount + userData.verifiedCustomNFTCount : 0} Verified NFTs`}><div className="verified-badge profile"><span style={{fontSize: "1.2em"}}>{userData.verifiedNFTCount + userData.verifiedCustomNFTCount > 0 ? userData.verifiedNFTCount + userData.verifiedCustomNFTCount : 0}</span><img src={verifiedImg} alt="verified" /></div></Tooltip>
                  <Grid item>
                      <Avatar className={classes.img} alt="" src={userData.avatarUrl + "?img-width=300"} style={{fontFamily: "Roboto", backgroundColor: !!userData.avatarUrl ? "transparent" : "#12ccc5"}}>
                        {userData.username && userData.username.slice(0,1).toUpperCase()}
                      </Avatar>
                  </Grid>
                  <Grid item container wrap="nowrap" justifyContent="center" spacing={2} direction="column">
                    <Grid item container spacing={1} alignItems="center" direction="column">
                      <Typography variant="caption" align="center" style={{maxWidth: isMobile ? 200 : 400, color: "rgba(255,255,255,0.54)", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: isMobile ? "0.6em" : "0.75rem"}} gutterBottom>
                        {userData.address}
                      </Typography>
                      <Grid item style={{display:"flex",alignItems:"center",textAlign:"center"}}>
                          <Typography variant={isMobile ? "h6" : "h4"} style={{margin: "auto", color: "#12ccc5"}}>
                            <b>{userData.username}</b>
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" align="center">
                          Owns <b>{userData.tokenBalance}</b> Cell{userData.tokenBalance !== 1 ? "s" : ""}!
                        </Typography>
                        <Tooltip title={`View ${userData.username}'s Cells`}>
                          <IconButton size="small" onClick={viewCells} style={{position: "relative", left: 10}}><VisibilityIcon htmlColor="#DDD"/></IconButton>
                        </Tooltip>
                    </Grid>                            
                    {userData.desc && 
                      <Grid item>
                          <Typography variant="body1" align="center">
                            {userData.desc}
                          </Typography>
                      </Grid>                            
                    }                    
                    <Grid item container style={{alignItems: "center", opacity: .8, justifyContent: "center"}}>
                      {userData.externalUrl && 
                        <Grid item>
                          <Tooltip title="External URL"><img src="https://cells.land/img/icons/externalurl.svg" alt="externalurl" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.externalUrl,"blank")}/></Tooltip>
                        </Grid>
                      }                    
                      {userData.twitter && 
                        <Grid item>
                          <Tooltip title="Twitter"><img src="https://cells.land/img/icons/twitter.svg?v2" alt="twitter" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.twitter,"blank")}/></Tooltip>
                        </Grid>
                      }
                      {userData.instagram && 
                        <Grid item>
                          <Tooltip title="Instagram"><img src="https://cells.land/img/icons/instagram.svg" alt="instagram" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.instagram,"blank")}/></Tooltip>
                        </Grid>
                      }
                      {userData.telegram && 
                        <Grid item>
                          <Tooltip title="Telegram"><img src="https://cells.land/img/icons/telegram.svg" alt="telegram" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.telegram,"blank")}/></Tooltip>
                        </Grid>
                      }
                      {userData.opensea && 
                        <Grid item>
                          <Tooltip title="OpenSea"><img src="https://cells.land/img/icons/opensea.svg" alt="opensea" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.opensea,"blank")}/></Tooltip>
                        </Grid>
                      }                      
                      {/* {userData.discord && 
                        <Grid item>
                          <Tooltip title="Discord"><img src="https://cells.land/img/icons/discord.svg" alt="discord" style={{width: 36, height: 36, padding: 10, cursor: "pointer"}} onClick={() => window.open(userData.discord,"blank")}/></Tooltip>
                        </Grid>
                      } */}
                    </Grid>
                    <Grid item container spacing={2} justifyContent="space-around" alignItems="stretch">
                      <Grid item xs>
                        {userStats && 
                          <Paper style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.3)", color: "white"}}>

                            <Grid container alignItems="center" justifyContent="space-evenly" direction="row">
                              <Grid item style={{fontSize: 130}}>
                                {getBadge(userStats.cellCount)}
                              </Grid>
                              <Grid item style={{padding: 10, lineHeight: 2, textAlign: "left"}}>
                                Owns <b>{userStats.cellCount}</b> Cells in <b>{userStats.cantCountries}</b> countries<br />
                                Favorited <b>{userStats.userFavorites}</b> Cells and received <b>{userStats.cellFavorites}</b> favorites<br />
                                Visited <b>{userStats.userVisits}</b> playgrounds and received <b>{userStats.cellVisits}</b> visitors<br />
                                {userStats.cellsWithContent > 0 && <>Has <b>{userStats.cellsWithContent}</b> playgrounds filled with a total of <b>{userStats.contentCount}</b> contents<br /></>}
                                {userStats.verifiedNFTs > 0 ? <>Has verified ownership of <b>{userStats.verifiedNFTs}</b> NFTs</> : "Hasn't verified ownership of any NFTs yet"}<br />
                              </Grid>
                            </Grid>
                          </Paper>
                        }
                        {/* <Paper style={{height: 100, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(255,255,255,0.3)", color: "white", fontWeight: "bold"}}>
                          <Grid container alignItems="center" justifyContent="center" direction="column">
                            <Grid item style={{marginBottom: 10}}><CellsLogoWhite fontSize="large" /></Grid>
                            <Grid item>BADGES COMING SOON</Grid>
                          </Grid>
                        </Paper> */}
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            </Paper>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}