import React, { useContext, useState } from 'react';
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {Grow, List, ListItem, ListItemText, ListItemIcon, IconButton} from '@material-ui/core';
//import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import AppIcon from '@material-ui/icons/Apps';
import AudioIcon from '@material-ui/icons/Audiotrack';
import UnknownIcon from '@material-ui/icons/InsertDriveFile';
import TextIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import UploadIcon from '@material-ui/icons/CloudUpload';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import GlobalContext from './Context';
import Draggable from 'react-draggable';
import { copyClipboard, bytesToSize, smartTrim, CellsLogoGrad } from './Utils';
import { green } from '@material-ui/core/colors';
import UploadDialog from './Upload';

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    backgroundColor: "rgba(255,255,255,0.3)",
    borderRadius: 30
  },
  paper: {
    padding: isMobile ? 3 : theme.spacing(2),
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    borderRadius: 30,
  },
  green: {
    color: "white",
    backgroundColor: green['500'],
    '&:hover': {
      backgroundColor: green['900'],
    },
  },
  listitem: {
    borderBottom: "1px solid rgba(255,255,255,0.1)",
    '&:hover': {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }
}));




const BrowseDialog = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {userAccount, setUserAccount} = context.userAccount
  const {setLoading} = context.loading
  const [accessToken, ] = context.accessToken
  const [upload, setUpload] = useState(false)
  const classes = useStyles();
  const { refreshToken, snackbarShowMessage } = context
  const { selectFile, browseFiles, setBrowseFiles } = props

  const handleClose = (action) => {
    setBrowseFiles(false);
  };

  const handleClick = async (action, hash) => {
    if (action === "upload") {
      if (process.env.REACT_APP_DEBUG) console.log("upload")
      setUpload(true)
    }
    if (action === "pinata") {
      window.open("https://pinata.cloud","_blank")
    }
    if (action === "copy") {
      copyClipboard("https://ipfs.thecellszone.com/ipfs/" + hash, snackbarShowMessage, "Copied https://ipfs.thecellszone.com/ipfs/" + hash + " to clipboard")
    }
    if (action === "copyhash") {
      copyClipboard(hash, snackbarShowMessage, "Copied IPFS Hash to clipboard")
    }    
    if (action === "delete") {
      if (window.confirm("This cannot be undone. Are you sure?\n* NOTE: Files might remain online even if you unpin them.")){
        await handleDelete(hash)
      }
    }
    if (action === "select") {
      selectFile("https://ipfs.thecellszone.com/ipfs/" + hash)
      handleClose()
    }
  }

  const handleDelete = async (hash) => {
    refreshToken().then((ok) => {
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
        body: JSON.stringify({hash: hash}),
      }
      //if (process.env.REACT_APP_DEBUG) console.log(process.env.NODE_ENV);
      let url = process.env.REACT_APP_API_URL + '/deleteFile';
  
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => { 
          //if (process.env.REACT_APP_DEBUG) console.log(result)
          if (result.result) {
            let user = {...userAccount}
            user.ipfsFiles = user.ipfsFiles.filter((f) => f.hash !== hash)
            setUserAccount(user)          
            snackbarShowMessage("File unpinned!");
          } else {
            snackbarShowMessage("File not found or already unpinned", "error");                
          }
          setLoading()
        },
        (error) => {                                
          setLoading()
          snackbarShowMessage("ERROR: " + error, "error");
        }
      );       
    },(err) => {if (process.env.REACT_APP_DEBUG) console.log("error",err)})
    
  }    

  return (
    <div>
      <Dialog
        open={browseFiles}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}} className={classes.root}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={2} justifyContent="center" alignItems="stretch" direction="column">
              {userAccount && userAccount.ipfsFiles && userAccount.ipfsFiles.length > 0 && 
              <>
                <Grid item container style={{display: "flex", alignItems: "center",borderBottom: "2px solid rgba(255,255,255,0.2)", paddingBottom: 15}}>
                  <Grid item xs={false} md={2}>
                    <img src="https://global-uploads.webflow.com/60b7e77d3e2aee4e9a1b035b/60dde044b701fcb480661ac8_Pinnie.svg" style={{margin: "0 24px", maxHeight: 60, cursor: "pointer"}} onClick={() => handleClick("pinata")} alt="Pinata" />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography align="center" variant="h3" style={{color: "#12ccc5"}}>
                      <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item style={{display: "flex"}}><CellsLogoGrad fontSize="large" /></Grid>
                        <Grid item style={{color: "rgba(255,255,255,0.5)"}}><b>IPFS</b></Grid>
                      </Grid>
                      
                    </Typography>
                    <Typography variant="subtitle1" align="center" style={{color: "rgba(255,255,255,0.8)"}}>
                      You uploaded <b>{userAccount.ipfsFiles.length}</b> file{userAccount.ipfsFiles.length === 1 ? "" : "s"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} align="center" style={{padding: 10}}>
                    <Button className={classes.green} startIcon={<UploadIcon />} onClick={() => handleClick("upload")} size="large">Upload</Button>
                  </Grid>
                </Grid>
                <Grid item style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                  <List style={{flexGrow: 1,maxHeight: 400, overflow: "auto"}} dense >
                    {[...userAccount.ipfsFiles].reverse().map((file) => {
                      if (!file) return null
                      const date = new Date(file.date)
                      const primary = <><Link href={"https://ipfs.thecellszone.com/ipfs/" + file.hash} style={{color:"#12ccc5",fontSize: "1.2em"}} target="_blank">{smartTrim(file.name, 50)}</Link><span style={{color:"rgba(255,255,255,0.6)", marginLeft: 5, fontSize: "0.8em"}}>{bytesToSize(file.size)}{file.width && file.height && " " + file.width + "x" + file.height}</span></>
                      const secondary = <><Tooltip title="Copy IPFS Hash to clipboard"><Link onClick={() => handleClick("copyhash", file.hash)} style={{color:"rgba(255,255,255,0.8)",cursor: "pointer"}} target="_blank">{smartTrim(file.hash, 20)}</Link></Tooltip><span style={{color:"rgba(255,255,255,0.6)", marginLeft: 5, fontSize: "0.8em"}}>{date.toLocaleString()}</span></>
                      let icon = <UnknownIcon htmlColor="#12ccc5" fontSize="large"/>
                      if (file.type.match(/image\//)) {
                        let url = "https://ipfs.thecellszone.com/ipfs/" + file.hash
                        if (file.type !== "image/svg+xml") url += "?img-width=50"
                        icon = <img src={url} alt="" style={{borderRadius: 5}}/>
                      } else if (file.type.match(/video\//)) {
                        icon = <VideoIcon htmlColor="#12ccc5" fontSize="inherit"/>
                      } else if (file.type.match(/audio\//)) {
                        icon = <AudioIcon htmlColor="#12ccc5" fontSize="inherit"/>
                      } else if (file.type.match(/text\//) || file.type.match(/\/json/)) {
                        icon = <TextIcon htmlColor="#12ccc5" fontSize="inherit"/>
                      } else if (file.type.match(/application\//)) {
                        icon = <AppIcon htmlColor="#12ccc5" fontSize="inherit"/>
                      }
                      return (
                        <ListItem key={file.hash + file.date} className={classes.listitem}>
                          {!isMobile && 
                            <ListItemIcon style={{fontSize: "3.1875rem",marginRight: 10}}>
                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              {icon}
                              </div>
                            </ListItemIcon>
                          }
                          <ListItemText primary={primary} secondary={secondary} primaryTypographyProps={{style: {maxWidth: 700}}}/>
                          <div>
                            {selectFile && 
                              <Tooltip title="Use this file">
                                <IconButton aria-label="select" onClick={() => handleClick("select",file.hash)}>
                                  <TouchAppIcon htmlColor="rgba(200,200,200,.8)" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            }
                            <Tooltip title="Copy URL to clipboard">
                              <IconButton aria-label="copy" onClick={() => handleClick("copy",file.hash)}>
                                <CopyIcon htmlColor="rgba(200,200,200,.8)" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Unpin">
                              <IconButton edge="end" aria-label="delete" onClick={() => handleClick("delete",file.hash)}>
                                <DeleteIcon htmlColor="rgba(200,200,200,.8)" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </ListItem>
                      )
                    })}
                  </List>
                </Grid>
              </>
              }
              {!!userAccount && (!userAccount.ipfsFiles || userAccount.ipfsFiles.length === 0) && (
                <div style={{minHeight:200, display:"flex", alignItems: "center", justifyContent: "center"}}>
                  <Typography variant={"h6"} style={{color: "white"}} align="center">
                    You didn't upload anything yet.<br /><br />
                    <Button variant="contained" startIcon={<UploadIcon />} className={classes.green} size="large" onClick={() => handleClick("upload")}>Upload now!</Button>
                  </Typography>
                </div>
              )}

            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
      { upload && !!userAccount && <UploadDialog browseFiles={browseFiles} setBrowseFiles={setBrowseFiles} upload={upload} setUpload={setUpload}/> }
    </div>
  );
})

export default BrowseDialog