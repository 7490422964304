import React, { useState, useEffect } from "react";
import axios from 'axios';
import Mapa from "./Mapa";
import LoadingScreen from "./LoadingScreen";
import "./App.css";
//import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

// LogRocket.init('17uzkl/cells');
// setupLogRocketReact(LogRocket);

const urlQuery = new URLSearchParams(window.location.search)
const isPic = urlQuery.get("pic")
const isTokenId = urlQuery.get("tokenId")
const isCell = urlQuery.get("cell")
const isHdr = urlQuery.get("h")
//const isGame = urlQuery.get("battle") || window.location.hostname === "nftbattles.xyz" || window.location.hostname === "test.nftbattles.xyz"

//import ApolloClient from "apollo-boost";
// import { ApolloProvider } from "@apollo/react-hooks";

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_GRAPHQL_URL,
// });

const App = () => {
  const [cells, setCells] = useState()
  const [origins, setOrigins] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    let url = process.env.REACT_APP_API_URL;
    let hdr = isHdr && {"x-cells": isHdr}

    if (isPic && !!isTokenId) {
      url = url + '/getByTokenId/' + isTokenId
    } else if (isPic && !!isCell) {
      url = url + '/getByCeldaCod/' + isCell
    } else {
      url = url + '/getCells'
    }

    try {      
      axios.get(url,{
        onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setLoading(percentCompleted)
        },
        headers: hdr || {}
      })
      .then((result) => { 
         if (result.data) {
          setOrigins(result.data.filter(d => d.traits.origin))
          setCells(result.data)
         } 
         else if (process.env.REACT_APP_DEBUG) console.log("getCells", "no data received")
        },
        (error) => {
          console.error(url + " error", error);
        }
      ); 
    } catch (err) {
        console.error(err)
    }
    return () => {}
  },[setLoading])

  return cells ? <Mapa payload={cells} origins={origins} /> : <LoadingScreen loading={loading} />
}

export default App;
