import React, { useContext, useRef } from 'react';
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Grow } from '@material-ui/core';
import Draggable from 'react-draggable';
import { red } from '@material-ui/core/colors';

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-upload" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'visible',
    borderRadius: 30,
    '& .MuiInputBase-root': {
      color: 'white',
      overflow: "visible"
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(33, 33, 33, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'visible',
    minHeight: 400,
    display: 'flex',
    borderRadius: 30,
    flexDirection: 'column',
    fontStyle: "italic",
  },
  img: {
    height: 80,
    padding: 10,
    cursor: "pointer"
  },
  input: {
    color: "white !important",
    borderColor: "white !important"
  },
  textfield: {
    "& label": {
      color: "white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    }
  },
  red: {
    color: "white",
    fontSize: "2.5em",
    margin: 10,
    fontStyle: "italic",
    backgroundColor: red['900'],
    '&:hover': {
      backgroundColor: red['600'],
    },
  },
}));




const BattlesWelcome = React.memo((props) => {
  const classes = useStyles();

  const { battlesWelcome, setBattlesWelcome } = props

  const handleClose = (action) => {
    setBattlesWelcome(false)
  };



  return (
    <div>
      <Dialog
        open={battlesWelcome}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30, overflow: 'visible'}}}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-upload"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10, overflow: 'visible',}}>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <div>
                <Typography variant={"h4"} style={{margin: 10, textAlign: "center", wordBreak: "break-word", color: "gold", fontWeight: "bolder"}}>
                  NEW GAME
                </Typography>
                <Typography variant={"h2"} style={{margin: 10, textAlign: "center", wordBreak: "break-word", color: "white", fontWeight: "bolder", fontStyle: "italic", fontFamily: "Basement"}}>
                  CELDA <span style={{fontSize: "1.5em", color: "#333", textShadow: "0 0 20px orange, 0 0 30px red"}}>NFT</span> BATTLES
                </Typography>
              </div>
              <Grid container justifyContent="center" alignItems="stretch" direction="row" style={{flexGrow: 1}}>
                <Grid item xs={12} sm={4} style={{display: "flex", justifyContent:"center", alignItems: "center", flexGrow: 1, flexDirection: "column"}}>
                  <img src="https://cells.land/img/logos/battles.png?v2" style={{position: "relative", cursor: "pointer", marginBottom: 10, width: 300, height: 300}} alt="CELDA NFT Battles" className="battlesLogo" />
                </Grid>              
                <Grid item container xs={12} sm={8} style={{justifyContent:"space-evenly", alignItems: "center", flexDirection: "column", padding: 10}}>
                  <div style={{margin: 20, display: "flex", flexWrap: "nowrap"}}>
                    <img src="https://nftbattles.xyz/images/Doodles-p-500.png" style={{width: 120}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/WoW.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/Azuki-p-500.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/CloneX-p-500.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/CoolCats-p-500.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/Bonsai-p-500.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/MAYC.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                    <img src="https://nftbattles.xyz/images/Cells-p-500.png" style={{width: 120, marginLeft: -60}} alt="card" className="battlecard" />
                  </div>
                  <Typography variant={"h4"} style={{margin: 10, textAlign: "center", wordBreak: "break-word", color: "gold"}}>
                    Use your NFT Portfolio to Battle and earn CELDA!
                  </Typography>
                  <Button variant="contained" color="secondary" size="large" className={classes.red} onClick={() => window.open("https://nftbattles.xyz","_blank")}>PLAY NOW</Button>
                  <Typography variant="body1" onClick={handleClose} style={{marginBottom: 20, cursor: "pointer", textDecoration: "underline"}}>NO THANKS</Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default BattlesWelcome