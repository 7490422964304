import { useEffect } from "react";
import L from 'leaflet'
import { useLeafletContext, createElementHook } from '@react-leaflet/core';


const useCellsLogoElement = createElementHook(createCellsLogo, updateCellsLogo)

function createCellsLogo(props, context) {
  
  let coords = L.latLngBounds(L.latLng(props.sw[0], props.sw[1]),L.latLng(props.ne[0], props.ne[1])); //9, 6, 8, 7
  
  
  return { 
    instance: L.imageOverlay(
      'https://cells.land/img/logos/logomark_white@4x.png' ,
      [coords.getSouthWest(), coords.getNorthEast(),],
      {interactive: false, className: props.customClass} 
      ),
    context
  }

}
  
function updateCellsLogo(instance, props, prevProps) {
//    if (props.data.celdaCod !== prevProps.data.celdaCod) {
   //   if (process.env.REACT_APP_DEBUG) console.log('updateCellsLogo');
  //  }
}

export default function CellsLogo(props) {
  
    const context = useLeafletContext();
    const elementRef = useCellsLogoElement(props, context);

    useEffect(() => {
        const eRef = elementRef.current;
        const container = context.layerContainer || context.map
        container.addLayer(eRef.instance);
        //eRef.instance.bringToFront()

        return () => {
          container.removeLayer(eRef.instance)    
        }
    }, [elementRef, context.layerContainer, context.map])

    return null
}
