import React, {useState, useContext, useEffect} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import EvolveIcon from '@material-ui/icons/OfflineBoltRounded';
import { Button, Grow } from '@material-ui/core';
import {ethers} from 'ethers';
import GlobalContext from "./Context";
import Draggable from 'react-draggable';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const evolutionABI = require('./contracts/Evolutions.json').abi;
const erc20ABI = require('./contracts/Celda.json').abi;
const evolutionsContractAddress = process.env.REACT_APP_EVOLUTIONS_CONTRACT || "0x9cDa8121A77b94138Ca440018b4CF8aeE81E0DeB" // EVOLUTIONS POLY
const wethAddress = process.env.REACT_APP_DEBUG ? "0xC83b0Ce17525ee7657B99F751E9501c1390b5921" : "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden',
    textAlign: 'center'
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
	select: {
	  color: "#ccc !important",
	  fontSize: "0.9em",
	  "& label": {
		color: "#ccc !important"
	  },
	  "& .MuiOutlinedInput-notchedOutline": {
		color: "#ccc !important",
		borderColor: "#ccc !important"
	  }
	},
}));




const MintEvolution = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {mint, setMint} = props;
  const classes = useStyles();
  const [quantity, setQuantity] = useState(3);
  const [minting, setMinting] = useState(false);
  const [currency, setCurrency] = useState("matic");
  const [allowance, setAllowance] = useState(false);
  const [approving, setApproving] = useState(false);
  const [tx, setTx] = useState(false);
  const {currentChain} = context.currentChain
  const {provider} = context.provider
  const {snackbarShowMessage, refreshToken} = context
  const {userAccount, setUserAccount} = context.userAccount
  const [mintedFree, setMintedFree] = useState(userAccount?.freeEvo);
  const evolutionsContract = new ethers.Contract( evolutionsContractAddress , evolutionABI , provider.getSigner() )
  const wethContract = new ethers.Contract( wethAddress , erc20ABI , provider.getSigner() )
  const isPolygon = currentChain === "0x13881" || currentChain === "0x89"

  const MINT_PRICE = process.env.REACT_APP_DEBUG ? 0.001 : currency === "matic" ? 3 : 0.002

  const handleClose = () => {
    setMint(false);
  };
  
  const handleToggle = (event, newValue) => {
    if (!newValue) return
    setCurrency(newValue);
  };

  const switchToPoly = async () => {
    if (!window.ethereum) return
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: process.env.REACT_APP_DEBUG ? "0x13881" : "0x89" }], // chainId must be in hexadecimal numbers
      });
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: process.env.REACT_APP_DEBUG ? 'Polygon Mumbai Testnet' : 'Polygon',
                chainId: process.env.REACT_APP_DEBUG ? "0x13881" : "0x89",                
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC',
                  decimals: 18
                },
                blockExplorerUrls: [process.env.REACT_APP_DEBUG ? "https://mumbai.polygonscan.com/" : "https://polygonscan.com/"],
                rpcUrls: [process.env.REACT_APP_DEBUG ? "https://rpc-mumbai.maticvigil.com/" : "https://polygon-rpc.com/"],
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(error);
    }
  }

  const fetchData = async (url, method, body) => {
    return await refreshToken().then(async (result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("wallet fetchData", "at not present")
        return null
      }      
      let at = result.at
      try {
        let requestOptions = {
          method: method,
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at },
        }
        if (body) requestOptions = {...requestOptions, body: JSON.stringify(body)}

        return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (result) => { 
              return result
          },
          (error) => {
              if (process.env.REACT_APP_DEBUG) console.log("error", error)
          }
        ); 
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log("error", err)
      }
    }, (err) => {if (process.env.REACT_APP_DEBUG) console.log("error", err)})
  }

  const handleMint = async () => {
    if (quantity < 1) {
      alert('Invalid amount')
      return
    }
    let q = mintedFree ? quantity : 1
    if (!isPolygon) {
      switchToPoly()
    } else {
      if (currency === "weth" && !allowance) {
        setApproving(true)
        const t = await wethContract.approve(evolutionsContract.address, "10000000000000000000000000000000");
        if (t) await provider.waitForTransaction(t.hash)
        snackbarShowMessage("Approved!")
        setAllowance(true)
        setApproving(false)
        return
      }      
      setMinting(true)
      let amount = ethers.utils.parseUnits(String(q * MINT_PRICE), "ether")
      let freevo
      if (!mintedFree) {
        freevo = await fetchData(process.env.REACT_APP_API_URL + "/freeevo", "GET")
        if (!freevo || !freevo.signature) {
          setMinting(false)
          if (freevo.error) alert(freevo.error)
          return;
        }
      }
      let t
      try {
         t = mintedFree ? 
            ( currency === "matic" ? await evolutionsContract.mintEvolutions(q, {value: String(amount)}) : await evolutionsContract.mintEvolutionsWithToken(wethAddress, q) )
           :
            await evolutionsContract.mintOneFreeEvo(freevo.signature)
      } catch (ex) {
        console.log(ex)
        setMinting(false)
        alert(ex.data?.message || ex.message)
      }
      if (t) {
        setTx(t.hash)
        await provider.waitForTransaction(t.hash)
        setTx()
        snackbarShowMessage(`Congrats! You minted ${q} Evolutions!`)
        setMintedFree(true)
        setMinting(false)  
        let ua = {...userAccount}
        ua.freeEvo = true
        setUserAccount(ua)
        setTimeout(() => getEvolutions(), 1000)
      }
    }
  };

  function getEvolutions() {
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("mint", "at not present")
        return null
      }
      let at = result.at
      try {
        let requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at},
        }
  
        let url = process.env.REACT_APP_API_URL + '/getEvolutions'
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (r) => { 
            if (r) {
              console.log(r)
              if (!r.error) {
                let ua = {...userAccount}
                ua.evolutions = r
                setUserAccount(ua)
              }
            }
          },
          (error) => {
            if (process.env.REACT_APP_DEBUG) console.log("error",error.error)
          }
        ); 
  
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log("error",err)
      }
    }, (err) => {
      if (process.env.REACT_APP_DEBUG) console.log("error", err)
    })
  }

  useEffect(() => {
    async function checkMint() {
      if (!isPolygon) switchToPoly()
      else {
        if (userAccount.cells.length > 0 && !mintedFree) {
          let check = await evolutionsContract.claimed(userAccount.address)
          setMintedFree(check)
        }    
      }
    }
    checkMint()
  },[isPolygon, mintedFree])

  
  useEffect(() => {
    async function checkApprove() {
      if (!isPolygon) switchToPoly()
      else {
        if (!allowance && currency === "weth") {
          let check = ethers.utils.formatEther(await wethContract.allowance(userAccount.address, evolutionsContract.address))
          if (process.env.REACT_APP_DEBUG) console.log("allowance", check)
          setAllowance(check > 1)
        }
      }
    }
    checkApprove()
  },[isPolygon, allowance, currency])

  return (
    <div>
      <Dialog
        open={mint}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="xs"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root} >
            <Paper elevation={3} className={classes.paper}>
              <DialogTitle>HOW MANY EVOLUTIONS DO YOU WANT TO MINT?</DialogTitle>
              {mintedFree && 
                <ToggleButtonGroup
                  value={currency}
                  exclusive
                  onChange={handleToggle}
                  aria-label="currency"
                  color="primary"
                  size="small"
                  style={{width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10}}
                >
                <ToggleButton value="matic" aria-label="matic" style={{color: currency === "matic" ? "#12ccc5" : "white", padding: 10, backgroundColor: "#ffffff20"}}>
                  <img src="https://cells.land./img/tokens/wmatic.png" alt="matic" width="20" height="20" />&nbsp;&nbsp;<b>MATIC</b>
                </ToggleButton>
                <ToggleButton value="weth" aria-label="weth" style={{color: currency === "weth" ? "#12ccc5" : "white", padding: 10, backgroundColor: "#ffffff20"}}>
                  <img src="https://cells.land./img/tokens/weth.png" alt="weth" width="20" height="20" />&nbsp;&nbsp;<b>WETH</b>
                </ToggleButton>
              </ToggleButtonGroup>              
              }
              <div style={{textAlign: "center", padding: 20, display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: 0, paddingTop: 0}}>
                {mintedFree && 
                    <>
                      <TextField
                        margin="dense"
                        id="quantity"
                        type="number"
                        variant="outlined"
                        color="primary"
                        value={quantity || 0}
                        onChange={(e) => setQuantity(e.target.value)}
                        inputProps={{style:{color: "#ccc", borderColor: "#ccc"}}}
                        style={{color: "#ccc", borderColor: "#ccc"}}
                        className={classes.select}
                      />                                  
                    </>
                    }
                <Button variant="contained" onClick={handleMint} color="primary" style={{marginLeft: 10, top: 2, position: "relative", whiteSpace: "nowrap"}} disabled={minting || approving} endIcon={<EvolveIcon fontSize="large"/>} >
                  {minting ? <b>MINTING...</b> : <b>{!mintedFree ? "MINT ONE FOR FREE" : approving ? "APPROVING..." : currency === "matic" || allowance ? `MINT WITH ${String(currency).toUpperCase()}` : `APPROVE WETH`}</b> }
                </Button>
              </div>              
              <div style={{textAlign: "center"}}><br />Evolution mints helps us raise funds <br />for the upcoming $CELDA Liquidity Pool</div>
              <div style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                {!mintedFree && <div style={{textAlign: "center", paddingBottom: 20}}><br /><span style={{fontSize: "0.8em", color: "#ffffff80"}}>FIRST MINT</span><br /><b>FREE</b></div>}
                <div style={{textAlign: "center", paddingBottom: 20}}><br /><span style={{fontSize: "0.8em", color: "#ffffff80"}}>PRICE</span><br /><b>3 MATIC or 0.002 WETH</b></div>
              </div>
          </Paper>
        </div>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default MintEvolution