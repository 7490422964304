import React, {useState, useContext, useMemo} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Grow } from '@material-ui/core';
import Draggable from 'react-draggable';
import StarIcon from '@material-ui/icons/Star';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GlobalContext from './Context';
import { smartTrim, OpenSeaIconWhite, zones } from './Utils'
import UserProfile from './UserProfile';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BattlesIcon } from "./img/battles.svg";

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: isMobile ? 0 : 3,
    backgroundColor: "rgba(255,255,255,0.3)",
    borderRadius: 30
  },
  paper: {
    padding: theme.spacing(2),
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    minHeight: isMobile ? 0 : 350,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    overflowX: "hidden",
    margin: 3,
    flexGrow: 1,
    borderRadius: 30
  },
  img: {
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: 400,
    cursor: "pointer",
    borderRadius: 5
  },
  owner: {
      color: "#12ccc5",
      fontSize: "1.1em",
      whiteSpace: "wrap",
      "& :hover": {
          textDecoration: "underline"
      }
  },
  smallpaper: {
    padding: 10,
    backgroundColor: "rgba(255,255,255,0.1)",
    textAlign: "center",
    borderRadius: 30
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
  card: {
    margin: 5,
    background: "#00000070",
    color: "#fff",
    overflow: 'hidden',
    height: 50,
    width: 50,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid transparent",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundSize: "cover !important",
    backgroundPosition: "50% 50%",
  },
  Fire: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/fire.gif)",
    border: "2px solid orange"
  },
  Water: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/water.gif)",
    border: "2px solid cyan"
  },
  Air: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/air.gif)",
    border: "2px solid white"
  },
  Earth: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/earth.gif)",
    border: "2px solid brown"
  }
}));


const StyledTooltip = withStyles({
  tooltip: {
    border: "2px solid rgb(115 3 199)",
    borderRadius: 20,
  }
})(Tooltip);

const BlackTooltip = withStyles({
  tooltip: {
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    padding: 15,
    borderRadius: 15,
    textAlign: "center"
  }
})(Tooltip);


const CellInfo = React.memo((props) => {
  const {cellData, setOpen} = props;
  const classes = useStyles();
  const [userProfileOpen, setUserProfileOpen] = useState(false)
  const context = useContext(GlobalContext)
  const {celdaAmounts} = context.celdaAmounts
  const handleClose = () => {
    setOpen()
  };
  
  if (!cellData || !cellData.celdaCod) return null
  
  const zone = cellData.zone && zones.find(z => cellData.zone.toLowerCase() === z.shortName.toLowerCase())

  const CPD = celdaAmounts?.find(a => a.key === "PASSIVE_AMOUNT")?.value || 2
  const ELEMENTAL_BOOST = celdaAmounts?.find(a => a.key === "ELEMENTAL_BOOST")?.value || 1.5
  const GLOBAL_BOOST = celdaAmounts?.find(a => a.key === "GLOBAL_BOOST")?.value || 1
  const ORIGIN_BOOST = celdaAmounts?.find(a => a.key === "ORIGIN_BOOST")?.value || 2
  const MAX_EVOLUTIONS = cellData.evolutions && cellData.evolutions.count > 11 ? 2 : 1
  const EVOLUTIONS_BOOST = 1 + ((cellData.evolutions && cellData.evolutions.boost) || 0) / 100 * MAX_EVOLUTIONS
  const isElemental = zone && ["fire","air","earth","water"].includes(zone.type)
  const isOrigin = !!cellData.traits.origin

  const CELDA_MODIFIER = isElemental ? ELEMENTAL_BOOST : isOrigin ? ORIGIN_BOOST : 1
  const CELDA_PER_DAY = CPD * CELDA_MODIFIER * GLOBAL_BOOST * EVOLUTIONS_BOOST
  const CELDA_ELEMENTAL = isElemental ? <b>ELEMENTAL ZONE BONUS {ELEMENTAL_BOOST}x</b> : isOrigin ? <b>ORIGIN CELL BONUS {ORIGIN_BOOST}x</b> : ""
  const CELDA_GLOBAL_BOOST = GLOBAL_BOOST > 1 ? <b>GLOBAL BOOST BONUS {GLOBAL_BOOST}x</b> : ""
  const EVOLUTIONS_BOOST_TEXT = EVOLUTIONS_BOOST > 1 ? <b>{cellData.evolutions.count} EVOLUTIONS BOOST BONUS {EVOLUTIONS_BOOST.toFixed(2)}x</b> : ""
  const passive = ((CPD / 86400) * (Date.now() - (cellData.celda?.lastExtractDate || Date.parse(cellData.mintedDate))) / 1000 * CELDA_MODIFIER * EVOLUTIONS_BOOST * GLOBAL_BOOST).toFixed(2)

  let isBattleCell = cellData.nftBattles && (cellData.nftBattles.wins > 0 || cellData.nftBattles.losses > 0 || cellData.owner.battleCell === cellData.tokenId)

  const EvosTooltip = () => {
    const Fire = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Fire")
    const Water = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Water")
    const Air = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Air")
    const Earth = cellData.evolutions && cellData.evolutions.list.filter(e => e.type === "Earth")
    return <>
      <Grid container justifyContent="space-around">
        <Grid item className={`${classes.card} ${classes.Earth}`}>
          <Typography variant="h6">
            {(Earth && Earth.length) || 0}
          </Typography>
        </Grid>
        <Grid item className={`${classes.card} ${classes.Air}`}>
          <Typography variant="h6">
            {(Air && Air.length) || 0}
          </Typography>
        </Grid>
        <Grid item className={`${classes.card} ${classes.Water}`}>
          <Typography variant="h6">
            {(Water && Water.length) || 0}
          </Typography>
        </Grid>
        <Grid item className={`${classes.card} ${classes.Fire}`}>
          <Typography variant="h6">
            {(Fire && Fire.length) || 0}
          </Typography>
        </Grid>
      </Grid>
    </>
  }

  return (
    <div>
      <Dialog
        open={!!cellData && !!cellData.celdaCod}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth={'lg'}
        PaperProps={{style:{backgroundColor: "transparent", padding: 0, width: "100%", margin: 0, borderRadius: 30}}}
        PaperComponent={PaperComponent}
        TransitionComponent={Grow}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title"></DialogTitle>
        <DialogContent style={{padding: 0}}>
          <div className={classes.root}>
          <Grid container justifyContent="center" alignItems="stretch">
            <Grid item xs={12} md={4} style={{display: "flex", flexGrow: 1}}>
              <Paper elevation={3} className={classes.paper}>
                <Grid item container style={{alignSelf: "stretch"}} direction="column" justifyContent="center" spacing={2}>
                  <Grid container item direction="row" alignItems="center" justifyContent="space-around">
                    {cellData.tokenId > 0 &&
                      <Grid item container xs={6} justifyContent="center" alignItems="center" spacing={2}>
                        <Typography variant="body1" align="center" style={{color: "rgba(255,255,255,0.7)", margin: 5}}>
                          <b>TOKEN ID</b>
                        </Typography>
                        <Typography variant="h6" align="center" style={{color: "#12ccc5", margin: 5}}>
                          <b>#{cellData.tokenId}</b>
                        </Typography>
                      </Grid>                       
                    }                        
                    <Grid item container xs={6} justifyContent="center" alignItems="center">
                      <Typography variant="body1" align="center" style={{color: "rgba(255,255,255,0.7)", margin: 5}}>
                        <b>S2 CODE</b>
                      </Typography>
                      <Typography variant="h6" align="center" style={{color: "#12ccc5", margin: 5}}>
                        <b>{cellData.celdaCod}</b>
                      </Typography>
                    </Grid>
                  </Grid>  
                  <Grid item container style={{flexGrow: 1, alignItems: "center", justifyContent: "center", cursor: "pointer", fontSize: "1.2em", color: "#12ccc5", minHeight: 400}} onClick={() => !!cellData.custom_image && window.open(cellData.custom_image, "_blank")}>
                    {cellData.custom_type === "video" || (cellData.animated && !cellData.custom_image) ? <video src={cellData.custom_image || "https://cells.land/video/cell/" + cellData.celdaCod + ".mp4"} alt={cellData.custom_name} className={classes.img} autoPlay muted controls loop /> : <img src={cellData.custom_image || "https://cells.land/images/400/cell/" + cellData.celdaCod + ".jpg"} alt={cellData.custom_name || cellData.celdaCod} className={classes.img} />}
                  </Grid>
                  <Grid item container alignItems="center" justifyContent="center">
                    {(cellData.owner || cellData.mintedBy) ? (
                        <Grid item container alignItems="center" justifyContent="space-around" spacing={1} direction="row" wrap="nowrap">
                          {cellData.mintedBy && (
                            <Grid item container direction="column" alignItems="center" justifyContent="center" md={6} onClick={() => setUserProfileOpen(cellData.mintedBy)} style={{cursor: "pointer"}}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)", paddingTop: 3}}>
                                <b>MINTED BY</b>
                              </Typography>
                              <Typography variant="body1" align="center" className={classes.owner}>
                                <b>{cellData.mintedBy.username}</b>
                              </Typography>                            
                            </Grid>
                          )}
                          {cellData.owner && (
                            <Grid item container direction="column" alignItems="center" justifyContent="center" md={6} onClick={() => setUserProfileOpen(cellData.owner)} style={{cursor: "pointer"}}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)", paddingTop: 3}}>
                                <b>OWNED BY</b>
                              </Typography>
                              <Typography variant="body1" align="center" className={classes.owner}>
                                <b>{cellData.owner.username}</b>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )
                      :
                      (
                        <Grid item>
                          <Typography variant="h6" align="center" style={{color: "#12ccc5"}}>
                            <b>NOT MINTED YET</b>
                          </Typography>                        
                        </Grid>
                      )
                      }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
              <Grid item xs={12} md={4} style={{display: "flex", flexGrow: 1}}>
                <Paper elevation={3} className={classes.paper}>
                  <Grid item xs={12} sm container wrap="nowrap" justifyContent="center" direction="column" alignItems="center" style={{alignSelf: "stretch", position: "relative", flexGrow: 1}}>
                    <Grid item container direction="column" justifyContent="space-between" style={{flexGrow:1}}>
                      <Grid item xs={12} sm container wrap="nowrap" justifyContent="space-between" direction="column" alignItems="center">
                        <Grid item>

                        </Grid>
                        <Grid item>
                          <Grid item container style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column", width: "100%"}}>
                            <Grid item>
                              <Typography variant="h5" align="center" style={{color: "#12ccc5", marginTop: 20}}>
                                {cellData.custom_name ? <b>{cellData.custom_name}</b> : <span>Hi! I'm Cell <b>{cellData.celdaCod}</b></span>}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container wrap="nowrap" style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", paddingTop: 10}}>
                            <Grid item style={{flexGrow:1, overflowY: "auto", display: "flex", alignItems: "center", width: "100%", justifyContent: "center"}}>
                              <Typography variant="body2" align="center" style={{textOverflow: "ellipsis", overflowX: "hidden"}}>
                                {!!cellData.custom_description ? cellData.custom_description : "I'm waiting for my owner to give me a proper name and a nice description to replace this text"}
                              </Typography>
                            </Grid>
                          </Grid>                     
                          {cellData.custom_external_url && 
                            <Grid item container wrap="nowrap" style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column", width: "100%"}}>
                              <Grid item>
                                <Typography variant="caption" align="left">
                                  <span style={{display: "flex", alignItems: "center", justifyContent: "center"}}><LinkIcon htmlColor="#DDD" style={{fontSize: "16px"}}/>&nbsp;<Link href={cellData.custom_external_url} target="_blank" rel="noopener noreferrer" style={{color: "#DDD", wordBreak: "break-word"}}>{smartTrim(cellData.custom_external_url,40)}</Link></span>
                                </Typography>
                              </Grid>
                            </Grid>     
                          }       
                        </Grid>                 
                        <Grid item>
                          {isBattleCell && (
                            <Grid item>
                              <Tooltip title={<><b>NFT BATTLE CELL</b></>}>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20}}>
                                    <SvgIcon component={BattlesIcon} viewBox="0 0 800 800" style={{width: 32, height: 32, color: "gold", filter: "drop-shadow(0 0 10px gold)", marginRight: 10}} />
                                    <Typography variant="h6" style={{filter: "drop-shadow(0 0 10px gold)", color: "gold"}} align="center">{cellData.nftBattles ? <>{cellData.nftBattles.wins}W-{cellData.nftBattles.losses}L</> : "NFT BATTLE CELL"}</Typography>
                                </div>
                              </Tooltip>      
                            </Grid>
                          )}                                 
                          <Grid item container wrap="nowrap" style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%"}}>
                            {cellData.evolutions.count > 0 && 
                              <Grid item>
                                <StyledTooltip placement="top" title={<EvosTooltip />} PopperProps={{style:{background: "black !important"}}}>
                                    <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            cursor: "pointer",
                                            textAlign: "center",
                                            background: "rgb(115 3 199)",
                                            borderRadius: 10,
                                            padding: "10px 20px",
                                            fontWeight: "bold",
                                            fontSize: "0.8em"
                                        }}
                                        onClick={() => window.open('https://opensea.io/collection/cells-evolutions', '_blank')}
                                    >
                                        {cellData.evolutions.count} EVOLUTIONS: {EVOLUTIONS_BOOST.toFixed(2)}x BOOST
                                    </div>
                                  </StyledTooltip>
                              </Grid>                
                            }
                            <Grid item>
                              <Button endIcon={<OpenSeaIconWhite />} variant="contained" color="primary" onClick={() => window.open(process.env.REACT_APP_OPENSEA_URL + cellData.tokenId, "_blank")} style={{marginTop: 10}}>View Cell on OpenSea</Button>
                            </Grid>
                            {!!cellData.comment && 
                              <Grid item style={{textAlign: "center", color: "rgba(255,255,255,0.7)", background: "rgba(0,0,0,0.2)", borderRadius: 10, padding: 15, marginTop: 10}}>
                                <Typography variant="caption" align="center" gutterBottom><div dangerouslySetInnerHTML={{ __html: cellData.comment }}></div></Typography>
                              </Grid>
                            }
                          </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" justifyContent="flex-start" style={{position: "absolute", left: -10, top: -10, width: "auto", zIndex: 20}}>
                          <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
                              <Tooltip title={`Visited ${cellData.visitCount} time${cellData.visitCount !== 1 ? "s" : ""}`} placement="top">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><VisibilityIcon style={{paddingRight: 5, fontSize: 17, paddingBottom: 2}} /><span>{cellData.visitCount}</span></div>
                              </Tooltip>
                          </Grid>    
                          <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
                              <Tooltip title={`Favorited ${cellData.favoriteCount} time${cellData.favoriteCount !== 1 ? "s" : ""}`} placement="top">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}><StarIcon style={{paddingRight: 5, fontSize: 17, paddingBottom: 2}} /><span>{cellData.favoriteCount}</span></div>
                              </Tooltip>
                          </Grid>          
                        </Grid>
                        <Grid container item direction="row" alignItems="center" justifyContent="flex-end" style={{position: "absolute", right: -10, top: -10, width: "auto", zIndex: 20}}>
                          {cellData.country.map((c, idx) => {
                            return (
                              <Grid item key={cellData.celdaCod + "-" + c} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 5, fontSize: "0.9em"}}>
                                  <Tooltip title={c} placement="top">
                                    <img alt={c} src={`https://cells.land/img/flags-iso/shiny/24/${cellData.countryCode[idx]}.png`} width={24} height={24}/>
                                  </Tooltip>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} style={{display: "flex", flexGrow: 1}}>
                <Paper elevation={3} className={classes.paper}>
                  <Grid item xs={12} sm container spacing={1} wrap="nowrap" justifyContent="flex-start" direction="column" alignItems="center" style={{alignSelf: "stretch", position: "relative", flexGrow: 1}}>
                    <Grid item container direction="column" justifyContent="space-between" style={{flexGrow:1}}>
                      <Grid item container direction="column" spacing={1} style={{paddingTop: 10,flexGrow:1}} justifyContent="space-evenly">
                        {(cellData.traits.origin || cellData.traits.zone) && (
                          <Grid item container alignItems="center" justifyContent="center" spacing={1} direction="column">
                            <Grid item container direction="column" alignItems="stretch" justifyContent="center">
                              <Paper elevation={2} className={classes.smallpaper} onClick={() => zone && window.open(zone.url,"_blank")} style={{cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {zone && <img src={`https://cells.land/img/zones/${zone.logo}`} alt={zone.shortName} width={32} height={32} style={{marginRight: 10, borderRadius: zone.square ? "0%" : "50%"}}/>}
                                <Typography variant="h6" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                  <b className={cellData.traits.origin || zone.type === "collection" ? "rainbowtext" : "zonetext-" + zone.type}>{(cellData.traits.origin || zone.name).toUpperCase()}</b>
                                </Typography>
                              </Paper>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
                          <Grid item container xs={6} alignItems="stretch" justifyContent="center" direction="column">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>RARITY RANK</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                {cellData.rarity > 0 ? <span className={cellData.rarity > 10000 ? "rainbowtext" : cellData.rarity > 800 ? "raretext" : ""}><b>#{cellData.rarityRank}</b></span> : "¿?"}
                              </Typography>
                            </Paper>
                          </Grid>                          
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>RARITY SCORE</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                {cellData.rarity > 0 ? <span className={cellData.rarity > 10000 ? "rainbowtext" : cellData.rarity > 800 ? "raretext" : ""}><b>{cellData.rarity}</b></span> : "¿?"}
                              </Typography>
                            </Paper>
                          </Grid> 
                        </Grid>                           
                        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>NATIONALITY</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={cellData.traits.nationality === "Quad" || cellData.traits.nationality === "Penta" ? "rainbowtext" : cellData.traits.nationality === "Triple" ? "raretext" : ""}>{cellData.traits.nationality}</b>
                              </Typography>                            
                            </Paper>
                          </Grid>
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>SIZE</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={cellData.traits.size === "Top 10" || cellData.traits.size === "Bottom 12" ? "rainbowtext" : ['Tiny', 'Nano', 'Huge', 'Mega'].includes(cellData.traits.size) ? "raretext" : ""}>{cellData.traits.size}</b>
                              </Typography>                            
                            </Paper>
                          </Grid>
                        </Grid>                     
                        <Grid item container alignItems="center" justifyContent="center" spacing={1} >
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>BORDER COLOR</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={cellData.traits.border === "CHROMATIC" ? "rainbowtext" : cellData.traits.border === "RAINBOW" || cellData.traits.border === "RANDOM"? "rainbowtext2" : ["BlueViolet","HotPink","OrangeRed"].includes(cellData.traits.border) ? "raretext" :""}>{cellData.traits.border}</b>
                              </Typography>                            
                            </Paper>
                          </Grid>
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>BORDER LINES</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={['Snake', 'Flash'].includes(cellData.traits.lines) ? "raretext" : ""}>{cellData.traits.lines}</b>
                              </Typography>                            
                            </Paper>
                          </Grid>                          
                        </Grid>
                        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>OVERLAY</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={['Banana', 'Bitcoin', 'Avocado', 'Ethereum', 'RANDOM', 'CHROMATIC'].includes(cellData.traits.overlay) || cellData.zone ? "rainbowtext" : ""}>{cellData.traits.overlay}</b>
                              </Typography>                            
                            </Paper>
                          </Grid>                          
                          <Grid item container xs={6} direction="column" alignItems="stretch" justifyContent="center">
                            <Paper elevation={4} className={classes.smallpaper}>
                              <Typography variant="caption" align="center" style={{color: "rgba(255,255,255,0.7)"}}>
                                <b>GLOW</b>
                              </Typography>
                              <Typography variant="subtitle1" align="center" style={{color: "rgb(255 255 255)"}}>
                                <b className={cellData.traits.glow === "CHROMATIC" ? "rainbowtext" : ["BlueViolet","HotPink","OrangeRed"].includes(cellData.traits.glow) ? "raretext" : ""}>{cellData.traits.glow}</b>
                              </Typography>
                            </Paper>
                          </Grid> 
                        </Grid>
                      </Grid>
                      {cellData.mintedDate && 
                        <Grid item container alignItems="center" justifyContent="center" spacing={1} style={{fontSize: "0.8em", padding: "5px 10px", backgroundColor: "#12ccc580", borderRadius: 10, marginTop: 10}} direction="column">
                          <BlackTooltip title={<>Earning <b>{CELDA_PER_DAY.toFixed(2)} $CELDA/day</b> since {cellData.celda?.lastExtractDate ? "last harvest": "mint"} date ({new Date(cellData.celda?.lastExtractDate || cellData.mintedDate).toLocaleString()}).{(CELDA_ELEMENTAL || CELDA_GLOBAL_BOOST || EVOLUTIONS_BOOST_TEXT) && <><br /><br /></>}{CELDA_ELEMENTAL && <div><b><u>{CELDA_ELEMENTAL}</u></b></div>}{CELDA_GLOBAL_BOOST && <div><b><u>{CELDA_GLOBAL_BOOST}</u></b></div>}{EVOLUTIONS_BOOST_TEXT && <div><b><u>{EVOLUTIONS_BOOST_TEXT}</u></b></div>}</>}>
                            <Grid container justifyContent="space-between">
                              <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <span>PASSIVE <b>$CELDA</b> YIELD &nbsp;</span>
                                <InfoIcon fontSize="inherit" />
                              </Grid>
                              <Grid item>
                                <b>{passive}</b>
                              </Grid>
                            </Grid>
                          </BlackTooltip>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              TOTAL <b>$CELDA</b> EARNED
                            </Grid>
                            <Grid item>
                              <b>{((cellData.celda?.earned || 0) + Number(passive)).toFixed(2)}</b>
                            </Grid>
                          </Grid>
                        </Grid>                        
                      }                      
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>              
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      {userProfileOpen && <UserProfile setOpen={setUserProfileOpen} userData={userProfileOpen} /> }
    </div>
  );
})

export default CellInfo