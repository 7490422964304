import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import { Grow, IconButton } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import GlobalContext from './Context';
import Draggable from 'react-draggable';

//const CellsDialog = React.lazy(() => import('./CellsDialog')) 
import CellsDialog from './CellsDialog'
import BrowseDialog from './BrowseDialog'
import isURL from 'validator/lib/isURL';

//const opensea_url = "https://testnets.opensea.io/collection/cells" || process.env.REACT_APP_OPENSEA_URL
const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    borderRadius: 30
  },
  paper: {
    padding: "13px",
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    overflowX: "hidden",
    flexGrow: 1,
    borderRadius: 30
  },
  smallpaper: {
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: 150,
    minHeight: 160,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start",
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    position: "relative",
    transition: "all 300ms ease-in-out",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    zIndex: 0,
    overflow: "hidden",
    borderRadius: 30,
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: "50%",
    boxShadow: "0 0 5px black"
  },
  img: {
    margin: 'auto',
    display: 'flex',
    width: 400,
    height: 400,
    maxWidth: '100%',
    maxHeight: '100%',
    fontSize: 72,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#12ccc5"
  },
  EditIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    opacity: 0.7,
    padding: 5,
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: "50%"
  },
  empty: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  }, 
  cells: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    zIndex: 1
  }, 
  list: {
    position: "relative",
    overflow: "hidden",
    height: 220
  },
  backdrop: {
    position:"absolute",
    left:0,
    right:0,
    top:0,
    bottom:0,
    zIndex: -1,
    borderRadius: 5,
    background: "linear-gradient(70deg,rgba(0,0,0,0) 33%,rgba(255,255,255,0.2) 50%,rgba(0,0,0,0) 66%) rgba(0,0,0,0.5)",
    backgroundSize:"300% 100%",
  },
  backDrop2: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }
}));


const UserSettings = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {open, setOpen, map} = props;
  const [showIcon, setShowIcon] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editMail, setEditMail] = useState(false)
  const [editTwitter, setEditTwitter] = useState(false)
  const [editOpenSea, setEditOpenSea] = useState(false)
  const [editTelegram, setEditTelegram] = useState(false)
  //const [editDiscord, setEditDiscord] = useState(false)
  const [editURL, setEditURL] = useState(false)
  const [editDesc, setEditDesc] = useState(false)
  const [cellsDialog, setCellsDialog] = useState()
  const [browseFiles, setBrowseFiles] = useState()
  const {cells, } = context.cells
  const [userAddress, ] = context.userAddress
  const {userAccount, setUserAccount} = context.userAccount
  const [accessToken, ] = context.accessToken
  const [state, setState] = useState({
    username: userAccount?.username || "",    
    usernameDefault: userAccount?.username || "",
    email: userAccount?.email || "",
    emailDefault: userAccount?.email || "",
    desc: userAccount?.desc || "",
    descDefault: userAccount?.desc || "",
    avatarUrl: userAccount?.avatarUrl || "",
    twitter: userAccount?.twitter || "",
    twitterDefault: userAccount?.twitter || "",
    opensea: userAccount?.opensea || "",
    openseaDefault: userAccount?.opensea || "",    
    telegram: userAccount?.telegram || "",
    telegramDefault: userAccount?.telegram || "",    
    discord: userAccount?.discord || "",
    discordDefault: userAccount?.discord || "",    
    externalUrl: userAccount?.externalUrl || "",
    externalUrlDefault: userAccount?.externalUrl || "",  
  })
  const { refreshToken, snackbarShowMessage } = context
  const classes = useStyles();
  
  let ownCells = useMemo(() => (cells?.data?.filter(c => !!userAccount?.cells.find(x => x.celdaCod === c.celdaCod)) || []), [userAccount, cells?.data])
  let favoriteCells = useMemo(() => (cells?.data?.filter(c => !!userAccount?.favoriteCells.find(x => x.celdaCod === c.celdaCod)) || []), [userAccount, cells?.data])
  let visitedCells = useMemo(() => (cells?.data?.filter(c => !!userAccount?.visitedCells.find(x => x.celdaCod === c.celdaCod)) || []), [userAccount, cells?.data])

  const randomCell = useRef({
    own: ownCells.length > 0 && ownCells[Math.floor(Math.random() * (ownCells.length - 1))].celdaCod,
    fav: favoriteCells.length > 0 && favoriteCells[Math.floor(Math.random() * (favoriteCells.length - 1))].celdaCod,
    vis: visitedCells.length > 0 && visitedCells[Math.floor(Math.random() * (visitedCells.length - 1))].celdaCod
  })

  const [currCell, setCurrCell] = useState(randomCell.current)

  useEffect(() => {
    const r = randomCell.current
    if (ownCells.length > 0 && !r.own) r.own = ownCells[Math.floor(Math.random() * (ownCells.length - 1))].celdaCod
    if (ownCells.length === 0 && r.own) r.own = undefined
    if (favoriteCells.length > 0 && !r.fav) r.fav = favoriteCells[Math.floor(Math.random() * (favoriteCells.length - 1))].celdaCod
    if (favoriteCells.length === 0 && r.fav) r.fav = undefined
    if (visitedCells.length > 0 && !r.vis) r.vis = visitedCells[Math.floor(Math.random() * (visitedCells.length - 1))].celdaCod
    if (visitedCells.length === 0 && r.vis) r.vis = undefined
    setCurrCell(r)
  },[ownCells,favoriteCells,visitedCells,randomCell])

  const handleClose = () => {
    setOpen(false);
  };
  
  if (!userAccount) {
    handleClose()
    return null
  }  

  const handleChange = (e, field) => {
    setState({...state, [field]: e.target.value})
  }

  const handleChangeAvatar = (e) => {
    setBrowseFiles(true)
  }

  const selectFile = (url) => {
      updateUser("avatarUrl", url)
  }

  const handleEdit = (field, result) => {
    if (result === "ok") {
      if (field === "username" && state[field].length < 3) {
        snackbarShowMessage("Usernames must be at least 3 characters long", "error")
        return
      }
      if (field === "username" && state[field].length > 30) {
        snackbarShowMessage("Usernames must be no more than 30 characters long", "error")
        return
      }
      if (field !== "username" && field !== "email" && field !== "desc" && field !== "discord") {
        if (state[field] && !isURL(state[field])) {
          snackbarShowMessage("Invalid URL: " + state[field], "error")
          return
        }
      }      
      if (field === "twitter" && state[field] && (!state[field].startsWith("https://twitter.com/") || state[field] === "https://twitter.com/")) {
        snackbarShowMessage("Invalid Twitter URL: " + state[field], "error")
        return      
      }
      if (field === "telegram" && state[field] && (!state[field].startsWith("https://t.me/") || state[field] === "https://t.me/")) {
        snackbarShowMessage("Invalid Telegram URL: " + state[field], "error")
        return      
      }
      if (field === "opensea" && state[field] && (!state[field].startsWith("https://opensea.io/") || state[field] === "https://opensea.io/")) {
        snackbarShowMessage("Invalid OpenSea URL: " + state[field], "error")
        return      
      }
      if (state[field] !== state[`${field}Default`]) {
        updateUser(field)
        return
      }
    } else {
      setState({...state, [field]: state[`${field}Default`]})
    }
    handleEditClose(field)
  }

  const handleEditClose = (field) => {
    if (field === "username") {
      setEditName(false)
    } 
    if (field === "email") {
      setEditMail(false)
    }    
    if (field === "desc") {
      setEditDesc(false)
    }     
    if (field === "twitter") {
      setEditTwitter(false)
    } 
    if (field === "opensea") {
      setEditOpenSea(false)
    }    
    if (field === "telegram") {
      setEditTelegram(false)
    } 
    // if (field === "discord") {
    //   setEditDiscord(false)
    // }    
    if (field === "externalUrl") {
      setEditURL(false)
    }    
  }

  const updateUser = (field, value = undefined) => {
    refreshToken().then((ok) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
          body: JSON.stringify({data: {[field]: value ? value : state[field]}}),
        }
  
        let url = process.env.REACT_APP_API_URL + '/updateUser';
    
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (result) => { 
           if (result && !result.error) {
            setState({...state, [`${field}Default`]: value ? value : state[field], [field]: value ? value : state[field]})
            const newData = {...userAccount, [field]: value ? value : state[field]}
            setUserAccount(newData)
            handleEditClose(field)
            snackbarShowMessage("Info updated!")
           } else {
            snackbarShowMessage("ERROR: " + result.error, "error")
           }
          },
          (error) => {
            snackbarShowMessage("ERROR: " + error.toString(), "error")
          }
        ); 
      } catch (err) {
        snackbarShowMessage("ERROR: " + err.toString(), "error")
      }      
    },(err) => {if (process.env.REACT_APP_DEBUG) console.log("error",err)})
  }

    
  const openCells = (what,cells) => { 
    if (cells.length === 0) return;
    if (what === "own") {
      setCellsDialog({title: "YOUR CELLS", cells: cells})
    }
    if (what === "fav") {
      setCellsDialog({title: "FAVORITE CELLS", cells: cells})
    }    
    if (what === "vis") {
      setCellsDialog({title: "LAST VISITED CELLS", cells: cells})
    }        
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth={'md'}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, width: "100%", margin: 0, overflowX: "hidden"}}}
        BackdropProps={{
          classes: {
            root: classes.backDrop2,
          },
        }}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
        style={{visibility: !cellsDialog || !cellsDialog.cells ? 'visible' : 'hidden'}}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10, overflowX: "hidden"}}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          
            <Grid container direction="row" spacing={1} alignItems="stretch" justifyContent="center">
              <Grid item xs={12} md={8} style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                <Paper elevation={5} className={`${classes.smallpaper}`}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{position: "relative", padding: 10}}>
                      {/* <Tooltip title={`${userAccount.verifiedNFTs ? userAccount.verifiedNFTs.length : 0} Verified NFTs`}><div className="verified-badge profile" style={{marginTop: 10}}><span style={{fontSize: "1.2em"}}>{userAccount.verifiedNFTs ? userAccount.verifiedNFTs.length : 0}</span><img src="https://cells.land/img/verified.svg" alt="verified"/></div></Tooltip> */}
                      <Grid item style={{marginTop: 10}}>
                        <ButtonBase className={classes.image} onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} onClick={handleChangeAvatar}>
                            <Avatar className={classes.img} alt="" src={state.avatarUrl + "?img-width=300"} style={{fontFamily: "Roboto", backgroundColor: state.avatarUrl ? "transparent" : "#12ccc5"}}>
                              {state.username && state.username.slice(0,1).toUpperCase()}
                            </Avatar>
                            {showIcon && ( <EditIcon className={classes.EditIcon} fontSize={"large"}/> )}
                        </ButtonBase>
                      </Grid>
                      <Grid item container justifyContent="center" spacing={2}>
                        <Grid item container spacing={1} alignItems="center">
                          <Typography variant="caption" align="center" style={{margin: "auto", marginTop: 10, maxWidth: isMobile ? 200 : 400, color: "rgba(255,255,255,0.54)", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: isMobile ? "0.6em" : "0.75rem"}}>
                            {userAccount.address || userAddress || "0x123456..."}
                          </Typography>
                          <Grid item container>
                            <Grid item xs={12} style={{display: "flex", position: "relative"}}>
                              {!editName ? 
                              <Typography variant={isMobile ? "h6" : "h4"} style={{margin: "auto"}}>
                                <b>{state.username || "Username"}</b>
                              </Typography>
                              :
                              <Input 
                                value={state.username}
                                onChange={(e) => handleChange(e, "username")}
                                inputProps={{ 'aria-label': 'username', style:{textAlign: "center", fontWeight: "bold", padding: 0, lineHeight: isMobile ? 1.6 : 1.235, height: "auto", fontSize: isMobile ? "1.25rem" : "2.125rem", color: "white"} }}
                                fullWidth
                                autoFocus
                              />
                            }
                             <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", position: "absolute", right: 0, top: 10}}>
                                {!editName ? 
                                  <Tooltip title={`Edit`}>
                                    <IconButton size="small" onClick={() => setEditName(!editName)}>
                                      <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                    </IconButton>
                                  </Tooltip>
                                  :
                                  <React.Fragment>
                                    <IconButton size="small" onClick={() => handleEdit("username", "ok")}>
                                      <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleEdit("username", "cancel")}>
                                      <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                    </IconButton>                                                                
                                  </React.Fragment>
                                }
                              </div>
                            </Grid>
                           
                          </Grid>
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="Discord"><img src="https://cells.land/img/icons/discord.svg" alt="discord" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                <span style={{color: state.discord ? "#12ccc5" : "white"}}>{(state.discord && state.discord.username + '#' + state.discord.discriminator) || <a href={process.env.REACT_APP_DISCORD_URL} rel="noopener noreferrer" style={{color:"white"}}>Link your Discord account</a>}</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            </Grid>
                          </Grid>                          
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="E-Mail"><img src="https://cells.land/img/icons/email.svg" alt="email" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editMail ? 
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.email ? "#12ccc5" : "white"}}>{state.email || "E-Mail (not public)"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.email}
                                  onChange={(e) => handleChange(e, "email")}
                                  inputProps={{ 'aria-label': 'email', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editMail ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditMail(!editMail)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("email", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("email", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="Twitter"><img src="https://cells.land/img/icons/twitter.svg?v2" alt="twitter" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editTwitter ? 
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.twitter ? "#12ccc5" : "white"}}>{state.twitter || "Twitter"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.twitter || "https://twitter.com/"}
                                  onChange={(e) => handleChange(e, "twitter")}
                                  inputProps={{ 'aria-label': 'twitter', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editTwitter ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditTwitter(!editTwitter)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("twitter", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("twitter", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="Telegram"><img src="https://cells.land/img/icons/telegram.svg" alt="telegram" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editTelegram ? 
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.telegram ? "#12ccc5" : "white"}}>{state.telegram || "Telegram"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.telegram || "https://t.me/"}
                                  onChange={(e) => handleChange(e, "telegram")}
                                  inputProps={{ 'aria-label': 'telegram', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editTelegram ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditTelegram(!editTelegram)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("telegram", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("telegram", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="OpenSea"><img src="https://cells.land/img/icons/opensea.svg" alt="opensea" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editOpenSea ? 
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.opensea ? "#12ccc5" : "white"}}>{state.opensea || "OpenSea"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.opensea || "https://opensea.io/"}
                                  onChange={(e) => handleChange(e, "opensea")}
                                  inputProps={{ 'aria-label': 'OpenSea', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editOpenSea ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditOpenSea(!editOpenSea)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("opensea", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("opensea", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>                               
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                              <Tooltip title="External URL"><img src="https://cells.land/img/icons/externalurl.svg" alt="externalurl" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editURL ? 
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.externalUrl ? "#12ccc5" : "white"}}>{state.externalUrl || "External URL"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.externalUrl || "https://"}
                                  onChange={(e) => handleChange(e, "externalUrl")}
                                  inputProps={{ 'aria-label': 'externalUrl', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editURL ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditURL(!editURL)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("externalUrl", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("externalUrl", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>                          
                          <Grid item container>
                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                             <Tooltip title="Description"><img src="https://cells.land/img/icons/profile-user.svg" alt="profile" style={{width: 24, height: 24, paddingRight: 10}}/></Tooltip>
                              {!editDesc ?                               
                                <Typography variant="body2" style={{maxWidth: isMobile ? 280 : 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                  <span style={{color: state.desc ? "#12ccc5" : "white"}}>{state.desc || "Something about you!"}</span>
                                </Typography>
                              :
                                <Input 
                                  value={state.desc}
                                  onChange={(e) => handleChange(e, "desc")}
                                  inputProps={{ 'aria-label': 'desc', style:{letterSpacing:"1px", padding: 0, fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.75, height: "auto", color: "white"} }}
                                  fullWidth
                                  autoFocus
                                />
                            }
                            </Grid>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {!editDesc ? 
                              <Tooltip title={`Edit`}>
                                <IconButton size="small" onClick={() => setEditDesc(!editDesc)}>
                                  <EditIcon fontSize={"small"} htmlColor="rgba(255,255,255,0.3)" />
                                </IconButton>
                              </Tooltip>
                              :
                              <React.Fragment>
                                <IconButton size="small" onClick={() => handleEdit("desc", "ok")}>
                                  <CheckIcon fontSize={"small"} htmlColor="rgba(0,200,0,0.8)"/>
                                </IconButton>
                                <IconButton size="small" onClick={() => handleEdit("desc", "cancel")}>
                                  <CancelIcon fontSize={"small"} htmlColor="rgba(200,0,0,0.8)"/>
                                </IconButton>                                                                
                              </React.Fragment>
                            }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} style={{flexGrow: 1}}>
                <Grid container direction="column" spacing={1} style={{flexGrow:1, alignItems: "stretch", justifyContent: "center"}}>
                  <Grid item style={{flexGrow: 1}} xs={12}>
                    <Paper elevation={5} className={`${classes.smallpaper}`} style={{backgroundImage: (currCell.own && `url(https://cells.land/images/400/cell/${currCell.own}.jpg)`), cursor: "pointer"}} onClick={() => openCells("own", ownCells)}>
                      {userAccount.cells && userAccount.cells.length > 0 && <div className={classes.backdrop}></div>}
                      <div className={classes.cells}>
                        <Typography variant="h6" align="center" style={{marginTop: 10}}>
                          <b>YOUR CELLS</b>
                        </Typography>                        
                        <Typography variant="h2" align="center" style={{color:"#12ccc5"}}>
                          <b>{userAccount.cells.length}</b>
                        </Typography>
                        <Button style={{color: "white", textDecoration:"underline"}}>VIEW ALL</Button>
                      </div>
                    </Paper>               
                  </Grid>
                  <Grid item style={{flexGrow: 1}} xs={12}>
                    <Paper elevation={5} className={`${classes.smallpaper}`} style={{backgroundImage: (currCell.fav && `url(https://cells.land/images/400/cell/${currCell.fav}.jpg)`), cursor: "pointer"}} onClick={() => openCells("fav", favoriteCells)}>                  
                      {userAccount.favoriteCount > 0 && <div className={classes.backdrop}></div>}
                      <div className={classes.cells}>
                        <Typography variant="h6" align="center" style={{marginTop: 10}}>
                          <b>FAVORITE</b>
                        </Typography>                        
                        <Typography variant="h2" align="center" style={{color:"#12ccc5"}}>
                          <b>{userAccount.favoriteCount}</b>
                        </Typography>
                        {userAccount.favoriteCount > 0 ? <Button style={{color: "white", textDecoration:"underline"}}>VIEW ALL</Button> : <span style={{padding: "6px 8px"}}>Favorite by clicking on the ⭐ icon</span>}
                      </div>
                    </Paper>  
                  </Grid>
                  <Grid item style={{flexGrow: 1}} xs={12}>
                    <Paper elevation={5} className={`${classes.smallpaper}`} style={{backgroundImage: (currCell.vis && `url(https://cells.land/images/400/cell/${currCell.vis}.jpg)`), cursor: "pointer"}} onClick={() => openCells("vis", visitedCells)}>                  
                      {userAccount.visitedCount > 0 && <div className={classes.backdrop}></div>}
                      <div className={classes.cells}>
                        <Typography variant="h6" align="center" style={{marginTop: 10}}>
                          <b>LAST VISITED</b>
                        </Typography>                        
                        <Typography variant="h2" align="center" style={{color:"#12ccc5"}}>
                          <b>{userAccount.visitedCount}</b>
                        </Typography>
                        {userAccount.visitedCount > 0 ? <Button style={{color: "white", textDecoration:"underline"}}>VIEW ALL</Button> : <span style={{padding: "6px 8px"}}>Go visit your neighbors!</span>}
                      </div>
                    </Paper>  
                  </Grid>
                </Grid>                
              </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
      { browseFiles && <BrowseDialog selectFile={selectFile} setBrowseFiles={setBrowseFiles} browseFiles={browseFiles} />  }
      {cellsDialog && cellsDialog.cells && <CellsDialog title={cellsDialog.title} cells={cellsDialog.cells} setCells={setCellsDialog} map={map} setUserSettings={setOpen} />}
    </div>
  );
})

export default UserSettings