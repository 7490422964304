 import React, { useState, useEffect } from "react";
 // import styled from 'styled-components';
 import { Paper, Typography, IconButton, makeStyles, withStyles } from "@material-ui/core";
 import { useMapEvents } from "react-leaflet";
 import { Util } from "leaflet";
 import MuiAccordion from '@material-ui/core/Accordion';
 import FormControlLabel from "@material-ui/core/FormControlLabel";
 import Checkbox from "@material-ui/core/Checkbox";
 import Radio from "@material-ui/core/Radio";
 import AccordionSummary from "@material-ui/core/AccordionSummary";
 import AccordionDetails from "@material-ui/core/AccordionDetails";
 import FormControl from "@material-ui/core/FormControl";
 import InputLabel from "@material-ui/core/InputLabel";
 import Select from "@material-ui/core/Select";
 import Badge from "@material-ui/core/Badge";
 import MenuItem from "@material-ui/core/MenuItem";
 import ListItemText from "@material-ui/core/ListItemText";
 import ListItemIcon from "@material-ui/core/ListItemIcon";
 import ListItemAvatar from "@material-ui/core/ListItemAvatar";
 import Avatar from "@material-ui/core/Avatar";
 import Grid from "@material-ui/core/Grid";
 import TextField from "@material-ui/core/TextField";
 import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
 import CloseIcon from "@material-ui/icons/Close";
 import CheckIcon from "@material-ui/icons/Check";
 import MenuIcon from "@material-ui/icons/Menu";
 import lodashGroupBy from "lodash.groupby";
 import { LayersControlProvider } from "./layerControlContext";
 import createControlledLayer from "./controlledLayer";
 import { cellStats, zones } from './Utils';
 import { isValidAddress } from 'ethereumjs-util'
 
 
 // Classes used by Leaflet to position controls
 const POSITION_CLASSES = {
   bottomleft: "leaflet-bottom leaflet-left",
   bottomright: "leaflet-bottom leaflet-right",
   topleft: "leaflet-top leaflet-left",
   topright: "leaflet-top leaflet-right"
 };

 const useStyles = makeStyles((theme) => ({
	container: {	
		color: 'white',
		background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
		borderRadius: "10px",
	},
	section: {	
		color: 'white',
		background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
		"&:hover": {
			background: "radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11));",
		}
	},	
	details: {
		padding: 5
	},
	input: {
	  color: "white !important",
	  borderColor: "white !important"
	},
	select: {
	  color: "#ccc !important",
	  fontSize: "0.9em",
	  "& label": {
		color: "#ccc !important"
	  },
	  "& .MuiOutlinedInput-notchedOutline": {
		color: "#ccc !important",
		borderColor: "#ccc !important"
	  }
	},
	menuButton: {
		'&:hover': {
			background: "radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11))",
		},	  
	}
}))

const NewCheckbox = withStyles({
	root: {
	  color: "#12ccc5",
	  '&$checked': {
		color: "#12ccc5",
	  },
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NewRadio = withStyles({
	root: {
		color: "#12ccc5",
		'&$checked': {
		color: "#12ccc5",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);  

const Accordion = withStyles({
	root: {
	  border: '1px solid rgba(0, 0, 0, .125)',
	  '&:not(:last-child)': {
		borderBottom: 0,
	  },
	  '&:before': {
		display: 'none',
	  },
	  '&$expanded': {
		margin: 'auto',
	  },
	  '&:last-child': {
		  borderBottomLeftRadius: "10px",
		  borderBottomRightRadius: "10px",
	  },
	  '&:first-child': {
		  borderTopLeftRadius: "10px",
		  borderTopRightRadius: "10px"
	  }
	},
	expanded: {},
  })(MuiAccordion);
 
  const StyledBadge = withStyles((theme) => ({
	badge: {
		position: "absolute",
		top: 10,
	  	right: 5, 
		fontFamily: "Roboto"
	},
  }))(Badge);

 function LayerControl({ position, filters, setFilters, showBorders, setShowBorders, userAccount, currentStats, children }) {
   const classes = useStyles();
   const [collapsed, setCollapsed] = useState(true);
   const [expanded, setExpanded] = useState(false);
   const [layers, setLayers] = useState([]);
   const [state, setState] = useState({onlymycells: false, borders: showBorders, onlywithcontent: false, onlyevolved: false,...filters})
   const [error, setError] = useState({})
   const positionClass =
	 (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
 
   const map = useMapEvents({
	 layerremove: () => {
	   // if (process.env.REACT_APP_DEBUG) console.log("layer removed");
	 },
	 layeradd: (e) => {
	   if (e.layer.options.name && e.layer.options.base === 1) {
			localStorage.mapStyle = e.layer.options.name
	   }
	 },
	 overlayadd: (layer, extra) => {
	   // if (process.env.REACT_APP_DEBUG) console.log(layer, extra);
	 }
   });
 
   const onLayerClick = layerObj => {
	 if (map?.hasLayer(layerObj.layer)) {
	   map.removeLayer(layerObj.layer);
	   setLayers(
		 layers.map(layer => {
		   if (layer.id === layerObj.id)
			 return {
			   ...layer,
			   checked: false
			 };
		   return layer;
		 })
	   );
	 } else {
	   map.addLayer(layerObj.layer);
	   setLayers(
		 layers.map(layer => {
		   if (layer.id === layerObj.id)
			 return {
			   ...layer,
			   checked: true
			 };
		   return layer;
		 })
	   );
	 }
   };

   const onLayerToggle = layerObj => {
	if (map?.hasLayer(layerObj.layer)) {
		 return null;
	} else {
		map.eachLayer((layer) => {
			if (layer.options && layer.options.tileSize > 0) map.removeLayer(layer)
		})
		map.addLayer(layerObj.layer);
	  	setLayers(
			layers.map(layer => {
			if (layer.id === layerObj.id) {
				return {
					...layer,
					checked: true
				};
			} else {
				if (layer.group === "MAP STYLES") {
					return {
						...layer,
						checked: false
					};	
				}
				return layer;
			}
		})
	  );
	}
  };   
 
   const onGroupAdd = (layer, name, group) => {
	 setLayers(_layers => [
	   ..._layers,
	   {
		 layer,
		 group,
		 name,
		 checked: map?.hasLayer(layer),
		 id: Util.stamp(layer)
	   }
	 ]);
   };
 
   const groupedLayers = lodashGroupBy(layers, "group");
 
	const handleState = (filter) => (event, result) => {
		let s = result;
		if (filter === "country" || filter === "zone") s = result.props.value
		if (filter === "celdaCod" || filter === "tokenId" || filter === "userAddress" || filter === "userName") s = event.target.value
		if ((filter === "celdaCod" || filter === "tokenId") && s.length > 5) return
		if (filter === "tokenId" && s && (s < 1 || s > 29886)) return
		let newState = {[filter]: s}
		
		if (filter === "onlymycells") {
			setFilters({...filters, onlymycells: s})
		}
		if (filter === "onlywithcontent") {
			setFilters({...filters, onlywithcontent: s})
		}
		if (filter === "onlyevolved") {
			setFilters({...filters, onlyevolved: s})
		}			
		if (filter === "borders") {
			setShowBorders(s)
		}		
		setState({...newState})
		if (error) setError({})
	}

	const handleFilters = (s) => {
		if (s.celdaCod && s.celdaCod.length !== 5) {
			setError({field: "celdaCod", message: "Invalid Cell Code"})
			return
		}
		if (s.tokenId && (s.tokenId < 1 || s.tokenId > 29886)) {
			setError({field: "tokenId", message: "Invalid Token ID"})
			return
		}
		if (s.userAddress && !isValidAddress(s.userAddress)) {
			setError({field: "userAddress", message: "Invalid Ethereum Address"})
			return
		}
		setFilters({...s})
		setCollapsed(true)
		map.dragging.enable()
	}

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	}

	const handleOpen = (isOpen) => {
		if (!isOpen) {
			setCollapsed(false)
			map.dragging.disable()
		} else {
			setCollapsed(true)
			map.dragging.enable()
		}
	}

	const handleKeyPress = (e) => {
		if(e && e.charCode === 13) {
			handleFilters(state)
		 }
	}
	
	useEffect(() => {
		setState({...filters})
	},[filters])

	let searchBadge = filters.celdaCod || filters.tokenId ? 1 : 0
	searchBadge += filters.country ? 1 : 0
	searchBadge += filters.userAddress ? 1 : 0
	searchBadge += filters.zone ? 1 : 0
	let filterBadge = filters.onlymycells ? 1 : 0
	filterBadge += filters.onlywithcontent ? 1 : 0
	filterBadge += filters.onlyevolved ? 1 : 0
   
   // if (process.env.REACT_APP_DEBUG) console.log(groupedLayers, "groupedLayers");
   return (
	 <LayersControlProvider
	   value={{
		 layers,
		 addGroup: onGroupAdd
	   }}
	 >
	   <div className={`${positionClass} leaflet-bottomleft-menu animate__animated animate__slideInLeft animate__faster`}>
		 <div className="leaflet-control leaflet-bar">
		   {
			 <Paper
			   onMouseLeave={() => handleOpen(true)}
			   className={classes.container}
			 >
			   {collapsed && (
				 <IconButton onClick={() => handleOpen(false)} style={{border: "1px solid #12ccc5", borderRadius: 10}} className={classes.menuButton}>
					<StyledBadge badgeContent={filterBadge + searchBadge} color="secondary">
						<MenuIcon fontSize="large" htmlColor="#12ccc5" />
					</StyledBadge>
				 </IconButton>
			   )}
			   {!collapsed &&
			   <div className={classes.container} style={{borderRadius: "50px", position: "relative"}} onClick={e => e.stopPropagation()}>
					{Object.keys(groupedLayers).map((section, index) => {
						if (section !== "MAP STYLES") return null
						return (
							<Accordion key={`${section} ${index}`} className={classes.section} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon htmlColor="#12ccc5" />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
							<Typography variant="button" style={{display: 'flex', alignItems: "center"}}>
								{section === "MAP STYLES" ? <span className="emoji" style={{paddingRight: 10}}>🌍</span> : <span className="emoji" style={{paddingRight: 10}}>🗺</span>}
								{section}
							</Typography>

							</AccordionSummary>
							{groupedLayers[section]?.map(layerObj => (
							<AccordionDetails key={`${section} ${index} ${layerObj.name}`} style={{paddingTop: 0, paddingBottom: 0}}>
								<FormControlLabel
								control={
										section !== "MAP STYLES" ?
									<NewCheckbox
										checked={layerObj.checked}
										onChange={() => onLayerClick(layerObj)}
										name="checkedB"
										color="primary"
									/>
									:
									<NewRadio
										checked={layerObj.checked}
										onChange={() => onLayerToggle(layerObj)}
										name="checkedC"
										color="primary"
									/>
								}
								label={layerObj.name.toUpperCase()}
								style={{fontSize: "1.2em"}}
								/>
							</AccordionDetails>
							))}
						</Accordion>
						)
					})}	 				   
					<Accordion className={classes.section} expanded={expanded === `search`} onChange={handleChange(`search`)}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon htmlColor="#12ccc5" />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							<Typography variant="button" style={{display: 'flex', alignItems: "center"}}>
								<span className="emoji" style={{paddingRight: 10}}>🔎</span>
								SEARCH
							</Typography>
							<StyledBadge style={{paddingLeft: 20}} badgeContent={searchBadge} color="secondary">&nbsp;</StyledBadge>
						</AccordionSummary>					 
						<AccordionDetails style={{paddingTop: 0, paddingBottom: 10, display: "flex", flexDirection: "column"}}>
							<TextField
								margin="dense"
								id="celdaCod"
								label="Cell S2 Code"
								type="text"
								fullWidth
								variant="outlined"
								color="primary"
								value={state?.celdaCod || ""}
								onChange={handleState("celdaCod")}
								inputProps={{style:{color: "#ccc", borderColor: "#ccc"}}}
								style={{color: "#ccc", borderColor: "#ccc"}}
								className={classes.select}
								error={error.field === "celdaCod"}
								helperText={error.field === "celdaCod" ? (error.message) : undefined}
								onKeyPress={handleKeyPress}
							/>
							<TextField
								margin="dense"
								id="tokenId"
								label="NFT Token ID"
								type="number"
								fullWidth
								variant="outlined"
								color="primary"
								value={state?.tokenId || ""}
								onChange={handleState("tokenId")}
								inputProps={{style:{color: "#ccc", borderColor: "#ccc"}}}
								style={{color: "#ccc", borderColor: "#ccc"}}
								className={classes.select}
								error={error.field === "tokenId"}
								helperText={error.field === "tokenId" ? (error.message) : undefined}
								onKeyPress={handleKeyPress}
							/>
							<Grid item>
								<TextField
									margin="dense"
									id="userAddress"
									label="Ethereum Address"
									type="text"
									fullWidth
									variant="outlined"
									color="primary"
									value={state?.userAddress || ""}
									onChange={handleState("userAddress")}
									inputProps={{style:{color: "#ccc", borderColor: "#ccc"}}}
									style={{color: "#ccc", borderColor: "#ccc"}}
									className={classes.select}
									error={error.field === "userAddress"}
									helperText={error.field === "userAddress" ? (error.message) : undefined}
									onKeyPress={handleKeyPress}
								/>
							</Grid>									
							<Grid item>
								<FormControl variant="outlined" margin="dense" fullWidth className={classes.select} size="small">
									<InputLabel id="country-label" color="primary">Country</InputLabel>
									<Select 
										label="Country"
										id="country"
										value={state?.country || ''}
										onChange={handleState("country")}
										SelectDisplayProps={{style:{display: "flex", alignItems: "center", color: "white", borderColor: "white"}}}
										color="primary"
										style={{color: "white"}}
										inputProps={{style:{color: "white", borderColor: "white"}}}
									>
									<MenuItem value="">
										<ListItemIcon style={{display: "flex", alignItems: "center", justifyContent: "center", minWidth: 24, margin: "0 10px"}}>
											&nbsp;
										</ListItemIcon>
										<ListItemText>
											<i>None</i>
										</ListItemText>
									</MenuItem>										
									{
										!!currentStats && currentStats.mintedByCountry && Object.keys(currentStats.mintedByCountry).sort().map((c) => (
											<MenuItem key={`country-${c}`} value={c} dense disableGutters >
												<ListItemIcon style={{display: "flex", alignItems: "center", justifyContent: "center", minWidth: 24, margin: "0 10px"}}>
													<img alt="" src={`https://cells.land/img/flags-iso/shiny/24/${cellStats.cellsByCountry[c].code}.png`} width={24} height={24} />
												</ListItemIcon>
												<ListItemText>
													{c} ({currentStats.mintedByCountry[c].count}/{cellStats.cellsByCountry[c].count})
												</ListItemText>
											</MenuItem>
										))
									}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl variant="outlined" margin="dense" fullWidth className={classes.select} size="small">
									<InputLabel id="zone-label" color="primary">Zone</InputLabel>
									<Select 
										label="Zone"
										id="zone"
										value={state?.zone || ''}
										onChange={handleState("zone")}
										SelectDisplayProps={{style:{display: "flex", alignItems: "center", color: "white", borderColor: "white"}}}
										color="primary"
										style={{color: "white"}}
										inputProps={{style:{color: "white", borderColor: "white"}}}
										//endAdornment={<CloseIcon htmlColor="white" />}
									>
									<MenuItem value="">
										<ListItemIcon style={{display: "flex", alignItems: "center", justifyContent: "center", minWidth: 48, margin: "0 10px"}}>
											&nbsp;
										</ListItemIcon>
										<ListItemText>
											<i>None</i>
										</ListItemText>
									</MenuItem>										
									{
										zones.map((c) => c.type === "collection" && (
											<MenuItem key={`zone-${c.name}`} value={c.shortName} dense disableGutters >
												<ListItemAvatar style={{ margin: "0 10px"}}>
													<Avatar variant="circle">
														<img alt={c.name} src={`https://cells.land/img/zones/${c.logo}`} width={40} height={40} />
													</Avatar>
												</ListItemAvatar>
												<ListItemText>
													{c.name}
												</ListItemText>
											</MenuItem>
										))
									}
									</Select>
								</FormControl>
							</Grid>					
							<Grid item container spacing={1} justifyContent="space-around" style={{paddingTop: 10}}>
								<IconButton color="secondary" onClick={() => handleFilters(state)} size="small">
									<CheckIcon htmlColor="white" />
								</IconButton>
								{state && (state.tokenId || state.celdaCod || state.country || state.userAddress || state.zone) && 
									<IconButton color="secondary" onClick={() => { setFilters({}); setState({}) }} size="small">
										<CloseIcon htmlColor="white" />
									</IconButton>
								}
							</Grid>							
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.section} expanded={expanded === `filters`} onChange={handleChange(`filters`)}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon htmlColor="#12ccc5" />}
						aria-controls="panel3a-content"
						id="panel3a-header"
						style={{position: "relative"}}
						>
						<Typography variant="button" style={{display: 'flex', alignItems: "center"}}>
								<span className="emoji" style={{paddingRight: 10}}>🗺</span>
								MAP CONTROLS
							</Typography>
							<StyledBadge style={{paddingLeft: 20}} badgeContent={filterBadge} color="secondary">&nbsp;</StyledBadge>
						</AccordionSummary>					 
						<AccordionDetails style={{paddingTop: 0, paddingBottom: 0}}>
							<Grid container spacing={1} style={{flexDirection: "column", paddingBottom: 10, justifyContent:"flex-start", fontSize: "1.2em"}} alignItems="stretch">
								<Grid item container direction="column" alignItems="flex-start" justifyContent="center" spacing={1}>
									{groupedLayers["CELLS"]?.map(layerObj => (
										<FormControlLabel
											key={layerObj.name}
											control={<NewCheckbox
												checked={layerObj.checked}
												onChange={() => onLayerClick(layerObj)}
												name="checkedC"
												color="primary"
												/>}
											label={layerObj.name.toUpperCase()}
										/>
									))}
								</Grid>
								<Grid item container justifyContent="flex-start" spacing={1}>
									<FormControlLabel
										control={<NewCheckbox
											checked={showBorders}
											onChange={handleState("borders")}
											name="checkedE"
											color="primary"
											/>}
										label={"CELL BORDERS"}
									/>
								</Grid>		
								<hr style={{opacity:1, width: "100%"}}/>
								<Grid item container justifyContent="flex-start" spacing={1}>
									<FormControlLabel
										control={<NewCheckbox
											checked={state?.onlywithcontent || false}
											onChange={handleState("onlywithcontent")}
											name="checkedD"
											color="primary"
											/>}
										label={"ONLY CELLS WITH CONTENT"}
									/>
								</Grid>
								<Grid item container justifyContent="flex-start" spacing={1}>
									<FormControlLabel
										control={<NewCheckbox
											checked={state?.onlyevolved || false}
											onChange={handleState("onlyevolved")}
											name="checkedD"
											color="primary"
											/>}
										label={"ONLY EVOLVED CELLS"}
									/>
								</Grid>
								{!!userAccount && userAccount.tokenBalance > 0 &&
									<Grid item container justifyContent="flex-start" spacing={1}>
										<FormControlLabel
											control={<NewCheckbox
												checked={state?.onlymycells || false}
												onChange={handleState("onlymycells")}
												name="checkedD"
												color="primary"
												/>}
											label={"ONLY MY CELLS"}
										/>
									</Grid>
								}			
							</Grid>							
						</AccordionDetails>
					</Accordion>
				</div>
				}
			 </Paper>
		   }
		 </div>
		 {children}
	   </div>
	 </LayersControlProvider>
   );
 }
 
 const GroupedLayer = createControlledLayer(function addGroup(
   layersControl,
   layer,
   name,
   group
 ) {
   layersControl.addGroup(layer, name, group);
 });
 
 export default LayerControl;
 export { GroupedLayer };
 