import React, { useContext, useRef } from 'react';
import L from 'leaflet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Grow } from '@material-ui/core';
import GlobalContext from './Context';
import Draggable from 'react-draggable';
import { green } from '@material-ui/core/colors';
import { CellsLogoGrad } from './Utils'

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-upload" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30,
    '& .MuiInputBase-root': {
      color: 'white',
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(33, 33, 33, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflowX: 'hidden',
    minHeight: 400,
    display: 'flex'
  },
  img: {
    height: 80,
    padding: 10,
    cursor: "pointer"
  },
  input: {
    color: "white !important",
    borderColor: "white !important"
  },
  textfield: {
    "& label": {
      color: "white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    }
  },
  green: {
    color: "white",
    backgroundColor: green['500'],
    '&:hover': {
      backgroundColor: green['900'],
    },
  },
}));




const UploadDialog = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {userAccount} = context.userAccount
  const classes = useStyles();
  const fileUpload = useRef()
  const { uploadFileToIPFS } = context
  const { browseFiles, setBrowseFiles, upload, setUpload } = props

  const handleClose = (action) => {
    setUpload(false)
  };

  const handleClick = async (action) => {
    if (action === "upload") {
      fileUpload.current.click()
    }
    if (action === "pinata") {
      window.open("https://pinata.cloud","_blank")
    }
  }

  const handleFileUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileData = await uploadFileToIPFS(file)
      if (!browseFiles) setBrowseFiles(true)
      handleClose()
      if (process.env.REACT_APP_DEBUG) console.log("fileData", fileData)
    }
  }


  return (
    <div>
      <Dialog
        open={upload && !!userAccount}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-upload"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={2} justifyContent="center" alignItems="stretch" direction="row" style={{flexGrow: 1}}>
                <Grid item sm={6} style={{display: "flex", justifyContent:"center", alignItems: "center", flexGrow: 1, flexDirection: "column"}}>
                  {/* <model-viewer src="https://cells.land/gltf/cells_new5.glb" style={{flexGrow: 1, marginTop: -10, padding: 10}} poster="https://cells.land/img/poster.png" interaction-prompt="none" disable-zoom camera-controls auto-rotate shadow-softness="1" shadow-intensity="0" loading="eager" exposure=".5" auto-rotate-delay="500" rotation-per-second="300%"></model-viewer> */}
                  <CellsLogoGrad fontSize="large" style={{fontSize: isMobile ? "8em" : "15em"}} />
                </Grid>              
                <Grid item container sm={6} style={{justifyContent:"space-between", alignItems: "center", flexDirection: "column", padding: 10}}>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", margin: 10, flexGrow: 1}}>
                    <Typography variant={"h6"} style={{margin: 10, textAlign: "center", wordBreak: "break-word", color: "#12ccc5"}}>
                      Upload media files to IPFS and use them in your Cells and Playgrounds!<br /><span style={{fontSize: "0.8em", opacity: .8, color: "rgba(255,255,255,0.8)"}}>Max file size is 100MB</span>
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", margin: 10, flexGrow: 1, width: "90%"}}>                  
                    <Button variant="contained" color="secondary" size="large" className={classes.green} startIcon={<UploadIcon fontSize="large" />} onClick={() => handleClick("upload")}>UPLOAD TO IPFS</Button>
                    <Typography variant={"body1"} style={{margin: 10, textAlign: "center", wordBreak: "break-word", color: "#12ccc5"}}>
                      <span style={{fontSize: "0.8em", opacity: .8, color: "rgba(255,255,255,0.8)"}}>Remember IPFS is a public network. Anyone has access to whatever you upload.</span>
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", margin: 10}}>
                    <img src="https://cells.land/img/Pinata-FullLogo.svg" className={classes.img} alt="Pinata" onClick={() => handleClick("pinata")}/>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          <input ref={fileUpload} onChange={handleFileUpload} type="file" hidden accept="image/jpeg,image/gif,image/webp,image/png,image/svg+xml,video/mp4,video/webm,.glb,.gltf"/>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default UploadDialog