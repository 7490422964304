import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import GlobalContext from "./Context";
import { ButtonBase, Grid, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SortIcon from '@material-ui/icons/Sort';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import RightArrowIcon from '@material-ui/icons/ArrowRight';
import LeftArrowIcon from '@material-ui/icons/ArrowLeft';
//import { AwesomeQRCode } from "@awesomeqr/react";
import { cellStats, sortByKey, zones } from './Utils';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BattlesIcon } from "./img/battles.svg";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawer: {
    width: "100%",
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center'
  },
  drawerPaper: {
    width: "100%",
    background: "rgba(0,0,0,0.8)",
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
    width: "100%",
    height: 40
  },
  papercard: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    padding: 10,
    "&:hover": {
      //background: "radial-gradient(at 0% 100%, rgba(123, 22, 255, .8), rgb(15, 1, 94))",
      border: "1px solid #12ccc5"
    }
  },
  selectedcard: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    padding: 10,
    animation: "shadow 3s linear infinite",
    "&:hover": {
      //background: "radial-gradient(at 0% 100%, rgba(123, 22, 255, .8), rgb(15, 1, 94))",
      border: "1px solid #12ccc5"
    }
  },
  showing: {
    color: "#12ccc5", 
    margin: "0px 10px",
    padding: "3px 10px",
    display: "flex",
    alignItems: "center"
  },
  img: {
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: 300,
    cursor: "pointer",
    borderRadius: 5
  },
  zoneimg: {
    margin: 5,
    marginBottom: 10,
    maxWidth: "100%",
    maxHeight: 50,
  },
  buttonContainer: {
    height: 50,
    width: 50,
    position: "absolute",
    right: 5,
    top: 75,
    zIndex: 1000,
    background: "rgba(0,0,0,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      background: "rgba(0,0,0,1)",
    }
  }
}));


export default function BottomDrawer(props) {
  const context = useContext(GlobalContext)
  const classes = useStyles();
  //const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { filters, setFilters, cells, map } = props
  const [items, setItems] = useState()
  const { selectedCell } = context
  const { userAccount, } = context.userAccount
  const [showing, setShowing] = useState()
  const [sortDir, setSortDir] = useState(filters && filters.onlymycells ? -1 : 1)
  const [sortField, setSortField] = useState("tokenId")
  const listRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState()
  
  const handleSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback((cell, index) => {
    if (map) map.fitBounds([cell.v1,cell.v2,cell.v3,cell.v4], {padding: [300, 300]})
    //listRef.current.scrollToItem(index, "center");
    
    //setSelected(cell.celdaCod)
    //setOpen(true);
  },[map]);

  const handleDrawerClose = () => {
    setFilters({})
    setOpen(false);
  };

  const handleSortDir = () => {
    setSortDir(sortDir * -1)
  };

  const handleSortField = (field) => {
    if (process.env.REACT_APP_DEBUG) console.log("sortField",sortField)
    setSortField(field)
    handleClose()
    if (["evolutionsBoost","cellContentLastUpdate","rarity","contentCount"].indexOf(field) !== -1) 
      setSortDir(-1)
    else
      setSortDir(1)
  };

  const handleScroll = (direction) => {
    const offset = listRef.current.state.scrollOffset
    const width = listRef.current.props.width
    if (direction === 'left') { 
      offset > 0 && listRef.current.scrollTo(offset - width, 0)
    } else {
      //if (process.env.REACT_APP_DEBUG) console.log(listRef.current)
      if (offset > (listRef.current.props.itemCount * listRef.current.props.itemSize) - width) return
      else listRef.current.scrollTo(offset + width, 0)
    }
  };


  useEffect(() => {
    let celdas = cells.data
    let zone = filters.zone && zones.find(c => c.shortName.toLowerCase() === filters.zone.toLowerCase())
    celdas = celdas.map(c => {
      c.evolutionsBoost = c.evolutions?.boost || 0
      return c
    })
    if (celdas && !selectedCell && filters && Object.keys(filters).length > 0) {
      if (filters.onlymycells) {
        celdas = [...celdas.filter(c => c.owner && c.owner.address.toLowerCase() === (userAccount && userAccount.address.toLowerCase()))]
        if (celdas[0]) setShowing(<span>Showing your <b>{celdas.length}</b> Cells</span>)
      }
      if (filters.onlywithcontent) {
        celdas = [...celdas.filter(c => c.cellContentCount > 0)]
        if (celdas[0]) setShowing(<span>Showing <b>{celdas.length}</b> Cells with content</span>)
      }
      if (filters.onlyevolved) {
        celdas = [...celdas.filter(c => c.evolutions && c.evolutions.count > 0)]
        if (celdas[0]) setShowing(<span>Showing <b>{celdas.length}</b> Evolved Cells</span>)
      }      
      if (filters.country) {
        celdas = [...celdas.filter(c => c.country.indexOf(filters.country) !== -1)]
        if (celdas[0]) setShowing(<span style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: "0 10px"}}>Showing <b style={{margin: "0 7px"}}>{celdas && celdas.length}</b> Cells from<img alt="" src={"https://cells.land/img/flags-iso/shiny/32/" + cellStats.cellsByCountry[filters.country].code + ".png"} style={{padding: 0, margin: "0 10px"}} width={32} height={32} /> <b>{filters.country}</b></span>)
      }
      if (filters.zone) {
        celdas = [...celdas.filter(c => c.zone && c.zone.toLowerCase() === filters.zone.toLowerCase())]
        if (celdas[0]) setShowing(<span style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: "0 10px"}}>Showing <b style={{margin: "0 7px"}}>{celdas && celdas.length}</b> Cells from the <b style={{margin: "0 7px"}} className="rainbowtext">{zone.name}</b></span>)
      }      
      if (filters.userAddress) {
        celdas = [...celdas.filter(c => c.owner && c.owner.address.toLowerCase() === filters.userAddress.toLowerCase())]
        if (celdas[0]) {
          let owner = celdas[0].owner
          setShowing(<span style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: "0 10px"}}>Showing <b style={{margin: "0 7px"}}>{celdas && celdas.length}</b> Cells owned by <b style={{margin: "0 7px"}}>{owner.username} ({owner.address.substring(0,7)})</b></span>)  
        }
      }      
      if (filters.tokenId || filters.celdaCod) {
        celdas = undefined        
        setShowing()
      } else {
        if (celdas && celdas[0]) {
          celdas = sortByKey(celdas, sortField, sortDir)
          setItems(celdas)
          setOpen(true)
          map.closePopup()
          if (zone) {
            map.fitBounds(zone.bounds)
          } else {
            //let cell = celdas[0]
           // if (cell) map.fitBounds([cell.v1,cell.v2,cell.v3,cell.v4], {padding: [100, 100]})
          }
          
        } else {
          setOpen(false)
          setFilters({})
          setShowing()
        }  
      }
    } else {
      setShowing()
      setOpen(false)
    }
    return () => {

    }
  },[filters, selectedCell, cells, map, userAccount, sortDir, sortField])

const Column = React.memo(({ data, index, style }) => {
  //const zone = data[index].zone && zones.find(z => data[index].zone.toLowerCase() === z.shortName.toLowerCase())
  let customimg = `https://cells.land/images/250/cell/${data[index].celdaCod}.jpg`
  if (data[index].custom_image_metadata) {
    customimg = data[index].custom_image_metadata.replace("/img/","/images/250/")
  }
  const MAX_EVOLUTIONS = data[index].evolutions && data[index].evolutions.count > 11 ? 2 : 1
  const EVOLUTIONS_BOOST = 1 + ((data[index].evolutions && data[index].evolutions.boost) || 0) / 100 * MAX_EVOLUTIONS
  const VISITED = userAccount && userAccount.visitedCells.find(z => data[index].celdaCod === z.celdaCod)
  const isOwner = userAccount && data[index].owner.address.toLowerCase() === userAccount.address.toLowerCase()
  const isBattleCell = data[index].nftBattles && (data[index].nftBattles.wins > 0 || data[index].nftBattles.losses > 0 || data[index].owner.battleCell === data[index].tokenId)
  //const select = !!selected && data[index].celdaCod === selected
  return (
      <div key={`drawer-${data.celdaCod}`} style={{...style, display: "flex", justifyContent:"center", alignItems: "center"}}>
      {/* <AwesomeQRCode
        options={{
          text: `https://cells.land/?cell=${data[index].celdaCod}`,
          size: 500,
          // ...
        }}
        onStateChange={(state) => {
          switch (state) {
            case "working":
              // ...
              break;
            case "idle":
              // ...
              break;
          }
      }} />  */}
      <HtmlTooltip
          title={
            <Grid container direction="column" justifyContent="center" alignItems="center">
              {data[index].evolutions.count > 0 && 
                <Grid item>
                    <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            width: "160px",
                            background: "rgb(115 3 199)",
                            borderRadius: 5,
                            padding: 2,
                            fontWeight: "bold",
                            marginBottom: 5,
                            color: "white"
                        }}
                    >
                        {data[index].evolutions.count} EVOLUTIONS ({EVOLUTIONS_BOOST.toFixed(2)}x)
                    </div>
                </Grid>                
              }                   
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  Token ID
                </Grid>
                <Grid item>
                  <b>#{data[index].tokenId}</b>
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  Cell Code
                </Grid>
                <Grid item>
                  <b>{data[index].celdaCod}</b>
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  Rarity Rank
                </Grid>
                <Grid item>
                  <b>{data[index].rarityRank}</b>
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  Rarity Score
                </Grid>
                <Grid item>
                  <b>{data[index].rarity}</b>
                </Grid>
              </Grid> 
              {data[index].traits.zone && 
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    ZONE
                  </Grid>
                  <Grid item>
                    <b className={`rainbowtext zonetext-${data[index].traits.overlay.toLowerCase()}`}>{data[index].traits.overlay}</b>
                  </Grid>
                </Grid>                     
              }
              {data[index].traits.origin && 
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    ORIGIN
                  </Grid>
                  <Grid item>
                    <b className={`rainbowtext`}>{data[index].traits.origin}</b>
                  </Grid>
                </Grid>                     
              }              
              {userAccount && (data[index].cellContentCount > 0 || isOwner) &&
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    Visited
                  </Grid>
                  <Grid item>
                    <b>{VISITED ? "Yes" : "No"}</b>
                  </Grid>
                </Grid>   
              }
              {data[index].custom_name &&
                <Grid item>
                  <Typography variant="body1" style={{color: "#12ccc5", marginTop: 5}}><b>{data[index].custom_name}</b></Typography>
                </Grid>
              }
              {data[index].owner ?
                <Grid item container justifyContent="center" direction="column" alignItems="center" style={{marginTop: 5}}>
                  <Typography color="inherit" variant="caption">OWNED BY</Typography>
                  <Typography variant="body1" style={{color: "#12ccc5"}}><b>{data[index].owner.username}</b></Typography>
                </Grid>
                :
                <Grid item container justifyContent="center" direction="column" alignItems="center">
                  <Typography color="inherit" variant="caption">NOT MINTED YET</Typography>
                </Grid>
              }
              {isBattleCell && data[index].nftBattles &&
                <Grid item container justifyContent="center" direction="column" alignItems="center">
                  <Typography color="inherit" variant="caption" style={{color: "gold", filter: "drop-shadow(0 0 10px gold)", marginTop: 5}}>{data[index].nftBattles.wins}W-{data[index].nftBattles.losses}L</Typography>
                </Grid>
              }
            </Grid>
          }
          placement="top"
          style={{minWidth: 300}}
          //interactive
        >
          <ButtonBase onClick={() => handleClick(data[index], index)} style={{borderRadius: 10}} >
            <Paper className={classes.papercard} elevation={5}>
              <div style={{position: "absolute", width: 130, display: "flex", justifyContent: "space-around"}}>{getCountries(data[index].country,data[index].countryCode,data[index].celdaCod)}</div>
              { data[index].animated && !data[index].custom_image_metadata ? <video alt={`cell ${data[index].celdaCod}`} autoPlay loop src={`https://cells.land/video/cell/${data[index].celdaCod}.mp4`} width="130" height="130" style={{borderRadius: 10}}/> : <img alt={`cell ${data[index].celdaCod}`} src={customimg} width="130" height="130" style={{borderRadius: 10}}/> }
              <div style={{borderRadius: 10, backgroundColor: "rgba(0,0,0,0.5)", padding: 10, marginTop: 10, justifyContent: "center", color: "#12ccc5"}}>
                {data[index].tokenId > 0 && (<><b>#{data[index].tokenId}</b> | </>)}<b>{data[index].celdaCod}</b>
              </div>
            </Paper>          
          </ButtonBase>
      </HtmlTooltip>
    </div>
    )
})
 
const CellList = React.memo((props) => {
  let c = props.cells;
  return (
    <AutoSizer>
      {({ height, width }) => (
      
        <List
        height={height}
        width={width}
        itemCount={c.length}
        itemSize={170}
        itemData={c}
        layout="horizontal"
        ref={listRef}
      >
        {Column}
      </List>
      )}
    </AutoSizer>
  )
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#111',
    color: 'rgba(255, 255, 255, 0.5)',
    minWidth: 150,
    border: '1px solid #12ccc5',
    padding: 10,
    borderRadius: 10,
    textAlign: "center"
  },
}))(Tooltip);

const getSortText = (sortField, sortDir) => {
  let out
  if (sortField === "tokenId") out = "Token ID"
  else if (sortField === "rarity") out = "Rarity"
  else if (sortField === "country") out = "Country"
  else if (sortField === "cellContentLastUpdate") out = "Last Update"
  else if (sortField === "contentCount") out = "Content Count"
  else if (sortField === "evolutionsBoost") out = "Evolutions"

  //out += ' ' + sortDir === -1 ? "(DESC)" : "(ASC)"
  return out
}

const getCountries = (countries, countryCode, celdaCod) => {
  let out = [];
  for (let i = 0; i < countryCode.length; i++) {
    out.push(
      <span key={`${celdaCod}-${countryCode[i]}`}>
        <img alt="" src={"https://cells.land/img/flags-iso/shiny/24/" + countryCode[i] + ".png" } style={{margin: 0, padding: 0}} width={24}  height={24} />
      </span>
    )
  }
  return (
    <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "row", cursor: "pointer", width: "100%"}}>
      {out}
    </div>
      
    )
  }

  if (!showing) return null
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="bottom"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Grid container style={{width: "100%"}} justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton onClick={handleSortMenu} size="small">
                <SortIcon htmlColor="#12ccc5" fontSize="large"/>
              </IconButton>
              <IconButton onClick={handleSortDir} size="small">
                <SwapHorizIcon htmlColor="#12ccc5" fontSize="large"/>
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.showing}>
                {showing}&nbsp;
                <div style={{fontSize: ".8em", color: "rgba(255,255,255,.8)"}}>
                  sorted by {getSortText(sortField, sortDir)}
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDrawerClose} size="small">
                <CloseIcon htmlColor="#12ccc5" fontSize="large"/>
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <Divider />
          <div
            role="presentation"
            style={{minHeight: 230, width: "100%", position: "relative"}}
          >
            <div className={classes.buttonContainer}>
              <IconButton onClick={() => handleScroll("right")}>
                <RightArrowIcon htmlColor="#12ccc5" fontSize="large"/>
              </IconButton>
            </div>
            <div className={classes.buttonContainer} style={{left: 5, right: "auto"}}>
              <IconButton onClick={() => handleScroll("left")}>
                <LeftArrowIcon htmlColor="#12ccc5" fontSize="large"/>
              </IconButton>
            </div>

            {!!items && <CellList cells={items}/>}
          </div>
      </Drawer>
      <Menu
      id="sort-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{style:{backgroundColor: "#333", padding: 0, color: "white"}}}
    >
      <MenuItem onClick={() => handleSortField("tokenId")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Token ID
      </MenuItem>
      <MenuItem onClick={() => handleSortField("rarity")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Rarity
      </MenuItem>
      <MenuItem onClick={() => handleSortField("country")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Country
      </MenuItem>
      <MenuItem onClick={() => handleSortField("cellContentCount")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Content count
      </MenuItem>
      <MenuItem onClick={() => handleSortField("evolutionsBoost")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Evolutions
      </MenuItem>      
      <MenuItem onClick={() => handleSortField("cellContentLastUpdate")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Last updated
      </MenuItem>      
    </Menu> 
    </div>
  );
}

/* () => handleSortField("rarity") */ 
