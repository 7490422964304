import React, { useMemo } from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Grow, List, ListItem, Tabs, Tab } from '@material-ui/core';
import { cellStats } from './Utils';
import Draggable from 'react-draggable';
import CountUp from 'react-countup'
import { areEqual, FixedSizeList } from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer";

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      //className={"animate__animated animate__zoomIn animate__faster"}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    disableRipple: true,
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "10px",
    borderRadius: 30
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 800,
    backgroundColor: "#EEE",
    borderRadius: 30
  },
  smallpaper: {
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: 100,
    height: 270,
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    borderRadius: 30,
    '&:hover': {
      boxShadow: "0 0 6px #12ccc5"
    }  
  },
  smallpaper2: {
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: 100,
    height: 270,
    overflow: "auto",
    flexGrow: 1,
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    borderRadius: 30,
    '&:hover': {
      boxShadow: "0 0 6px #12ccc5"
    }  
  },  
  continent: {
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: 100,
    height: 270,
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start",
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    borderRadius: 30,
    '&:hover': {
      boxShadow: "0 0 6px #12ccc5"
    }  
  },
  image: {
    width: 128,
    height: 128,
    borderRadius: "50%",
    boxShadow: "0 0 5px black"
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: 300,
    height: 300,
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tabs: {
    flexGrow: 1,
    justifyContent: "space-evenly",
    alignSelf: "stretch",
    color: "white"
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }
}));

function getTabLabel(name, count) {
  return (<div style={{width: "100%"}}>
          <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)",fontWeight: "bold"}}>
            <b>{name}</b>
          </Typography>
          <Typography variant="h3">
            <CountUp delay={.1} start={0} end={count} />
          </Typography>
          </div>)
}


export default function Stats(props) {
  const {open, setOpen} = props;
  const {stats, } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };


  const itemList = React.memo(({ data, index, style }) => {
    if (!data) return null
    const c = data[index]

    return (
        <ListItem key={`cells-${c}`} dense style={{...style, listStyleType:"none"}} >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img alt="" src={`https://cells.land/img/flags-iso/shiny/24/${cellStats.cellsByCountry[c].code}.png`} width={24} height={24} />
                &nbsp;&nbsp;
                <Typography variant="subtitle1" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                    <b>{c}</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="right">
                <CountUp start={0} end={cellStats.cellsByCountry[c].count} />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
    )
  }, areEqual)

  const contentItemList = React.memo(({ data, index, style }) => {
    if (!data) return null
    const c = data[index]

    return (
        <ListItem key={`content-${c}`} dense style={{...style, listStyleType:"none"}} >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img alt="" src={`https://cells.land/img/flags-iso/shiny/24/${cellStats.cellsByCountry[c].code}.png`} width={24} height={24} />
                &nbsp;&nbsp;
                <Typography variant="subtitle1" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                    <b>{c}</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="right">
                <CountUp start={0} end={stats.topContentByCountry[c]} />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
    )
  }, areEqual)


  const mintedItemList = React.memo(({ data, index, style }) => {
    if (!data) return null
    const c = data[index]

    return (
        <ListItem key={`content-${c}`} dense style={{...style, listStyleType:"none"}} >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img alt="" src={`https://cells.land/img/flags-iso/shiny/24/${cellStats.cellsByCountry[c].code}.png`} width={24} height={24} />
                &nbsp;&nbsp;
                <Typography variant="subtitle1" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                    <b>{c}</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="right">
                <CountUp start={0} end={stats.mintedByCountry[c].count} />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
    )
  }, areEqual)  

  // const ownersItemList = React.memo(({ data, index, style }) => {
  //   if (!data) return null
  //   const c = data[index]

  //   return (
  //       <ListItem key={`content-${c}`} dense style={{...style, listStyleType:"none"}} >
  //         <Grid container direction="row" justifyContent="center" alignItems="center">
  //           <Grid item xs={10}>
  //             <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
  //               <img alt="" src={`https://cells.land/img/flags-iso/shiny/24/${cellStats.cellsByCountry[c].code}.png`} width={24} height={24} />
  //               &nbsp;&nbsp;
  //               <Typography variant="subtitle1" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
  //                   <b>{c}</b>
  //               </Typography>
  //             </div>
  //           </Grid>
  //           <Grid item xs={2}>
  //             <Typography variant="body2" align="right">
  //               <CountUp start={0} end={stats.ownersByCountry[c].count} />
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </ListItem>
  //   )
  // }, areEqual)  

  const cellsByCountry = useMemo(() => {
    const cbc = Object.keys(cellStats.cellsByCountry)
    return (
      <AutoSizer>
        {({ height, width }) => (
        <FixedSizeList 
          height={height}
          width={width}
          itemCount={cbc.length}
          itemData={cbc}
          itemSize={36}
          overscanCount={20}
          className={"lista"}
        >
          {itemList}
        </FixedSizeList>)}
      </AutoSizer>
    )
  },[itemList])

  const contentsByCountry = useMemo(() => {
    if (!stats.topContentByCountry) return null
    const cbc = Object.keys(stats.topContentByCountry)
    return (
      <AutoSizer>
        {({ height, width }) => (
        <FixedSizeList 
          height={height}
          width={width}
          itemCount={cbc.length}
          itemData={cbc}
          itemSize={36}
          overscanCount={20}
          className={"lista"}
        >
          {contentItemList}
        </FixedSizeList>)}
      </AutoSizer>
    )
  },[contentItemList, stats])

  const mintedByCountry = useMemo(() => {
    if (!stats.mintedByCountry) return null
    const mbc = Object.keys(stats.mintedByCountry)
    return (
      <AutoSizer>
        {({ height, width }) => (
        <FixedSizeList 
          height={height}
          width={width}
          itemCount={mbc.length}
          itemData={mbc}
          itemSize={36}
          overscanCount={20}
          className={"lista"}
        >
          {mintedItemList}
        </FixedSizeList>)}
      </AutoSizer>
    )
  },[mintedItemList, stats])  

  // const ownersByCountry = useMemo(() => {
  //   if (!stats.ownersByCountry) return null
  //   const obc = Object.keys(stats.ownersByCountry)
  //   return (
  //     <AutoSizer>
  //       {({ height, width }) => (
  //       <FixedSizeList 
  //         height={height}
  //         width={width}
  //         itemCount={obc.length}
  //         itemData={obc}
  //         itemSize={36}
  //         overscanCount={20}
  //         className={"lista"}
  //       >
  //         {ownersItemList}
  //       </FixedSizeList>)}
  //     </AutoSizer>
  //   )
  // },[ownersItemList, stats])  

  const content = React.useMemo(() => (
    <div className={classes.root} style={{paddingBottom: 0, marginBottom: 2, display: "flex", flexDirection: "column"}} >
      <Grid container direction="row" spacing={1} alignItems={"stretch"} style={{marginBottom:5}}> 
        <Grid container item style={{flexGrow: 1}} xs={12}>
          <Paper elevation={3} className={classes.smallpaper} style={{display: "flex", alignItems: "center", flexGrow:1, height: "auto", maxHeight: "auto", minHeight: "auto"}}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant={isMobile ? "scrollable" : "fullWidth"}
              aria-label="full width tabs"
              className={classes.tabs}
            >
              <Tab label={getTabLabel("CELLS",cellStats.cellTotal || 0)} {...a11yProps(0)} />
              <Tab label={getTabLabel("MINTED",stats.mintedTotal || 0)} {...a11yProps(1)} />
              {/* <Tab label={getTabLabel("OWNERS",stats.ownersTotal || 0)} {...a11yProps(2)} /> */}
              <Tab label={getTabLabel("CONTENTS",stats.contentTotal || 0)} {...a11yProps(2)} />
            </Tabs>
          </Paper>          
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Grid container direction="row" spacing={1} alignItems={"stretch"}>
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.continent}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)",fontWeight: "bold"}}>
                CELLS BY CONTINENT
              </Typography>
              <div style={{position: "relative", overflow: "hidden", height: 220}}>              
                <List>
                  {Object.keys(cellStats.cellsByContinent).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={cellStats.cellsByContinent[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>                      
          <Grid item style={{flexGrow: 1}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                CELLS BY SUBREGION
              </Typography>
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                <List>
                  {Object.keys(cellStats.cellsBySubregion).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={cellStats.cellsBySubregion[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>               
            </Paper>              
          </Grid>       
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                CELLS BY COUNTRY
              </Typography>
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                {cellsByCountry}
              </div>
            </Paper>              
          </Grid>                                        
        </Grid>        
      </TabPanel>
      

      <TabPanel value={value} index={1}>
        <Grid container direction="row" spacing={1} alignItems={"stretch"}>
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.continent}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                MINTED BY CONTINENT
              </Typography>
              <div style={{position: "relative", overflow: "hidden", height: 220}}>              
                <List>
                  {stats && stats.mintedByContinent && Object.keys(stats.mintedByContinent).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.mintedByContinent[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>                      
          <Grid item style={{flexGrow: 1}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                MINTED BY SUBREGION
              </Typography>      
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                <List>
                  {stats && stats.mintedBySubregion && Object.keys(stats.mintedBySubregion).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.mintedBySubregion[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>       
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                MINTED BY COUNTRY
              </Typography>     
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                {mintedByCountry}
              </div>
            </Paper>              
          </Grid>                                        
        </Grid>
      </TabPanel>
{/* 
      <TabPanel value={value} index={2}>
        <Grid container direction="row" spacing={1} alignItems={"stretch"}>
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.continent}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                OWNERS BY CONTINENT
              </Typography>
              <div style={{position: "relative", overflow: "hidden", height: 220}}>              
                <List>
                  {stats.ownersByContinent && Object.keys(stats.ownersByContinent).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.ownersByContinent[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>                      
          <Grid item style={{flexGrow: 1}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                OWNERS BY SUBREGION
              </Typography>      
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                <List>
                  {stats.ownersBySubregion && Object.keys(stats.ownersBySubregion).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.ownersBySubregion[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>       
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                OWNERS BY COUNTRY
              </Typography>     
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                {ownersByCountry}
              </div>
            </Paper>
          </Grid>
        </Grid>              
      </TabPanel>
      */}
      <TabPanel value={value} index={2}>
        <Grid container direction="row" spacing={1} alignItems={"stretch"}>
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.continent}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                CONTENTS BY CONTINENT
              </Typography>
              <div style={{position: "relative", overflow: "hidden", height: 220}}>              
                <List>
                  {stats.topContentByContinent && Object.keys(stats.topContentByContinent).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.topContentByContinent[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>                      
          <Grid item style={{flexGrow: 1}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                CONTENTS BY SUBREGION
              </Typography>      
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                <List>
                  {stats.topContentBySubregion && Object.keys(stats.topContentBySubregion).map((k) => {
                    return (
                    <ListItem key={k} dense>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <b>{k}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right">
                            <CountUp delay={.1} start={0} end={stats.topContentBySubregion[k]} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    )
                  })}
                </List>
              </div>
            </Paper>              
          </Grid>       
          <Grid item style={{flexGrow: 1, display: "flex"}} lg={4}>
            <Paper elevation={3} className={classes.smallpaper2}>
              <Typography variant="h6" align="center" style={{color:"rgba(255,255,255,0.54)", fontWeight: "bold"}}>
                CONTENTS BY COUNTRY
              </Typography>     
              <div style={{position: "relative", overflow: "auto", height: 220}}>
                {contentsByCountry}
              </div>
            </Paper>
          </Grid>
        </Grid>        
      </TabPanel>

    </div>      
  ), [classes, stats, cellsByCountry, contentsByCountry, mintedByCountry, value]) 


  return (
    <div>
      <Dialog
        open={open && !!stats}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth={"lg"}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
        >
        <DialogTitle style={{cursor: "move", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10, overflowX: "hidden"}}>
          {content}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}