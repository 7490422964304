import React, {useState, useContext} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import EvolveIcon from '@material-ui/icons/OfflineBoltRounded';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grow, Typography, ButtonBase } from '@material-ui/core';
import Draggable from 'react-draggable';
import {CellsLogoGrad} from './Utils'
import GlobalContext from "./Context";
import CellInfo from "./CellInfo";
import MintEvolution from "./MintEvolution";

const isMobile = L.Browser.mobile
const evolutionsOpenSeaUrl = `https://${(process.env.REACT_APP_DEBUG && "testnets.") || ""}opensea.io/assets/${(process.env.REACT_APP_DEBUG && "mumbai") || "matic"}/${process.env.REACT_APP_EVOLUTIONS_CONTRACT || "0x9cDa8121A77b94138Ca440018b4CF8aeE81E0DeB"}/`

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden',
    borderRadius: 30
  },
  paper2: {
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .8), rgb(11, 11, 11,0.8)), url(https://cells.land/img/celda_background_low.png)",
    color: "#fff",
    overflowX: "auto",
    height: 440,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
  card: {
    margin: 5,
    background: "#00000070",
    color: "#fff",
    overflow: 'hidden',
    height: 100,
    width: 100,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid transparent",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundSize: "cover !important",
    backgroundPosition: "50% 50%",
    transition: "border 300ms ease-in-out"
  },
  Fire: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/fire.gif)",
    '&:hover': {
      border: "2px solid orange"
    }
  },
  Water: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/water.gif)",
    '&:hover': {
      border: "2px solid cyan"
    }
  },
  Air: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/air.gif)",
    '&:hover': {
      border: "2px solid white"
    }
  },
  Earth: {
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, .2), rgb(11, 11, 11,0.3)), url(https://cells.land/img/evos/earth.gif)",
    '&:hover': {
      border: "2px solid brown"
    }
  }
}));


const CellEvolve = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {cellEvolve, setCellEvolve, map} = props;
  const [cellEvolved, setCellEvolved] = useState()
  const [cellEvolving, setCellEvolving] = useState(false)
  const [cellInfo, setCellInfo] = useState()
  const [mint, setMint] = useState(false)
  const {cells, dispatchCells} = context.cells
  const {userAccount, setUserAccount} = context.userAccount
  const {celdaAmounts} = context.celdaAmounts
  const {refreshToken} = context
  const classes = useStyles();

  const AVAILABLE_EVOLUTIONS = userAccount.evolutions?.filter(e => e && !e.assignedToCell) || []
  const ACTIVE_EVOLUTIONS = cellEvolve.evolutions?.list
  const MAX_EVOLUTIONS = 12 + ((cellEvolve.attachedCount || 0) * 12)
  // const AVAILABLE_FIRE = AVAILABLE_EVOLUTIONS.filter(e => e.type === "Fire")
  // const AVAILABLE_WATER = AVAILABLE_EVOLUTIONS.filter(e => e.type === "Water")
  // const AVAILABLE_AIR = AVAILABLE_EVOLUTIONS.filter(e => e.type === "Air")
  // const AVAILABLE_EARTH = AVAILABLE_EVOLUTIONS.filter(e => e.type === "Earth")
  const isMaxed = cellEvolve.evolutions?.count > 11
  const currentBoost = (cellEvolve.evolutions?.boost || 0) / 100 * (isMaxed ? 2 : 1)
  
  //console.log("cellEvolve", cellEvolve)
  const handleClose = (action) => {
    setCellEvolve();
  }

  const handleEvolve = (evoId) => {
    if (cellEvolving || cellEvolved) return
    if (isMaxed) {
      alert("Cell already maxed out")
      return
    }
    let c = window.confirm("Are you sure? This action cannot be undone!")
    if (c) {
      setCellEvolving(true)
      evolve(evoId)  
    }
  }

  const handleMint = () => {
    setMint(true)
  }
  
  function evolve(evoId) {
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("EVOLVE", "at not present")
        return null
      }
      let at = result.at
      try {
        let body = {
          evoTokenId: evoId,
          cellTokenId: cellEvolve.tokenId
        }
        let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at},
          body: JSON.stringify(body)
        }
  
        let url = process.env.REACT_APP_API_URL + '/evolveCell'
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (r) => { 
            if (r) {
              if (!r.error) {
                setCellEvolving(false)
                let cell = {...cellEvolve}
                let ua = {...userAccount}
                ua.evolutions = [...ua.evolutions].map(e => {
                  if (e && e.tokenId === evoId) e.assignedToCell = cellEvolve.tokenId
                  return e
                })
                setUserAccount(ua)

                let newEvo = AVAILABLE_EVOLUTIONS.find(e => e.tokenId === evoId)
                cell.evolutions.list.push(newEvo)
                cell.evolutions.count += 1
                cell.evolutions.boost = r.boost
                let c = [...cells.data].filter(c => c.tokenId !== cellEvolve.tokenId)
                c.push(cell)
                dispatchCells({type: "reset", payload: c})
              } else {
                setCellEvolving(false)
                alert(r.error)
              }
            } else {
              alert("Something went wrong...")
              setCellEvolving(false)
            }
          },
          (error) => {
            if (process.env.REACT_APP_DEBUG) console.log("error",error.error)
            setCellEvolving(false)
          }
        ); 
  
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log("error",err)
        setCellEvolving(false)
      }
    }, (err) => {
      setCellEvolving(false)
      if (process.env.REACT_APP_DEBUG) console.log("error", err)
    })
  }

  const ActiveEvos = () => {
    let out = []
    for (let i=0;i<MAX_EVOLUTIONS;i++) {
      let e = ACTIVE_EVOLUTIONS[i]
      if (e && e.type) {
        out.push(
          <Tooltip title="View on OpenSea" key={e.tokenId}>
            <ButtonBase key={e.tokenId} className={`${classes.card} ${classes[e.type]}`} onClick={() => window.open(`${evolutionsOpenSeaUrl}/${e.tokenId}`, "_blank")}>
              <Typography variant="body2">{e.type.toUpperCase()}</Typography>
              <Typography variant="h6"><b>#{e.tokenId}</b></Typography>
              <Typography variant="body2">+{e.boostAmount}%</Typography>
            </ButtonBase>
          </Tooltip>
        )
      } else {
        out.push(
          <Paper key={`slot-${i+1}`} className={`${classes.card}`} style={{color: "#DDD", background: "#6a6a6a80", border: 0}}>
            <b>SLOT {i+1}</b>
          </Paper>
        )
      }
    
    }
    return out
  }

  return (
    <div>
      <Dialog
        open={!!cellEvolve}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root} >
            <Paper elevation={3} className={classes.paper}>
              <DialogTitle style={{textAlign: 'center'}}>
                <b style={{fontSize: "1.5em"}}>EVOLVE YOUR CELL!</b>
              </DialogTitle>              
              <Grid container style={{justifyContent:"space-around", alignItems: "center", flexGrow: 1,padding: 20, paddingTop: 10}} >
                <Grid item md={5} style={{textAlign: "center"}}>
                  <b>{AVAILABLE_EVOLUTIONS.length > 0 && `${AVAILABLE_EVOLUTIONS.length} `}AVAILABLE EVOLUTIONS</b>
                  <br /><br />
                  <Paper elevation={5} className={classes.paper2}>
                    {AVAILABLE_EVOLUTIONS.length === 0 ? 
                      <div style={{textAlign: "center", width: "100%"}}><b>NO EVOLUTIONS</b></div>
                      : 
                      <Box display="flex" justifyContent="center" alignItems="flex-start" alignContent="flex-start" justifyItems="flex-start" style={{width: '100%', height: '100%', flexWrap: "wrap"}}>
                        {AVAILABLE_EVOLUTIONS.map(e => 
                          { return e && e.type && (
                          <Tooltip title="Click to evolve" key={e.tokenId}>
                            <ButtonBase className={`${classes.card} ${classes[e.type]}`} onClick={() => handleEvolve(e.tokenId)}>
                              <Typography variant="body2">{e.type.toUpperCase()}</Typography>
                              <Typography variant="h6"><b>#{e.tokenId}</b></Typography>
                              <Typography variant="body2">+{e.boostAmount}%</Typography>
                            </ButtonBase>
                          </Tooltip>
                          )}
                        )}
                      </Box>
                    }
                  </Paper>
                </Grid>
                <Grid item container direction="column" justifyContent="space-around" alignItems="center" md={2} style={{textAlign: "center", position: "relative"}}>
                  <div style={{width: "100%", textAlign: "center"}}><CellsLogoGrad style={{fontSize: 50}}/></div>
                  <div style={{fontSize: 120, color: "#12ccc5"}}><EvolveIcon style={{fontSize: 70}}/></div>
                  <div style={{width: "100%", textAlign: "center"}}><br /><span style={{fontSize: "0.8em", color: "#ffffff80"}}>CURRENT BOOST</span><br /><b>{1 + Number(currentBoost.toFixed(2))}x</b></div>
                  <div style={{textAlign: "center"}}>
                    <br /><br />
                    <Button variant="contained" color="primary" onClick={handleMint} endIcon={<EvolveIcon fontSize="large"/>} style={{width: "90%"}}><b>MINT EVOLUTIONS</b></Button>
                  </div>
                </Grid>
                <Grid item md={5} style={{textAlign: "center"}}>
                  <b>{ACTIVE_EVOLUTIONS.length > 0 && `${ACTIVE_EVOLUTIONS.length} `}ACTIVE EVOLUTIONS</b>
                  <br /><br />
                  <Paper elevation={5} className={classes.paper2}>
                    <Box display="flex" justifyContent="center" alignItems="flex-start" alignContent="flex-start" justifyItems="flex-start" style={{width: '100%', height: '100%', flexWrap: "wrap"}}>
                      <ActiveEvos />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <div style={{textAlign: "center", paddingBottom: 20}}>
                Evolving a Cell boosts it's $CELDA YIELD and exposure on the map.
                <br />
                Each Cell can be evolved up to 12 times. <b>Fully evolved Cells gain double boost!</b>
              </div>
            </Paper>
          </div>
        </DialogContent>
      </Dialog>
      {!!mint && <MintEvolution mint={mint} setMint={setMint}/>}
    </div>
  );
})

export default CellEvolve