import React, {useState, useContext} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grow } from '@material-ui/core';
import Draggable from 'react-draggable';
import {CellsLogoGrad} from './Utils'
import GlobalContext from "./Context";
import CellInfo from "./CellInfo";

import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22, 22, 22, 1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden'
  },
  paper2: {
    margin: 'auto',
    background: "#222",
    color: "#fff",
    overflow: 'hidden',
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "stretch",
    border: "2px solid #12ccc580",
    borderRadius: 20,
    height: 400,
    textAlign: "center",
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
  special: {
    animation: `shadow 10s linear infinite, textcolor 10s linear infinite`
  }
}));



const getCountries = (countries, countryCode, celdaCod) => {
  let out = [];
  for (let i = 0; i < countryCode.length; i++) {
    out.push(
      <Tooltip key={celdaCod + '-' + countryCode[i]} title={<b>{countries[i]}</b>}>
        <span>
          <img alt="" src={"https://cells.land/img/flags-iso/shiny/32/" + countryCode[i] + ".png" } style={{margin: 0, padding: "0 10px"}} width={32} height={32} />
        </span>
      </Tooltip>
    )
  }
  return (
    <span style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      {out}
    </span>
      
  )
}

const CellCard = ({cellData, setCellInfo}) => {
  const isElemental = ['Fire','Air','Water','Earth'].indexOf(cellData.traits.overlay) !== -1
  const isOverlay = ['Bitcoin','Ethereum','Banana','Avocado','CHROMATIC'].indexOf(cellData.traits.overlay) !== -1
  const isGlow = cellData.traits.glow === 'CHROMATIC'
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <div>{getCountries(cellData.country, cellData.countryCode, cellData.celdaCod)}</div>
      <img src={`https://cells.land/images/200/cell/${cellData.celdaCod}.jpg`} width={200} height={200} alt="origCell" style={{margin: 10, borderRadius: 10, border: "2px solid #00000050"}}/>
      <Grid container justifyContent="space-between" style={{padding: "5px 20px"}}>
        <Grid item>
          Token ID
        </Grid>
        <Grid item>
          <b>#{cellData.tokenId}</b>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" style={{padding: "5px 20px"}}>
        <Grid item>
          Cell Code
        </Grid>
        <Grid item>
          <b>{cellData.celdaCod}</b>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" style={{padding: "5px 20px"}}>
        <Grid item>
          Rarity Rank
        </Grid>
        <Grid item>
          <b>#{cellData.rarityRank}</b>
        </Grid>
      </Grid>
      {(isElemental || isOverlay || isGlow) &&
      <Grid container justifyContent="space-between" style={{padding: "5px 20px"}}>
        <Grid item>
          <b>{isElemental ? "ELEMENTAL" : isGlow ? "GLOW" : "OVERLAY"}</b>
        </Grid>
        <Grid item>
          <b>{isElemental || isOverlay ? cellData.traits.overlay.toUpperCase() : "CHROMATIC"}</b>
        </Grid>
      </Grid>      
      }
      <br />
      <Button variant="contained" color="primary" size="small" onClick={() => setCellInfo(cellData)} style={{width: "100%", borderRadius: 0}}>MORE INFO</Button>
    </div>
  )
}

const CellReroll = React.memo((props) => {
  const context = useContext(GlobalContext)
  const {cellReroll, setCellReroll, map} = props;
  const [cellRerolled, setCellRerolled] = useState()
  const [cellRerolling, setCellRerolling] = useState(false)
  const [cellInfo, setCellInfo] = useState()
  const {cells, dispatchCells} = context.cells
  const {userAccount, setUserAccount} = context.userAccount
  const {celdaAmounts} = context.celdaAmounts
  const {refreshToken} = context
  const classes = useStyles();

  const REROLL_PRICE = celdaAmounts?.find(a => a.key === "REROLL_PRICE")?.value || 100

  const isElemental = cellRerolled && ['Fire','Air','Water','Earth'].indexOf(cellRerolled.traits.overlay) !== -1
  const isOverlay = cellRerolled && ['Bitcoin','Ethereum','Banana','Avocado','CHROMATIC'].indexOf(cellRerolled.traits.overlay) !== -1
  const isGlow = cellRerolled && cellRerolled.traits.glow === 'CHROMATIC'
  const isLowRank = cellRerolled && cellRerolled.rarityRank < 2000

  const handleClose = (action) => {
    setCellReroll();
  }

  const handleReroll = () => {
    if (cellRerolling) return
    if (cellRerolled) {
      setCellReroll(cellRerolled)
      setCellRerolled()
      return
    }
    let c = window.confirm("Are you sure? This action cannot be undone!")
    if (c) {
      setCellRerolling(true)
      reroll(cellReroll.celdaCod)  
    }
  }
  
  function reroll(cellCode) {
    refreshToken().then((result) => {
      if (!result.at) {
        if (process.env.REACT_APP_DEBUG) console.log("reroll", "at not present")
        return null
      }
      let at = result.at
      try {
        let requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + at},
        }
  
        let url = process.env.REACT_APP_API_URL + '/rerollCell/' + cellCode
        // setLoading("FETCHING USER DATA...")
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
          (r) => { 
            if (r) {
              if (!r.error) {
                setCellRerolling()
                setCellRerolled(r)
                let c = [...cells.data].filter(c => c.celdaCod !== cellCode)
                c.push(r)
                dispatchCells({type: "reset", payload: c})
                let u = {...userAccount}
                u.cells = [...u.cells].filter(c => c.celdaCod !== cellCode)
                u.cells.push(r)
                if (u.favoriteCells.find(c => c.celdaCod === cellCode)) {
                  u.favoriteCells = [...u.favoriteCells].filter(c => c.celdaCod !== cellCode)
                  u.favoriteCells.push(r)  
                }
                if (u.visitedCells.find(c => c.celdaCod === cellCode)) {
                  u.visitedCells = [...u.visitedCells].filter(c => c.celdaCod !== cellCode)
                  u.visitedCells.push(r)  
                }
                u.wallet.balance -= REROLL_PRICE
                setUserAccount(u)
                map.fitBounds([r.v1, r.v2, r.v3, r.v4], { padding: [0, 0], animate: false });
              } else {
                setCellRerolling()
                alert(r.error)
              }
            } else {
              alert("Something went wrong...")
              setCellRerolling()
            }
          },
          (error) => {
            if (process.env.REACT_APP_DEBUG) console.log("error",error.error)
            setCellRerolling()
          }
        ); 
  
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.log("error",err)
        setCellRerolling()
      }
    }, (err) => {
      setCellRerolling()
      if (process.env.REACT_APP_DEBUG) console.log("error", err)
    })
  }  

  const walletBalance = (userAccount && userAccount.wallet && userAccount.wallet.balance) || 0

  return (
    <div>
      <Dialog
        open={!!cellReroll && !cellInfo}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root} >
            <Paper elevation={3} className={classes.paper}>
                <DialogTitle style={{textAlign: 'center'}}>
                  <b style={{fontSize: "1.5em"}}>REROLL YOUR CELL!</b>
                </DialogTitle>              
              <Grid container style={{justifyContent:"space-around", alignItems: "center", flexGrow: 1,padding: 20, paddingTop: 10}} >
                <Grid item md={5} style={{textAlign: "center"}}>
                  <Paper elevation={5} className={classes.paper2}>
                    <CellCard cellData={cellReroll} setCellInfo={setCellInfo} />
                  </Paper>
                </Grid>
                <Grid item container direction="column" justifyContent="space-around" alignItems="center" md={2} style={{textAlign: "center", position: "relative"}}>
                  <div style={{width: "100%", textAlign: "center"}}><CellsLogoGrad style={{fontSize: 50}}/></div>
                  <div style={{fontSize: 120, color: "#12ccc5"}}>➡</div>
                  <div style={{width: "100%", textAlign: "center"}}><span style={{fontSize: "0.8em", color: "#ffffff80"}}>PRICE</span><br /><b>{REROLL_PRICE} $CELDA</b></div>
                </Grid>
                <Grid item container md={5} style={{textAlign: "center"}}>
                  <Paper elevation={5} className={`${classes.paper2} ${isOverlay || isElemental || isGlow || isLowRank ? `${classes.special} animate__animated animate__tada` : ""}`}>
                   {cellRerolled ? <CellCard cellData={cellRerolled} setCellInfo={setCellInfo} /> : cellRerolling ? <CircularProgress size={100} style={{color: "#12ccc5", margin: "auto"}} /> : <span style={{fontSize: 120, color: "#12ccc5"}}>?<span style={{fontSize: 30}}><br /><b>NEW CELL</b></span></span>}
                  </Paper>
                </Grid>
              </Grid>
              <div style={{textAlign: "center", paddingBottom: 20}}>When rerolling, Cells custom metadata, Playground contents and Evolutions will be transferred to the new Cell.<br /><b>This action cannot be undone.</b></div>
              <div style={{textAlign: "center", paddingBottom: 20, position: "relative"}}>
                <Button variant="contained" onClick={handleReroll} color="primary" disabled={!!cellRerolling || walletBalance < REROLL_PRICE || isElemental || isOverlay || isGlow || isLowRank} endIcon={<SwapHorizontalCircleIcon />}>
                  <b>{walletBalance < REROLL_PRICE ? "NOT ENOUGH $CELDA" : cellRerolled ? "TRY AGAIN?" : cellRerolling ? "REROLLING..." : "LET'S DO THIS!"}</b>
                </Button>
                <div style={{position: "absolute", right: 10, color: "#ffffff80", fontSize: ".8em"}}>CITIZEN WALLET BALANCE: <b style={{color: "#ffffff"}}>{walletBalance} $CELDA</b></div>
              </div>
          </Paper>
        </div>
        </DialogContent>
      </Dialog>
      {!!cellInfo && <CellInfo cellData={cellInfo} setOpen={setCellInfo}/>}
    </div>
  );
})

export default CellReroll