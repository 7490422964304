import React, {useState, useContext} from 'react';
import L from 'leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Grow, Tooltip } from '@material-ui/core';
import Draggable from 'react-draggable';
import IconButton from '@material-ui/core/IconButton';
import GlobalContext from './Context'
import CellRow from './CellRow'
import { sortByKey } from './Utils'
import SortIcon from '@material-ui/icons/Sort';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const isMobile = L.Browser.mobile

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title-cells" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    borderRadius: 30
  },
  paper: {
    padding: 0,
    margin: 'auto',
    background: "radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11))",
    color: "#fff",
    overflow: 'hidden'
  },
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  }
}));

const CellsDialog = React.memo((props) => {
  const {cells, setCells, title, map, setUserSettings} = props
  const classes = useStyles();
  const context = useContext(GlobalContext);
  const {dispatchCells} = context.cells
  const [sortDir, setSortDir] = useState(-1)
  const [sortField, setSortField] = useState("tokenId")
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (action) => {
    setCells([]);
  };

  const handleSortDir = () => {
    setSortDir(sortDir * -1)
  };

  const handleSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortField = (field) => {
    if (process.env.REACT_APP_DEBUG) console.log("sortField",sortField)
    setSortField(field)
    handleCloseMenu()
    if (["evolutionsBoost","cellContentLastUpdate","rarity","contentCount"].indexOf(field) !== -1) 
      setSortDir(-1)
    else
      setSortDir(1)
  };

  const handleCloseMenu = (action) => {
    setAnchorEl(null);
  };
   
  const sortedCells = sortByKey([...cells].map(c => {
    c.evolutionsBoost = c.evolutions.boost
    return c
  }), sortField, sortDir)

  return (
    <div>
      <Dialog
        open={sortedCells && sortedCells.length > 0}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title-cells"
        maxWidth="md"
        fullWidth
        PaperProps={{style:{backgroundColor: "#FFFFFF26", padding: 0, borderRadius: 30}}}
        TransitionComponent={Grow}  
        PaperComponent={PaperComponent}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle style={{cursor: "move", textAlign: "center", padding: 0, margin: 0, position: "absolute", width: "100%", height: 10}} id="draggable-dialog-title-cells"></DialogTitle>
        <DialogContent style={{padding: isMobile ? 0 : 10}}>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <Grid container justifyContent="center" alignItems="center" direction="column">
                <Grid item xs={12} style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                  <Typography variant={"h3"} align="center">
                    <b>{title}</b>
                  </Typography>
                  <div style={{position: "absolute", right: 20, top: 20, width: "auto", display: "flex", alignItems: "center"}}>
                    <Tooltip title={"Sort by"}>
                    <IconButton onClick={handleSortMenu} size="small">
                      <SortIcon htmlColor="#12ccc5" fontSize="large"/>
                    </IconButton>
                    </Tooltip>
                    <Tooltip title={`Sort ${sortDir === 1 ? "descending" : "ascending"}`}>
                    <IconButton onClick={handleSortDir} size="small">
                      <SwapHorizIcon htmlColor="#12ccc5" fontSize="large"/>
                    </IconButton>                                      
                    </Tooltip>
                  </div>
                  
                </Grid>
                <Grid id="cellsgrid" container alignItems="stretch" justifyContent="center" alignContent="flex-start" style={{maxHeight: isMobile ? 270 : "100%", height: isMobile ? 270 : 670, overflowY: "auto", width: "100%"}}>  
                    {sortedCells && sortedCells.map((c) => c && <Grid item key={"card-" + c.celdaCod} style={{width: "100%"}}><CellRow data={c} cells={cells} dispatchCells={dispatchCells} map={map} setCells={setCells} setUserSettings={setUserSettings}/></Grid>)}
              </Grid>                
              </Grid>
            </Paper>
          </div>
        </DialogContent>
      </Dialog>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        
      >
      <MenuItem onClick={() => handleSortField("tokenId")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Token ID
      </MenuItem>
      <MenuItem onClick={() => handleSortField("rarity")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Rarity
      </MenuItem>
      <MenuItem onClick={() => handleSortField("country")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Country
      </MenuItem>
      <MenuItem onClick={() => handleSortField("cellContentCount")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Content count
      </MenuItem>
      <MenuItem onClick={() => handleSortField("evolutionsBoost")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Evolutions
      </MenuItem>      
      <MenuItem onClick={() => handleSortField("cellContentLastUpdate")}>
        <ListItemIcon style={{minWidth: "35px"}}>
          <SortIcon fontSize="small" htmlColor="#12ccc5" />
        </ListItemIcon>
        Last updated
      </MenuItem>      
    </Menu> 
    </div>
  );
})

export default CellsDialog