import { useEffect } from "react";
import L from 'leaflet'
import { useLeafletContext, createElementHook } from '@react-leaflet/core';


const useCellsLogoElement = createElementHook(createCellsLogo, updateCellsLogo)

function createCellsLogo(props, context) {
  
  let coords = L.latLngBounds(L.latLng(props.sw[0], props.sw[1]),L.latLng(props.ne[0], props.ne[1])); //9, 6, 8, 7
  
  
  return { 
    instance: L.imageOverlay(
      'https://cells.land/img/logos/battles.png?v2' ,
      [coords.getSouthWest(), coords.getNorthEast(),],
      {interactive: true, className: props.customClass} 
      ),
    context
  }

}
  
function updateCellsLogo(instance, props, prevProps) {
//    if (props.data.celdaCod !== prevProps.data.celdaCod) {
   //   if (process.env.REACT_APP_DEBUG) console.log('updateCellsLogo');
  //  }
}

export default function CellsLogo(props) {
  
    const context = useLeafletContext();
    const elementRef = useCellsLogoElement(props, context);

    useEffect(() => {
        const eRef = elementRef.current;
        const container = context.layerContainer || context.map
        container.addLayer(eRef.instance);

        const goToBattles = () => {
          window.open("https://nftbattles.xyz")
        }
        //eRef.instance.bringToFront()
        if (eRef && eRef.instance)
          eRef.instance.addEventListener('click', goToBattles)

        return () => {
          container.removeLayer(eRef.instance)    
          if (eRef && eRef.instance)
            eRef.instance.removeEventListener('click', goToBattles)
        }
    }, [elementRef, context.layerContainer, context.map])

    return null
}
